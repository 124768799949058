import { sha256 } from "js-sha256";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginBanner from "../assets/images/banner.png";
import HidePassword from "../assets/images/icons/eye-hide.png";
import ShowPassword from "../assets/images/icons/eye-show.png";
import {
  doLogin,
  getAndStoreFranchiseDeatils,
  getAndStoreUserProfile,
  getFranchiserUserDeatils,
} from "../services/AuthApiService";
import {
  getAccessToken,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../core/services/StorageService";
import { getLoggedInUserWithService } from "../services/SettingsApiService";
// @ts-ignore: Unresolved module
import * as CryptoJS from "crypto-js";
import toast from "react-hot-toast";

interface TitleProps {
  title: string;
}

const Login: FC<TitleProps> = ({ title }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<{
    username?: string;
    password?: string;
  }>({});

  const token = getAccessToken();
  const enabledServicesFilter = (serviceList: any[]) => serviceList.filter((service) => service.status === "ENABLED")
  useEffect(() => {
    const userProfile = getLocalStorageItem("userProfile");
    const userType = userProfile?.user_type;
    console.log(userType)
    if (token && userType === "FRANCHISE_ADMIN") {
      navigate("/overview");
    }
    else if (token && userType === "FRANCHISE_SALES") {
      const encryptedServiceList = getLocalStorageItem("availed_services")
      if (encryptedServiceList) {
        const decryptedServiceList = decryptResponse(encryptedServiceList, process.env.REACT_APP_CYPER);
        const services = enabledServicesFilter(decryptedServiceList || [])
        const navigationUrl = getNavigationUrlFromServiceObj(services[0] || [])
        navigate(navigationUrl);
      }
      else {
        navigate("/create-new-ticket")
      }

    }
  }, []);
  const decryptResponse = (encryptedData: any, key: any) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, key);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };
  const getNavigationUrlFromServiceObj = (serviceObj: any) => {
    if (serviceObj.service === 1) {
      return "/overview"
    }
    else if (serviceObj.service === 2) {
      return "/onboarded-company"
    }
    else if (serviceObj.service === 3) {
      return "/sales-service-performance"
    }
    else if (serviceObj.service === 4) {
      return "/reports"
    }
    else if (serviceObj.service === 5) {
      return "/billing"
    }
    else if (serviceObj.service === 6) {
      return "/settings"
    } else {
      return "/create-new-ticket"
    }

  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setErrors({ ...errors, username: "" });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: "" });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: { username?: string; password?: string } = {};
    if (!username.trim()) {
      newErrors.username = "Username is required";
    }
    if (!password) {
      newErrors.password = "Password is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      login();
    }
  };

  const login = async () => {
    try {
      let payload = {
        username: username,
        password: sha256(password),
        grant_type: "password",
      };
      await doLogin(payload).then((loginData) => {
        getAndStoreUserProfile().then((profileData) => {
          getFranchiserUserDeatils(profileData.data.response.userId).then((franchiserData) => {
            setLocalStorageItem('userProfile', franchiserData.response);

            if (loginData.response.user_type === 'FRANCHISE_SALES') {
              getUserAvailableService(loginData.response);
            } else {
              navigate("/overview");
            }
          })
        });
      });
    } catch (error) {
    }
  };

  const getUserAvailableService = async (loginData: any) => {
    try {
      await getLoggedInUserWithService().then((res) => {
        const userType = loginData?.user_type;
        const services = enabledServicesFilter(res.response || [])
        if (res.response) {
          const data = CryptoJS.AES.encrypt(
            JSON.stringify(res.response),
            process.env.REACT_APP_CYPER
          ).toString();

          if (services.length > 0) {
            setLocalStorageItem("availed_services", data);
            const navigationUrl = getNavigationUrlFromServiceObj(services[0] || [])
            navigate(navigationUrl);
          }
          if (services.length === 0) {
            navigate("/my-tickets")
          }
        }
        else if (!res.response && userType !== "FRANCHISE_ADMIN") {
          navigate("/my-tickets")
        }
      });
    } catch (error) {
      console.log(error);

    }

  };

  return (
    <>
      <div className="container-fluid">
        <div className="p-3 h-100">
          <div className="row h-100">
            <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
              <div className="h-100 d-flex align-items-center">
                <div className="w-100">
                  <form
                    onSubmit={handleSubmit}
                    className="change-password-form  mx-auto"
                  >
                    <h1 className="font-18 fw-semibold pb-4">{title}</h1>

                    <div className="pb-3">
                      <label htmlFor="username" className="label-text pb-1">
                        Please sign in to continue*
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="username"
                          className="password-input w-100 p-2"
                          value={username}
                          onChange={handleUsernameChange}
                        />
                      </div>
                      {errors.username && (
                        <div className="text-danger font-10 pt-1">
                          {errors.username}
                        </div>
                      )}
                    </div>
                    <div className="pb-3">
                      <label htmlFor="password" className="label-text pb-1">
                        Password*
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="password-input w-100 p-2"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <img
                          src={showPassword ? ShowPassword : HidePassword}
                          alt={showPassword ? "Hide Password" : "Show Password"}
                          className="eye-icon position-absolute"
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                      {errors.password && (
                        <div className="text-danger font-10 pt-1">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="pt-5">
                      <button
                        type="submit"
                        className="w-100 save-btn text-white border-0"
                      >
                        Login{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 mt-3 mt-sm-0">
              <img
                alt="Banner"
                src={LoginBanner}
                className="w-100 login-banner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
