import { IRestResponse } from "../core/interfaces/RestResponse.interface";
import { getRequest } from "../core/services/HttpClientWrapper";
import { IOverviewGraphResponse } from "../interface/overview.interface";

export const getOnboardedCompany = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
    try {
      const params = new URLSearchParams(payload);
      const res = await getRequest("v1/franchise/overview/location/onboardedCompany?"+ params.toString(), 'ADMIN');
      return res.data;
    } catch (error) {
      throw error;
    }
};

export const getTotalSalesPerson = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
    try {
      const params = new URLSearchParams(payload);
      const res = await getRequest("v1/franchise/overview/users/sales?"+ params.toString(), 'ADMIN');
      return res.data;
    } catch (error) {
      throw error;
    }
};

export const getTotalInterest = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
  try {
    const params = new URLSearchParams(payload);
    const res = await getRequest("v1/franchise/overview/status/interest?"+ params.toString(), 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalMeeting = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
  try {
    const params = new URLSearchParams(payload);
    const res = await getRequest("v1/franchise/overview/status/meeting?"+ params.toString(), 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalProfit = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
  try {
    const params = new URLSearchParams(payload);
    const res = await getRequest("v1/franchise/overview/users/profit?"+ params.toString(), 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalRevenue = async (payload: any): Promise<IRestResponse<IOverviewGraphResponse>> => {
  try {
    const params = new URLSearchParams(payload);
    const res = await getRequest("v1/franchise/overview/users/revenue?"+ params.toString(), 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};