/** @format */


import { IPaginationResponse } from "../core/interfaces/Pagination.interface";
import { IRestResponse } from "../core/interfaces/RestResponse.interface";
import { ISearch } from "../core/interfaces/SearchRequest.interface";
import { getRequest, postFormDataRequest, postRequest, postRequestBlob, putFormDataRequest, putRequest } from "../core/services/HttpClientWrapper";
import { IFeedDetails } from "../interface/feed.interface";
import { IFranchierUserslist, IFranchiseCompanyList, IFranchiseDetails, IFranchiseInputForm, IFranchisePaymentDetails, IFranchiseRevenueList, IFranchiseSalesPerformance } from "../interface/franchise.interface";
import { ILocation } from "../interface/master.interface";

export const getAllFranchise = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFranchiseDetails[]>>> => {
  try {
    const res = await postRequest("v1/franchise/search", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseById = async (franchiserId: string): Promise<IRestResponse<IFranchiseInputForm>> => {
  try {
    const res = await getRequest(`v1/franchise/${franchiserId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersFranchiser = async (franchiserId: string): Promise<IRestResponse<IFranchierUserslist[]>> => {
  try {
    const res = await getRequest(`v1/franchise/${franchiserId}/users`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseDetails = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFranchiseSalesPerformance[]>>> => {
  try {
    const res = await postRequest("v1/franchise/user/search", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseDetailsExcel = async (
  payload: ISearch
): Promise<Blob> => {
  try {
    const res = await postRequestBlob("v1/franchise/user/search/excel", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getFranchiseRevenueList = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFranchiseRevenueList[]>>> => {
  try {
    const res = await postRequest("v1/franchise/revenue/search", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseRevenueListExcel = async (
  payload: ISearch
): Promise<Blob> => {
  try {
    const res = await postRequestBlob("v1/franchise/revenue/search/excel", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentById = async (docId: string): Promise<IRestResponse<string>> => {
  try {
    const res = await getRequest(`v1/document/url/${docId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchisePaymentList = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFranchisePaymentDetails[]>>> => {
  try {
    const res = await postRequest("v1/franchise/payment/search", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchisePaymentListExcel = async (
  payload: ISearch
): Promise<Blob> => {
  try {
    const res = await postRequestBlob("v1/franchise/payment/search/excel", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseCompanyList = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFranchiseCompanyList[]>>> => {
  try {
    const res = await postRequest("v1/franchise/company/search", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getLocationList = async (
): Promise<IRestResponse<IPaginationResponse<ILocation[]>>> => {  try {
    let res = await getRequest("/v1/master/locations?page_number=0&page_size=293", 'CAMPAIGN' );
    console.log("getLocationList",res)
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getFranchiseCompanyListExcel = async (
  payload: ISearch
): Promise<Blob> => {
  try {
    const res = await postRequestBlob("v1/franchise/company/search/excel", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};


export const downloadOnboardedCompany = async (
  payload: ISearch
): Promise<Blob> => {
  try {
    const res = await postRequest("v1/franchise/company/excel", payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCampaign = async (
  payload: ISearch
): Promise<IRestResponse<IPaginationResponse<IFeedDetails[]>>> => {
  try {
    const res = await postRequest("v1/campaign/search", payload, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};


export const addFranchiser = async (data: any) => {
  try {
    const res = await postFormDataRequest(`v1/franchise`, data, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateFranchiser = async (farnchiseId: string, data: any) => {
  try {
    const res = await putFormDataRequest(`v1/franchise/${farnchiseId}`, data, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const putProfileImage = async (payload:any) => {

  try {
    let data = await putFormDataRequest("/v1/user/profile/image", payload, "USER");
    return data.data;
  } catch (error) {
    throw error;
  }
};
export const getNotification = async (  payload:{no:number,size:number} ,MessageType:string)=>{
  try{
    const res = await getRequest(`v1/notifications?pageNo=${payload?.no}&pageSize=${payload?.size}&MessageType=${MessageType}&descending=${true}&sortBy=CreatedOn` ,'USER');
    return res.data;
  }catch(error){
    throw error;
  }
}
export const readUnreadNotification = async (  payload: string[])=>{
  try{
    const res = await putRequest(`v1/notifications`,payload ,'USER');
    return res.data;
  }catch(error){
    throw error;
  }
}
export const NotificationCount = async (payload:{NotificationStatus:string, MessageType:string})=>{
  try{
    const res = await getRequest(`v1/notifications/count?status=${payload.NotificationStatus}&messageType=${payload.MessageType}  `,'USER');
    return res.data;
  }catch(error){
    throw error;
  }
}
export const getDocumentUrl = async(documentId:string) =>{
  try{
    const data = await getRequest(`/v1/document/url/${documentId}`,'USER');
    return data.data
  }catch(error) {
    console.log(error)
  }
}
