import { IPaginationResponse } from "../../core/interfaces/Pagination.interface";
import { IRestResponse } from "../../core/interfaces/RestResponse.interface";
import { ISearch } from "../../core/interfaces/SearchRequest.interface";
import { getRequest, postRequest, postRequestBlob } from "../../core/services/HttpClientWrapper";
import { getParamsFromObjToStr } from "../../core/services/utilityService";
import { IFranchiseCompanyList } from "../../interface/franchise.interface";
import { IReportGraphRes, IReportUsersList } from "../../interface/report/Report.interface";



const getReportUsers = async (payload: ISearch): Promise<IRestResponse<IPaginationResponse<IReportUsersList[]>>> => {
    try {
        const res = await postRequest("v1/franchise/report/user/search", payload, 'ADMIN');
        return res.data;
    } catch (error) {
        throw error;
    }
};

const downloadOverviewCSV = async (payload: ISearch): Promise<Blob> => {
    try {
   
        const res = await postRequestBlob(`v1/franchise/overview/dashboard/excel`, payload);
        return res.data;
    } catch (error) {
      throw error;
    }
  };
const downloadReportCSV = async (payload: ISearch): Promise<Blob> => {
    try {
   
        const res = await postRequestBlob(`v1/franchise/overview/report/excel`, payload);
        return res.data;
    } catch (error) {
      throw error;
    }
  };

const getReportUsersCompanies = async (payload: ISearch): Promise<IRestResponse<IPaginationResponse<IReportUsersList[]>>> => {
    try {
        const res = await postRequest("v1/franchise/report/company/search", payload, 'ADMIN');
        return res.data;
    } catch (error) {
        throw error;
    }
};

const getCompanyCategories = async (): Promise<IRestResponse<[]>> => {
    try {
        const res = await getRequest("v1/franchise/report/category", 'ADMIN');
        return res.data;
    } catch (error) {
        throw error;
    }
};

const getCategoryStatus = async (category: string): Promise<IRestResponse<[]>> => {
    try {
        const res = await getRequest(`v1/franchise/report/subcategory?category=${category}`, 'ADMIN');
        return res.data;
    } catch (error) {
        throw error;
    }
};

const getReportGraph = async (paramsData: any): Promise<IRestResponse<IReportGraphRes>> => {

    const paramsStr = getParamsFromObjToStr(paramsData)
    try {
        const res = await getRequest(`v1/franchise/report/value?${paramsStr}`, 'ADMIN');
        return res.data;
    } catch (error) {
        throw error;
    }
};


export {
    getReportUsers,
    getReportUsersCompanies,
    getCompanyCategories,
    getCategoryStatus,
    getReportGraph,
    downloadReportCSV,
    downloadOverviewCSV,
};