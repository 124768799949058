import { useEffect } from 'react';


export const removeEmpty = (object: any): any => {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      if (object[key] instanceof Object) {
        removeEmpty(object[key])
      }
      if (object[key] === null || object[key] === '' || object[key] === undefined)
        delete object[key];
    }
  }
  return object;
}

export const convertToDDMMYYYY = (inputDate: any): any => {
  const dateParts = inputDate.split('-');
  if (dateParts.length === 3) {
    const [year, month, day] = dateParts;
    return `${day}/${month}/${year}`;
  } else {
    return 'Invalid Date';
  }
}
export const convertToYYYYMMDD = (inputDate: any): any => {
  const dateParts = inputDate.split('/');
  if (dateParts.length === 3) {
    const [day, month, year] = dateParts;
    return `${year}-${month}-${day}`;
  } else {
    return 'Invalid Date';
  }
}
export const convertToDDMMYYYYSeperatedByHypen = (inputDate: any): any => {
  const dateParts = inputDate.split('/');
  if (dateParts.length === 3) {
    const [day, month, year] = dateParts;
    return `${day}-${month}-${year}`;
  } else {
    return 'Invalid Date';
  }
}

export const convertToYYYYMMDDFromDBDate = (date: string): string => {
  const originalDate = new Date(date);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate
}
export const convertToDDMMYYYYFromDBDate = (date: string): string => {
  const originalDate = new Date(date);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate
}
export const convertToTitleCase = (input: string): string => {
  if (!input) {
    return 'NA'
  }
  return input
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const convertToMonthAndYear = (input: string): string => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = date.toLocaleString('en-US', { month: 'short' });
  return month + ", " + year
}

export const convertToMonthDateAndYear = (input: string): string => {
  const date = new Date(input);
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  return month + ' ' + day + ", " + year
}



export const getParamsFromObjToStr = (obj: any) => {
  for (let key in obj) {
    if (!obj[key]) {
      delete obj[key];
    }
  }
  let str = "";
  for (let key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str
}
export const constructSelectOptions = (data: any[], idKey: string, valueKey: string) => {
  var options :any = [];
  for (var i = 0; i < data.length; i++) {
    var option : any= {};
    option["label"] = data[i][valueKey];
    option["value"] = data[i][idKey];
    options.push(option);
  }
  return options;
};

export const  formatString = (input: string): string => {
  return input
      .toLowerCase() 
      .split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
}

export const ellipseAppender = (str:string,maxLength:number)=>{
  if(str.length<=maxLength){
        return str
  }
  return str.slice(0,maxLength-3)+"..."
}
export const fileSizeCalculator = (size:number)=>{
    return (size/1024).toFixed(2)
}

export const getFileExtension = (filename: string) => {
  const parts = filename.split('.');
  if (parts.length === 1) return ''; // No extension found
  return parts.pop(); // Get the last part (extension)
}


export const useOutsideClick =(ref: React.RefObject<HTMLElement>, callback: () => void) =>{
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}
export const formatBytes = (bytes:number, decimals = 2) =>{
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
