import { IPaginationResponse } from "../core/interfaces/Pagination.interface";
import { IRestResponse } from "../core/interfaces/RestResponse.interface";
import { ISearch } from "../core/interfaces/SearchRequest.interface";
import { getRequest, postFormDataRequest, postRequest, putRequest } from "../core/services/HttpClientWrapper";
import { IAnnouncement } from "../interface/announcement.interface";
import { IInbox } from "../interface/inbox.interface";
import { ITicket } from "../interface/ticket.interface";

export const getAllTickets = async (searchRequest: ISearch): Promise<IRestResponse<IPaginationResponse<ITicket[]>>> => {
  try {
    const res = await postRequest("v1/tickets/search", searchRequest, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTicketById = async (ticketID: string): Promise<IRestResponse<ITicket>> => {
  try {
    const res = await getRequest("v1/tickets/" + ticketID, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addNewTickets = async (payload: any): Promise<IRestResponse<ITicket>> => {
  try {
    const res = await postFormDataRequest("v1/tickets", payload, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllInboxes = async (searchRequest: ISearch): Promise<IRestResponse<IPaginationResponse<IInbox[]>>> => {
  try {
    const res = await postRequest("v1/inboxMessage/search", searchRequest, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const readInboxes = async (inboxId: String): Promise<IRestResponse<IPaginationResponse<IInbox[]>>> => {
  try {
    const res = await putRequest("v1/inboxMessage/read/" + inboxId, null, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllAnnouncement = async (searchRequest: ISearch): Promise<IRestResponse<IPaginationResponse<IAnnouncement[]>>> => {
  try {
    const res = await postRequest("v1/annoucement/search", searchRequest, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};


export const readAnnouncement = async (announcementId: String): Promise<IRestResponse<IPaginationResponse<IInbox[]>>> => {
  try {
    const res = await putRequest("v1/annoucement/" + announcementId + "/read", null, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};