import useState from 'react-usestateref'

import Cropper from 'react-easy-crop'
import {
  Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "reactstrap";
import getCroppedImg from "../core/services/cropImage"
import CropIcon from "../assets/images/Icon-crop-icon.png"
import CloseIcon from "../assets/images/Icon-close.png"
import { saveAs } from 'file-saver';

interface IImageCrop{
    showImageCrop:boolean;
    setShowImageCrop:any;
    image:any;
    setNewCroppedImage:any
    setShowLinkModal:any
    setShowCameraModal:any
    setShowPicUploadModal:any
    cropBoxHeight:number;
    cropBoxWidth:number;
    
}


const ImageCropper:React.FC<IImageCrop> = ({showImageCrop,setShowImageCrop,image,setNewCroppedImage,setShowLinkModal,setShowCameraModal,setShowPicUploadModal,cropBoxHeight,cropBoxWidth}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom,latestZoom] = useState<any>(1)
  const [croppedAreaPixels,setCroppedAreaPixels] = useState(null)

  const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
    setCroppedAreaPixels(croppedAreaPixels);
    getPercentageZomeValue(latestZoom.current)
  }
  const onCrop = async()=>{
    const croppedImageUrl: any = await getCroppedImg(image,croppedAreaPixels);
    setNewCroppedImage(croppedImageUrl)
    setShowImageCrop(false)
    setShowCameraModal(false)
    setShowPicUploadModal(false)
  }
  const blobToFile = (theBlob: Blob, fileName:string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
      
    //Cast to a File() type
    return theBlob as File;
  }
  const getPercentageZomeValue = (zomeValue:any) =>{
    const imageCropId = document.getElementById('imageCropId');
    if(imageCropId) {
      const value = (zomeValue-1)/(2)*100
      imageCropId.style.background = 'linear-gradient(to right, rgba(0, 102, 153, 1) 0%, rgba(0, 102, 153, 1) ' + value + '%, #fff ' + value + '%, white 100%)'
    }
  }
  return (
    <div>
          <Modal
        isOpen={showImageCrop}
        className="open-document-modal"
        style={{ maxWidth: "1000px",top:"5px" }}>
        <ModalBody style={{height:"430px"}}>
          <div className='heading-box d-flex justify-content-between' style={{marginBottom:"10px"}}>
          <div className='crop-title d-flex' >
            <div className='crop-icon-box'>
              <img src={CropIcon}/>
            </div>
            <div className='crop-info px-3'>
                <h5>Crop Image</h5>
              </div>
          </div>
          <div className='crop-close-mark'>
            <img src={CloseIcon} className='pointer' onClick={() => setShowImageCrop(!showImageCrop)}/>
          </div>
          </div>
        <div
            className="px-3 py-2 mb-3 image-crop-container" >
            <Cropper
      image={image}
      crop={crop}
      zoom={zoom}
      cropSize={{ width: cropBoxWidth, height: cropBoxHeight }}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      zoomWithScroll={false}
    />
    
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end border-0 ">
        <div className="controls bg-transparent" >
        <input
          type="range"
          id={'imageCropId'}
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          onInput={(e: any) =>{
            getPercentageZomeValue(e.target.value)
          }}
          className="zoom-range"
        />
      </div>
      <Button onClick={onCrop} style={{backgroundColor:"rgba(0, 102, 153, 1)",width:"171px",height:"47px",marginBottom:"5px",marginRight:"30px"}}>Next</Button>
        </ModalFooter>
        </Modal>
        
    </div>
    
  )
}
export default ImageCropper