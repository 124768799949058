// InboxContent Component
import React, { FC, useRef } from "react";
import { IInbox } from "../interface/inbox.interface";
import moment from "moment";
import parse from "html-react-parser";
import AttachIcon from "../assets/images/IconinboxAttach.png"
import { getDocumentById } from "../services/FranchiseApiService";
import DefaultDocType from "../assets/images/fileTypeIcons/defaultDoc.png";
import DefaultPDF from "../assets/images/fileTypeIcons/defaultPDF.png";
import DefaultWORD from "../assets/images/fileTypeIcons/defaultWord.png";
import PromiloLogoWithoutText from "../assets/images/icons/promilo-logo-without-text.png";
import { getFileExtension } from "../core/services/utilityService";

interface IInboxContentProps {
  selectedInbox: IInbox;
}
const InboxContent: FC<IInboxContentProps> = ({ selectedInbox }) => {
  const formatDateTime = (dateTimeString: string): string => {
    const momentDate = dateTimeString ? moment(dateTimeString) : moment();
    const formattedDateTime = momentDate.format("dddd h:mm A");
    return formattedDateTime;
  };
  const targetDivRef = useRef<HTMLDivElement>(null);
  const triggeringDivRef = useRef<HTMLImageElement>(null);
  const scrollToTargetDiv = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const downloadAttachment = async (document_id: any) => {
    let res = await getDocumentById(document_id)
    if (res.response) {
      window.open(res.response, '_blank')
    }
  }
  const getAttachmentIcons = (attachmentName: string) => {
    const fileExtension = getFileExtension(attachmentName);
    let attachmentIcon = DefaultDocType;
    if (fileExtension) {
      switch (fileExtension) {
        case 'pdf':
          attachmentIcon = DefaultPDF;
          break;
        case 'xlsx':
          attachmentIcon = DefaultWORD;
          break;
      }
    }
    return attachmentIcon;
  }
  return (
    <div className="inbox-main-content p-2">
      <div className="card mb-0 w-100 p-3">
        <div className="inbox-content-container">
          {selectedInbox && selectedInbox.id ? (
            <div className="d-flex align-items-start">
              <div className="show-user-name text-white font-16 text-bold d-flex align-items-center justify-content-center me-3">
                <img src={PromiloLogoWithoutText} style={{ width: '32px', height: '32px' }} />
              </div>
              <div className="w-100">
                <h1 className="font-16 w-100 fw-500 pb-1 mb-0 d-flex justify-content-between" style={{ width: "590px", marginTop: "10px" }}>Promilo Support
                  {selectedInbox.attachments.length > 0 &&
                    <img src={AttachIcon} alt="attachIcon" style={{ height: "19px", width: "19px", cursor: "pointer" }}
                      ref={triggeringDivRef} onClick={scrollToTargetDiv} />}
                </h1>
                <p className="font-12 time-stamp">
                  {formatDateTime(selectedInbox.created_on_date)}
                </p>
                <div className="content-wrapper">
                  <h2 className="font-16 fw-500 py-2 subject-underline-text">
                    {selectedInbox.subject}
                  </h2>
                  <div className="px-2" style={{ marginTop: "35px" }}>
                    {selectedInbox.description
                      ? parse(selectedInbox.description)
                      : ""}
                  </div>
                  {selectedInbox.attachments.length > 0 && <div ref={targetDivRef} className="my-5">
                    <p>Attachments:</p>
                    <div style={{width: 'max-content'}}>
                      {selectedInbox.attachments.map((attachment: any, index: any) =>
                        <div key={index} className="d-flex justify-content-between align-items-center my-2"
                          style={{
                            width: "100%",
                            borderRadius: '2px',
                            border: "1px solid #C4C9CC",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            cursor: "pointer"
                          }}
                          onClick={() => downloadAttachment(attachment.document_id)}>
                          <div style={{
                            fontWeight: "500",
                            fontSize: "medium",
                            paddingRight: '1rem'
                          }}>
                            <img src={getAttachmentIcons(attachment.document_name)}
                              style={{
                                height: "20px",
                                width: "15px",
                                marginRight: "5px"
                              }} alt="doc" />
                            {attachment.document_name.length > 30 ? attachment.document_name.slice(0, 30) + "..." :
                              attachment.document_name}</div>
                          <img src={AttachIcon} alt="" style={{
                            height: "15px",
                            width: "15px"
                          }} />
                        </div>
                      )}
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          ) : (
            <div>No Message</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InboxContent;
