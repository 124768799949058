import { IRestResponse } from "../core/interfaces/RestResponse.interface";
import { getRequest, postRequest, putRequest } from "../core/services/HttpClientWrapper";
import { IServiceList, IUserServiceList } from "../interface/settings.interface";


export const getAllUserWithService = async (): Promise<IRestResponse<IUserServiceList[]>> => {
    try {
      const res = await getRequest("v1/franchise/sales/user/list", 'ADMIN');
      return res.data;
    } catch (error) {
      throw error;
    }
};
export const getLoggedInUserWithService = async (): Promise<IRestResponse<IUserServiceList[]>> => {
  try {
    const res = await getRequest("v1/franchise/sales/user", 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getServiceList = async (): Promise<IRestResponse<IServiceList[]>> => {
  try {
    const res = await getRequest("v1/franchise/sales/user/sevices", 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updatePermission = async (payload: any): Promise<IRestResponse<IServiceList>> => {
  try {
    const res = await postRequest("v1/franchise/sales/user/permission", payload, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const activateSalesPerson = async (userId: string, action: string): Promise<IRestResponse<IServiceList>> => {
  try {
    const res = await putRequest(`v1/user/profile/${userId}/${action}`, {remark: 'blocked'}, 'USER');
    return res.data;
  } catch (error) {
    throw error;
  }
};