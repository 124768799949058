import { getAxiosInstance } from "./ApiConfig";
import { AxiosResponse } from "axios";
import { IHeaderConfig, ILoginConfig } from "../interfaces/ApiHeader.interface";
import toast from "react-hot-toast";
import { TInstanceType } from "../types/Instances.types";
import { getAccessToken } from "./StorageService";

const clientPassword = process.env.REACT_APP_CLIENT_PASSWORD;
const getRequest = async (
  path: string,
  instance?: TInstanceType,
  queryParam?: Record<string, string>
): Promise<AxiosResponse<any>> => {
  const newParams = new URLSearchParams(queryParam);
  const queryString = newParams.toString();
  const urlWithQuery = queryString ? `${path}?${queryString}` : path;
  try {
    const response = await getAxiosInstance(instance).get(
      urlWithQuery,
      getHeaderConfig()
    );
    return response;
  } catch (err: any) {
    err.response.data.message && toast.error(err.response.data.message);
    throw err;
  }
};

const putRequest = async (
  path: string,
  payload: any,
  instance: TInstanceType
) => {
  try {
    let response = await getAxiosInstance(instance).put(
      path,
      payload,
      getHeaderConfig()
    );
    return response;
  } catch (err: any) {
    toast.error(err.response.data.message);
    throw err;
  }
};

const postRequest = async (
  path: string,
  payload?: any,
  instance?: TInstanceType
) => {
  try {
    let response = await getAxiosInstance(instance).post(
      path,
      payload,
      instance === "LOGIN" ? loginHeaderConfig() : getHeaderConfig()
    );

    return response;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.status && err.response.data.status.message) {
      toast.error(err.response.data.status.message);
    }
    throw err;
  }
};

const deleteRequest = async (path: string, instance: TInstanceType) => {
  try {
    let response = await getAxiosInstance(instance).delete(
      path,
      getHeaderConfig()
    );
    if (response.data.status.code === 200) {
      toast.success(response.data.status.message);
    } else {
      toast.error(response.data.status.message);
    }
    return response;
  } catch (err: any) {
    toast.error(err.response.data.message);
    throw err;
  }
};
const postFormDataRequest = async (
  path: string,
  payload: any,
  instance: TInstanceType
) => {
  try {
    let response = await getAxiosInstance(instance).post(
      path,
      payload,
      getHeaderUploadConfig()
    );
    if (response.data.status.code === 200) {
      if (response.data.status.message) {
        // toast.success(response.data.status.message);
      }
    } else {
      toast.error(response.data.status.message);
    }
    return response;
  } catch (err: any) {
    toast.error(err.response.data.message);
    throw err;
  }
};
const postRequestBlob = async (
  path: string,
  payload?: any,
  instance?: TInstanceType
) => {
  try {
    const options = {
      ...getHeaderConfigBlob(),
      responseType: 'blob',
      withCredentials: true,
    };
    let response = await getAxiosInstance(instance).post(
      path,
      payload,
      options
    );
    return response;
  } catch (err: any) {
    if (err.response.data.status.message) {
      toast.error(err.response.data.status.message);
    }
    throw err;
  }
};
const putFormDataRequest = async (
  path: string,
  payload: any,
  instance: TInstanceType
) => {
  try {
    let response = await getAxiosInstance(instance).put(
      path,
      payload,
      getHeaderUploadConfig()
    );
    return response;
  } catch (err: any) {
    toast.error(err.response.data.message);
    throw err;
  }
};

const loginHeaderConfig = () => {
  let headers: ILoginConfig = {} as ILoginConfig;
  headers["Content-Type"] = "application/x-www-form-urlencoded";
  headers["Authorization"] = `Basic ${clientPassword}`;
  headers["franchise"] = true;

  return { headers: headers };
};

const getHeaderConfig = (): any => {
  let headers: IHeaderConfig = {} as IHeaderConfig;
  headers["Content-Type"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  let token = getAccessToken();
  if (token) {
    headers["access_token"] = token;
  }
  headers["franchise"] = "true";
  return { headers: headers };
};
const getHeaderConfigBlob = (): any => {
  let headers: IHeaderConfig = {} as IHeaderConfig;
  headers["Content-Type"] = "application/json";
  headers['Accept'] = 'application/octet-stream';
  headers["Access-Control-Allow-Origin"] = "*";
  let token = getAccessToken();
  if (token) {
    headers["access_token"] = token;
  }
  headers["franchise"] = "true";
  return { headers: headers };
};

const getHeaderUploadConfig = (): any => {
  let headers: IHeaderConfig = {} as IHeaderConfig;
  headers["Content-Type"] = "multipart/form-data";
  headers["Access-Control-Allow-Origin"] = "*";
  let token = getAccessToken();
  if (token) {
    headers["access_token"] = token;
    headers["franchise"] = "true";
  }
  return { headers: headers };
};

export {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postFormDataRequest,
  putFormDataRequest,
  postRequestBlob
};
