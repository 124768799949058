/** @format */

import appConfig from "../../configs/promiloConfig";
import { IUserInfo } from "../../interface/login.intereface";

export const setLocalStorageItem = (key: string, value: any) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getLocalStorageItem = (key: string) => {
  const item: any = localStorage.getItem(key);
  try {
    const parsedObject = JSON.parse(item);
    if (parsedObject) {
      return parsedObject;
    } else {
      return item;
    }
  } catch (error) {}
  return item;
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const setAccessToken = (token: string) => {
  if (!token) {
    return;
  }
  window.localStorage.setItem(appConfig.storage.accessToken, token);
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const getAccessToken = () => {
  return localStorage.getItem(appConfig.storage.accessToken);
};

export const setUserData = (userInfo: any) => {
  window.localStorage.setItem(
    appConfig.storage.userData,
    JSON.stringify(userInfo)
  );
};

export const getUserData = (): IUserInfo | null => {
  const userDataString = window.localStorage.getItem(
    appConfig.storage.userData
  );

  if (userDataString) {
    return JSON.parse(userDataString);
  }

  return null;
};
