import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import { convertToYYYYMMDDFromDBDate } from '../core/services/utilityService';
import { IAnnouncement } from '../interface/announcement.interface';
import CloseIcon from "../assets/images/closeIconX.png"
import moment from 'moment'
interface IAnnouncementPreview {
    announcementPreview: boolean,
    setShowAnnouncementPreview: any,
    announcementList: IAnnouncement[],
    activeIndex: number | undefined,
    handleReadAnnouncement: any
}

const AnnouncementPreview: React.FC<IAnnouncementPreview> = ({ announcementPreview, setShowAnnouncementPreview, announcementList, activeIndex, handleReadAnnouncement }) => {
    const [index, setIndex] = useState<number>(0);

    const handleSelect = (selectedIndex: number) => {
        if (!announcementList[selectedIndex]?.read) {
            handleReadAnnouncement(announcementList[selectedIndex]?.announcement_id)
        }
        setIndex(selectedIndex);
    };

    const onModalOpened = () => {
        (activeIndex === 0 || activeIndex) && handleSelect(activeIndex)
    }


    return (
        <div>
            <Modal
                isOpen={announcementPreview}
                toggle={() => setShowAnnouncementPreview(!announcementPreview)}
                onOpened={onModalOpened}
                className="announcement-popup modal-xl position-relative me-4 mt-0"
                style={{ top: "5%"}}
            >
                <ModalBody>
                <div className='announcement-preview-main d-flex row'>
                <div className='d-flex justify-content-between' style={{marginTop:"5px"}}>
                        {announcementList[index]?.video_url?<h1 className="mb-0 font-20 fw-500 px-2 py-2" ><a href={announcementList[index].video_url} style={{color:"#006699",marginLeft:"17px"}} target='_blank'>{announcementList[index]?.title}</a></h1>:<h1 className="mb-0 font-20 fw-500 px-2 py-2" style={{marginLeft:"17px"}}>{announcementList[index]?.title}</h1>}
                        <img className="close-button-previeww" src={CloseIcon} onClick={() => setShowAnnouncementPreview(!announcementPreview)}></img>
                        </div>
                    <Carousel activeIndex={index} onSelect={handleSelect} wrap={false} interval={null}>
                        {announcementList?.map((announcement) => <Carousel.Item key={announcement.announcement_id}>
                        <div className='preview-content px-4' style={{overflowY:"auto",maxHeight:"540px"}}>
                            <p>
                            {announcement.image_url && <img src={announcement.image_url} loading="lazy" alt="Sample Image" className='preview-image-main' />}
                            <div className="col-12 write-a-remark">
                            <label className='font-14 px-0 py-2 ' htmlFor="publishedOn">
                             {moment(announcement.published_on).format("MMM DD, YYYY")}
                            </label>
                            {!!announcement.description && <div dangerouslySetInnerHTML={{ __html: announcement.description }} />}
                        </div> 
                            </p>
                        </div>  
                        </Carousel.Item>)}

                    </Carousel>
                    </div>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default AnnouncementPreview
