export enum EFeedStatus {
    NEW = 'NEW',
    DRAFT= 'DRAFT',
    IN_REVIEW = 'IN_REVIEW',
    INITIATED='INITIATED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    DELETED = 'DELETED',
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    STOPPED = 'STOPPED',
    SCHEDULED='SCHEDULED',
}

export enum EFeedUsers {
    ADVERTIZER= "ADVERTIZER",
    USER='USER'
}