/** @format */

import React, { useState } from "react";
import { Input, Table } from "reactstrap";
import RemarkIcon from "../../assets/images/icons/remark.png";
import InvoiceIcon from "../../assets/images/icons/invoice.png";

import moment from "moment";

// import RemarkModal from "../../common-components/RemarkModal";
import RemarkPreviewModal from "../../common-components/RemarkPreviewModal";
import {
  IFranchisePaymentDetails,
  IFranchisePaymentDocuments,
  ISelectedFranchise,
} from "../../interface/franchise.interface";
import { getDocumentById } from "../../services/FranchiseApiService";
import { convertToMonthAndYear } from "../../core/services/utilityService";

export interface TitleProps {
  franchiseList: IFranchisePaymentDetails[];
  onSelectAll: any;
  selectedFranchise: ISelectedFranchise[];
  setSelectedFranchise: any;
}

const PaymentTable: React.FC<TitleProps> = ({
  franchiseList,
  onSelectAll,
  selectedFranchise,
  setSelectedFranchise,
}) => {
  const [showRemarkModal, setShowRemarkModal] = useState<boolean>(false);
  const [modalRemark, setModalRemark] = useState<string>("");

  const handleRemarksButton = async (remarks: string | undefined) => {
    if (remarks?.length) {
      remarks && setModalRemark(remarks);
      setShowRemarkModal(true);
    }
  };

  const handleInvoiceeDownload = async (invoiceId: string) => {
    let res = await getDocumentById(invoiceId);
    if (res.response) {
      window.open(res.response, "_blank");
    }
  };

  const handleDocumentDownload = async (
    docData: IFranchisePaymentDocuments[]
  ) => {
    const document = docData?.filter(
      (doc) => doc.document_type === "FRANCHISE_PAYMENT_DOCUMENTS"
    );
    if (document.length) {
      window.open(document[0]?.location, "_blank");
    }
  };

  return (
    <>
      <div className="mb-3">
        <Table responsive className="listing-table font-12 ">
          <thead>
            <tr>
              <th>
                <Input
                  type="checkbox"
                  name="selectAll"
                  onChange={(e) => {
                    onSelectAll(e);
                  }}
                />
              </th>
              <th>
                <div className="text-nowrap text-center">Invoice</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Total Revenue</div>
              </th>
              <th>
                <div className="text-nowrap text-center">
                  Total Profit Margin
                </div>
              </th>
              <th>
                <div className="text-nowrap text-center">Paid Amount</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Month of Payment</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Pending Amount</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Issued Date</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Mode of Payment</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Remarks</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Document</div>
              </th>
              <th>
                <div className="text-nowrap text-center">Invoice</div>
              </th>
            </tr>
          </thead>
          <tbody className="first-row">
            {franchiseList?.map((data: any) => {
              return (
                <tr key={data.payment_id}>
                  <td>
                    <div>
                      <Input
                        name=""
                        type="checkbox"
                        checked={selectedFranchise.some(
                          (franchise: any) => franchise.id === data.payment_id
                        )}
                        onChange={() => {
                          if (
                            selectedFranchise.some(
                              (franchise: any) =>
                                franchise.id === data.payment_id
                            )
                          ) {
                            setSelectedFranchise((prevSelectedData: any) =>
                              prevSelectedData.filter(
                                (ele: any) => ele.id !== data.payment_id
                              )
                            );
                          } else {
                            setSelectedFranchise((prevSelecteData: any) => [
                              ...prevSelecteData,
                              {
                                id: data.payment_id,
                                name: data.franchise_name,
                              },
                            ]);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-nowrap">
                      {data.invoice_number}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-nowrap">
                      {data.total_revenue}
                    </div>
                  </td>
                  <td className="text-nowrap text-start">
                    {data.total_profit_margin}
                  </td>
                  <td className="text-nowrap text-start">{data.paid_amount}</td>
                  <td className="text-nowrap text-start">{convertToMonthAndYear(data.payment_date)}</td>
                  <td className="text-nowrap text-start">
                    {data.pending_amount}
                  </td>
                  <td className="text-nowrap text-start">
                    {moment(data.payment_date).format("DD - MM - YYYY")}
                  </td>
                  <td className="text-nowrap text-start">
                    {data.mode_of_payment}
                  </td>
                  <td className="text-nowrap">
                    {data.remarks && (
                      <img
                        src={RemarkIcon}
                        alt="Remark"
                        className="grayscale-img"
                        loading="lazy"
                        onClick={() => handleRemarksButton(data.remarks)}
                      />
                    )}
                  </td>
                  <td>
                    <div
                      className=""
                      onClick={() => handleDocumentDownload(data.documents)}
                    >
                      <img src={InvoiceIcon} loading="lazy" alt="Document" />
                    </div>
                  </td>
                  <td>
                    <div
                      className=""
                      onClick={() =>
                        handleInvoiceeDownload(data.invoice_document_id)
                      }
                    >
                      <img src={InvoiceIcon} loading="lazy" alt="Invoice" />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <RemarkPreviewModal
        showRemarkModal={showRemarkModal}
        setShowRemarkModal={setShowRemarkModal}
        remark={modalRemark}
        setModalRemark={setModalRemark}
      />
    </>
  );
};

export default PaymentTable;
