import React, { FC, useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { IGraphData, IGraphProperty } from "../interface/overview.interface";
import "chart.js/auto";
import { Chart, registerables, LegendItem } from "chart.js";

Chart.register(...registerables);

interface TitleProps {
  data: IGraphData;
  label: string;
}

const DonutChart: FC<TitleProps> = ({ data, label }) => {
  const [reset, setReset] = useState(true);
  const [graphProperty, setGraphProperty] = useState<IGraphProperty>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    if (data && data.legends && data.data) {
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 100);
      const myCount = data.totalCount;
      const graphProps: IGraphProperty = {
        labels: data.legends,
        datasets: [
          {
            label: label ? label : "",
            data: data.data,
            backgroundColor: [
              "rgba(255, 169, 169, 1)",
              "rgba(245, 186, 255, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(255, 236, 186, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 0,
          },
        ],
      };

      const centerText = {
        id: "textCenter",
        beforeDatasetsDraw(chart: any) {
          const { ctx } = chart;
          ctx.save();
          ctx.font = "bolder 28px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(
            myCount ? performElipse(myCount) : "0",
            chart.getDatasetMeta(0).data[0]?.x,
            chart.getDatasetMeta(0).data[0]?.y
          );
          ctx.restore();
        },
      };

      const drawValues = {
        id: "drawValues",
        afterDatasetsDraw(chart: any) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;

          ctx.save();
          data.datasets.forEach((dataset: any, datasetIndex: number) => {
            chart.getDatasetMeta(datasetIndex).data.forEach((dataPoint: any, index: number) => {
              const { x, y } = dataPoint.tooltipPosition();
              const value = dataset.data[index];

              if(value !== 0){
                ctx.font = "bolder 20px sans-serif";
                ctx.fillStyle = "white";
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillText(value, x, y);
              }
             
            });
          });
          ctx.restore();
        },
      };

      setGraphProperty(graphProps);
      setTextCenter(centerText);
      setDrawValues(drawValues);
    }
  }, [data, label]);

  const legendData = {
    labels: graphProperty.labels,
    datasets: [
      {
        label: label ? label : "",
        data: Array(graphProperty.labels.length).fill(0),
        backgroundColor: graphProperty.datasets[0]?.backgroundColor || [],
        borderColor: graphProperty.datasets[0]?.borderColor || [],
        borderWidth: graphProperty.datasets[0]?.borderWidth || 0,
      },
    ],
  };

  const chartData = {
    labels: graphProperty.labels,
    datasets: graphProperty.datasets,
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    cutout: '50%',
    circumference: 360,
    rotation: 0,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const legendOptions = {
    ...options,
    plugins: {
      legend: {
        display: true,
        align: "start" as const,
        position: "left" as const,
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          padding: 12,
          font: {
            size: 10.5,
          },
        },
        onClick: (e: any, legendItem: LegendItem, legend: any) => {
          if (
            legendItem.datasetIndex !== undefined &&
            legendItem.index !== undefined
          ) {
            const chartInstance = legend.chart as Chart;
            const meta = chartInstance?.getDatasetMeta(legendItem.datasetIndex);

            if (meta && meta.data[legendItem.index]) {
              const data = meta.data[legendItem.index];
              const dataset = chartInstance.data.datasets[legendItem.datasetIndex];

              dataset.hidden = !dataset.hidden;

              chartInstance.update();
            }
          }
        },
      },
    },
  };

  const performElipse = (data: number) => {
    const value = data.toString()
    // if(value.length > 4){
    //   const trimmed = value.slice(0,3)
    //   return  trimmed+ '...'
    // }
    return value
  }

  const [textCenter, setTextCenter] = useState({
    id: "textCenter",
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();
      ctx.font = "bolder 24px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        data.totalCount ? performElipse(data.totalCount) : "",
        chart.getDatasetMeta(0).data[0]?.x,
        chart.getDatasetMeta(0).data[0]?.y
      );
      ctx.restore();
    },
  });

  const [drawValues, setDrawValues] = useState({
    id: "drawValues",
    afterDatasetsDraw(chart: any) {
      const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;

      ctx.save();
      data.datasets.forEach((dataset: any, datasetIndex: number) => {
        chart.getDatasetMeta(datasetIndex).data.forEach((dataPoint: any, index: number) => {
          const { x, y } = dataPoint.tooltipPosition();
          const value = dataset.data[index];

          ctx.font = "bolder 12px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(value, x, y - 10);
        });
      });
      ctx.restore();
    },
  });

  function areAllElementsZero(arr: number[]) {
    return arr.every(function (element) {
      return element === 0;
    });
  }

  const elllipsesAppender = (data: string) => {
    if (data.length >= 18) {
      const newData = data.slice(0, 15) + "...";
      return newData;
    } else {
      return data;
    }
  };

  return (
    <div className="py-3">
      {graphProperty.labels.length > 0 &&
        graphProperty.datasets[0].data.length > 0 && (
          <>
            {!areAllElementsZero(data.data) && reset && (
              <div className="row g-1">
                <div className="col-6 col-xxl-5">
                  {/* Custom legends */}
                  <div className="custom-legends">
                    {graphProperty.labels.map((label, index) => (
                      <div
                        key={index}
                        className="legend-item d-flex align-items-center legend-item-custom"
                      >
                        <div
                          className="legend-color mx-2"
                          style={{
                            backgroundColor:
                              graphProperty.datasets[0]?.backgroundColor[
                              index
                              ],
                            height: "15px",
                            width: "15px",
                            borderRadius: "50%",
                            fontWeight: "lighter",
                          }}
                        ></div>
                        <span className="legend-label">
                          {elllipsesAppender(label)}
                        </span>
                        <div className="legend-comment">{label}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-6 col-xxl-7">
                  <Doughnut
                    data={chartData}
                    options={options}
                    plugins={[textCenter, drawValues]}
                  />
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default DonutChart;
