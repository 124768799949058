import axios from "axios";
import { TInstanceType } from "../types/Instances.types";
import { doLogout } from "../../services/AuthApiService";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;
const apiCampaignBaseUrl = baseURL + "campaign/api/";
const proletUrlBaseUrl = baseURL + "prolet/api/";
const apiAdminBaseUrl = baseURL + "admin/api/";
const loginBaseUrl = baseURL + "user/";

const getAxiosInstance = (instance?: TInstanceType) => {
  let axiosInstance;

  if (instance === "PROLET") {
    axiosInstance = axios.create({ baseURL: proletUrlBaseUrl });
  } else if (instance === "USER") {
    axiosInstance = axios.create({ baseURL: baseURL + "user/api" });
  } else if (instance === "LOGIN") {
    axiosInstance = axios.create({ baseURL: loginBaseUrl });
  } else if (instance === "CAMPAIGN") {
    axiosInstance = axios.create({ baseURL: apiCampaignBaseUrl });
  } else if (instance === "ADMIN") {
    axiosInstance = axios.create({ baseURL: apiAdminBaseUrl });
  } else {
    axiosInstance = axios.create({ baseURL: apiAdminBaseUrl });
  }

  axiosInstance.interceptors.response.use(
    function (response) {
      if(response && response.data && response.data.status && response.data.status.message){
        if(response.data.status.code !== 200){
          toast.error(response.data.status.message)
        }else{
          toast.success(response.data.status.message)
        }
      }
      return response;
    },
    async function (error) {
      if (error.response.status === 401 && instance !== "LOGOUT") {
        await doLogout();
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const getBaseURL = () => {
  return baseURL;
};

export { getAxiosInstance, getBaseURL };
