import React from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import RemarkIcon from "../assets/images/icons/remark-icon.png";

export interface IRemarkPreviewModal {
  showRemarkModal: boolean;
  setShowRemarkModal: any;
  remark?: string | any[];
  setModalRemark?: any
}


const RemarkPreviewModal: React.FC<IRemarkPreviewModal> = ({
  showRemarkModal,
  setShowRemarkModal,
  remark,
}) => {

  return (
    <div>
      <Modal
        isOpen={showRemarkModal}
        className="admin-details-modal modal-xl"
        style={{ maxWidth: "996px" }}>
        <ModalHeader toggle={() => setShowRemarkModal(!showRemarkModal)}>
          <div>
            <img
              src={RemarkIcon}
              alt="Remark Icon"
              loading="lazy"
              width={"18px"}
            />
            <span className="font-16 ms-2 fw-500">
              Remark
            </span>
          </div>
        </ModalHeader>
        <ModalBody>

          <div
            className="choose-remark-statement px-3 py-2 mb-3">
            <Row className="align-items-center ">
              <Col
                sm="12"
                md="9"
                xl="10"
                xxl="10"
                className="border-right-grey">
                {remark && Array.isArray(remark)
                  ? remark?.map((data: any) => <p key={data.message} className="font-14 mb-0">{data.message}</p>)
                  : <p className="font-14 mb-0">{remark}</p>}
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end border-0">

        </ModalFooter>
      </Modal>

    </div>
  );
};

export default RemarkPreviewModal;
