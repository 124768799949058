import { useLocation } from "react-router-dom";
import FeedsTable from "./FeedsTable";

const FranchiseCompany = () => {
  const routerStates = useLocation();
  return (
    <div className="container">
      <div>
        <FeedsTable companyId={routerStates.state}/>
    </div>
    </div>
  );
};

export default FranchiseCompany;
