import { FC, useEffect, useRef, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-js-pagination";
import { Input, Table } from "reactstrap";
import PaginationIcon from "../assets/images/icons/pagination-left.png";
import Calendar from "../common-components/Calendar";
import DownloadIcon from "../assets/images/icons/download.png";
import TopMenuBar from "../common-components/TopMenuBar";
import { EOrder, EOrderSequencing, ESearchMatch } from "../core/enums/Search.enums";
import { getLocalStorageItem } from "../core/services/StorageService";
import { IFilterValues, IFranchiseCompanyList, IStatsCount } from "../interface/franchise.interface";
import { IDateRange } from "../interface/html-elements.interface";
import { getAndStoreFranchiseDeatils } from "../services/AuthApiService";
import { getFranchiseCompanyList, getFranchiseCompanyListExcel } from "../services/FranchiseApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { ICommonSearch } from "../core/interfaces/SearchRequest.interface";

import { OnboardedCompanyFilter } from "./OnboardedCompanyFilter";
interface TitleProps {
  title: string;
}
const OnboardedCompany: FC<TitleProps> = ({ title }) => {
  const routeState = useLocation();
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [franchiseList, setFranchiseList] = useState<IFranchiseCompanyList[]>([]);
  const [filterValues, setFilterValues] = useState<IFilterValues>({} as IFilterValues)
  const userId = useRef<string>('')
  const userRefCode = useRef<string>('');
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    getCompanyList();
  }, [
    dateRange, 
    currentPage, 
    pageSize, 
    filterValues
  ]);

  useEffect(() => {
    const allRowsSelected = franchiseList.length === selectedRows.size;
    setSelectAll(allRowsSelected);
  }, [selectedRows, franchiseList]);


  const initializeSearch = () => {
    let search: any = {
      page: {
        no: currentPage,
        size: pageSize,
      },
      query: {
        filter: [
        ],
        search: {} as ICommonSearch
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };

    if (userId.current) {
      search.query.filter.push(
        {
          fields: ["franchise_id"],
          search: userId.current ? userId.current : '',
          search_match: ESearchMatch.EXACT,

        });
    }
    if (filterValues && filterValues.locations && filterValues.locations.fields) {
      search.query.filter.push(filterValues.locations);
    }
    if (filterValues && filterValues.salesPerson && filterValues.salesPerson.fields) {
      search.query.filter.push(filterValues.salesPerson);
    }
    if ((routeState && routeState.state && routeState.state.user_ref_code) || userRefCode.current) {
      search.query.filter.push({
        fields: ["franchise_seller_ref_code"],
        search: userRefCode.current ? userRefCode.current : routeState.state.user_ref_code,
        search_match: ESearchMatch.EXACT,
      });
    }

    if (dateRange.startDate) {
      search.query.filter.push({
        fields: ["created_on"],
        between: [dateRange.startDate, dateRange.endDate],
        search_match: ESearchMatch.EXACT,
      });
    }
    if (searchQuery) {
      search.query.search = {
        fields: ["company_name",
          "location",
          "sales_person",
          "franchise_seller_ref_code",
          "franchise_ref_code",
          "franchise_name",
        ],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    return search;
  };

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageCountChange = (eventKey: any) => {
    setPageSize(eventKey);
    setCurrentPage(1);
  };

  const getCompanyList = async () => {
    try {
      await getAndStoreFranchiseDeatils()
      const userProfileData = getLocalStorageItem('userProfile')
      if (userProfileData.user_type === "FRANCHISE_ADMIN") {
        userId.current = userProfileData?.franchise_id
        userRefCode.current = ""
      } else {
        userRefCode.current = userProfileData?.referral_code
        userId.current = ""
      }
      const searchRequest = initializeSearch();
      const res = await getFranchiseCompanyList(searchRequest);
      setTotalPagesCount(res.response.total_elements);
      setFranchiseList(res?.response.content);
      setTotalElements(res?.response.total_elements)
    } catch (error) { }
  };

  const getStatsCount = (statsArray: IStatsCount[], field: string): number => {
    const stats = statsArray.find(ele => ele.status === field);
    if (stats) {
      return stats.count
    }
    return 0
  }

  const onDownloadExcel = async (): Promise<void> => {
    const searchRequest = initializeSearch();
    getFranchiseCompanyListExcel(searchRequest).then((response) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'onboarded-companies.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
  }
  const toggleRowSelection = (index: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(index)) {
      updatedSelectedRows.delete(index);
    } else {
      updatedSelectedRows.add(index);
    }
    setSelectedRows(updatedSelectedRows);
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows(new Set());
    } else {
      const allIndexes = Array.from({ length: franchiseList.length }, (_, i) => i);
      setSelectedRows(new Set(allIndexes));
    }
    setSelectAll(!selectAll);
  };

  const handleSearchIconClick = async () => {
    setSelectedRows(new Set())
    let searchRequest = initializeSearch();

    if (searchQuery && searchQuery.length > 0) {
      searchRequest.query.search = {
        fields: ["company_name",
        "location",
        "sales_person",
        "franchise_seller_ref_code",
        "franchise_ref_code",
        "franchise_name",],
        search: searchQuery,
        // search_match:ESearchMatch.CONTAINS
      };
      setCurrentPage(1);
    }
    try {
      let data: any;
     
        data = await getFranchiseCompanyList(searchRequest);
        setFranchiseList(data.response.content);
    
      setTotalElements(data.response.total_elements);
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const clearSearchField = async () => {
    setSearchQuery("");
    getCompanyList()
  };

  return (
    <>
      <div className="container-fluid">
      <TopMenuBar
          setSearchQuery={setSearchQuery}
          onSearchIconClick={handleSearchIconClick}
          searchQuery={searchQuery}
          clearField={clearSearchField}
        />
        <OnboardedCompanyFilter isBillingPage={false} setFilterValues={setFilterValues} />

        <div className="row pb-3">
           <div className="d-md-flex justify-content-between">
              <p className="my-1" style={{color:"#006699",fontWeight:500}}>Total Records : {totalElements}</p>
                  <div className="d-sm-flex align-items-center justify-content-end">
              <button className="download-report-btn mb-1 mb-sm-0" onClick={() => onDownloadExcel()}>
                <img src={DownloadIcon} alt="Download" className="download-icon me-1" loading="lazy" />
                Download Report
              </button>
              <div className="ps-sm-4">
                <Calendar
                  className="calender"
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </div>
            </div>
            </div>
        </div>
        <div className="mb-3">
          <Table responsive className="listing-table font-12 ">
            <thead>
              <tr>
                <th>
                  {franchiseList.length > 0 ?
                                    <Input type="checkbox"
                                    name="selectAll"
                                    checked={selectAll}
                                    onChange={handleSelectAll} />: ''}

                </th>
                <th>
                  <div className="text-nowrap text-center">
                    Company Name
                  </div>
                </th>
                <th>
                  <div className="text-nowrap text-center">
                    Location
                  </div>
                </th>
                <th>
                  <div className="text-nowrap text-center">
                    Sales person
                  </div>
                </th>
                <th>
                  <div className="text-nowrap text-center">
                    Referral Code (franchise)
                  </div>
                </th>
                <th colSpan={8}>
                  <div className="text-center">Campaigns</div>
                </th>
                <th colSpan={6}>
                  <div className="text-center">Total Interests</div>
                </th>
                <th colSpan={5}>
                  <div className="text-center">Meetings</div>
                </th>
                <th colSpan={1}>
                  <div className="text-center">Total&nbsp;Revenue</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={1}>

                </td>
                <td colSpan={1}></td>
                <td colSpan={1}></td>
                <td colSpan={1}></td>
                <td colSpan={1}></td>

                <td colSpan={1}>All</td>
                <td colSpan={1}>In&nbsp;Draft</td>
                <td colSpan={1}>In&nbsp;Review</td>
                <td colSpan={1}>Active</td>
                <td colSpan={1}>Expired</td>
                <td colSpan={1}>Deleted</td>
                <td colSpan={1}>Rejected</td>
                <td colSpan={1}>Paused</td>

                <td colSpan={1}>All</td>
                <td colSpan={1}>Pending</td>
                <td colSpan={1}>Confirmed</td>
                <td colSpan={1}>Rescheduled</td>
                <td colSpan={1}>Iterated</td>
                <td colSpan={1}>Cancel</td>

                <td colSpan={1}>All</td>
                <td colSpan={1}>Pending</td>
                <td colSpan={1}>Completed</td>
                <td colSpan={1}>Rescheduled</td>
                <td colSpan={1}>Cancelled</td>

                <td colSpan={1}></td>
              </tr>
              {franchiseList?.map((data, i) => {
                return (
                  <tr key={'on-boarded-company' + i} >
                    <td>
                      <div>
                        <Input type="checkbox"
                          name={`selectRow-${i}`}
                          checked={selectedRows.has(i)}
                          onChange={() => toggleRowSelection(i)} />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center text-nowrap">
                        {data.company_name}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center text-nowrap">
                        {data.location}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center text-nowrap">
                        {data.sales_person} ({data.franchise_seller_ref_code})
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center text-nowrap">
                        {data.franchise_name} ({data.franchise_ref_code})
                      </div>
                    </td>

                    {/* campaign */}
                    <td className="text-nowrap text-start pointer"
                      onClick={() => navigate("/franchise-company", { state: data.user_id })}>
                      {getStatsCount(data.campaign_counts, 'ALL')}
                    </td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'DRAFT')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'IN_REVIEW')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'ACTIVE')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'EXPIRED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'DELETED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'REJECTED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.campaign_counts, 'STOPPED')}</td>
                    {/* Interest */}
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'ALL')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'INITIATED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'SCHEDULED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'RESCHEDULED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'ITERATED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'CANCELLED')}</td>
                    {/* meeting */}
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'ALL')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'SCHEDULED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'COMPLETED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'RESCHEDULED')}</td>
                    <td className="text-nowrap text-start">{getStatsCount(data.interest_counts, 'CANCELLED')}</td>

                    <td className="text-nowrap text-start"> &#8377; {data.revenue}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {selectedRows.size > 0 ? (
          <div className="selected-item-text">
            {selectedRows.size} Items Selected
          </div>
        ) : null}
        </div>
        <div className="row align-items-center pt-25 pb-50 pagination-section py-3">
          <div className="col-10 text-end mt-1">
            <form className="d-flex justify-content-end align-item">
              <span className="text-primary-blue pe-1 font-12 d-flex align-items-center">
                Show
              </span>
              <div className="total-pages me-2 position-relative">
                <span className="d-flex align-items-center">{pageSize}</span>
                <DropdownButton onSelect={onPageCountChange} title={""}>
                  <Dropdown.Item eventKey="10">10</Dropdown.Item>
                  <Dropdown.Item eventKey="25">25</Dropdown.Item>
                  <Dropdown.Item eventKey="50">50</Dropdown.Item>
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>
              </div>
            </form>
          </div>
          <div className="col-2">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalPagesCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              prevPageText={<img src={PaginationIcon} alt="Previous" />}
              nextPageText={<img src={PaginationIcon} alt="Next" />}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardedCompany;
