import React, { FC, useEffect, useState, useRef } from "react";
import TotalSalePeopleGraph from "./TotalSalePeopleGraph";
import Calendar from "../../common-components/Calendar";
import DownloadIcon from "../../assets/images/icons/download.png";
import { IDateRange } from "../../interface/html-elements.interface";
import OnboardedCompanyGraph from "./OnboardedCompanyGraph";
import TotalInterestGraph from "./TotalInterestGraph";
import TotalMeetingGraph from "./TotalMeetingGraph";
import TotalRevenueGraph from "./TotalRevenueGraph";
import TotalProfitGraph from "./TotalProfitGraph";
import html2canvas from "html2canvas";
import TopMenuBar from "../../common-components/TopMenuBar";
import { getLocalStorageItem, getUserData } from "../../core/services/StorageService"
import { EUserType } from "../../enums/user.enum"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ICommonSearch } from "../../core/interfaces/SearchRequest.interface";
import { downloadOverviewCSV } from "../../services/reports/ReportApiService";
import { useOutsideClick } from "../../core/services/utilityService";
import { ESearchMatch } from "../../core/enums/Search.enums";


interface TitleProps {
  title: string;
}

const Overview: FC<TitleProps> = ({ title }) => {
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: `${new Date().getDate()}/${new Date().getMonth() - 1}/${new Date().getFullYear()}`,
    endDate: getDefaultStartDate(),
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userProfile = getLocalStorageItem("userProfile")
  const userType = userProfile?.user_type
  const franchiseId = userProfile?.franchise_id
  const [toggleDownloadDropDown, setToggleDownloadDropDown] = useState<boolean>(false)
  const downloadPopUpRef = useRef<HTMLDivElement>(null);

  useOutsideClick(downloadPopUpRef, () => setToggleDownloadDropDown(false));

  const initializeSearch = () => {
    let search: any = {
      page: {
        no: 1,
      },
      query: {
        filter: [],
        search: {} as ICommonSearch,
      },
      sort: [],
    };

    return search;
  };

  const DownloadReportAsCSV = async () => {
    let searchRequest = initializeSearch();

    searchRequest.query.filter.push({
      fields: ["franchise_id"],
      search: franchiseId,
      search_match: ESearchMatch.EXACT,
    
    })
    
    downloadOverviewCSV(searchRequest).then((responses) => {
      download(responses);
    });

    setToggleDownloadDropDown(false);
  };
  const download = async (response: Blob) => {
    const blob = new Blob([response], {
      type: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls , .xlsx",
    });
  saveAs(blob, 'reports.xlsx');
  }

  const cardIds = [
    'total-sale-people-graph',
    'onboarded-company-graph',
    'total-interest-graph',
    'total-meeting-graph',
    'total-revenue-graph',
    'total-profit-graph',
  ];



  const saveAsPNG = () => {
    const zip = new JSZip();

    const previousDateRangeString = `Start Date: ${new Date().getDate()}/${new Date().getMonth() - 1}/${new Date().getFullYear()}, End Date: ${getDefaultStartDate()}`
    const dateRangeString = `Start Date: ${dateRange.startDate}, End Date: ${dateRange.endDate}`;

    cardIds.forEach((cardId, index) => {
      const element = document.getElementById(cardId);
      if (element) {
        html2canvas(element).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgName = `${cardId}.png`;
          zip.file(imgName, imgData.split(',')[1], { base64: true });
          if (index === cardIds.length - 1) {
            zip.generateAsync({ type: 'blob' }).then(blob => {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = `ovrview-cards${previousDateRangeString !== dateRangeString ? `-${dateRangeString}` : ""}.zip`;
              link.click();
            });
          }
        });
      }
    });

    setToggleDownloadDropDown(false);
  };


  return (
    <>
      <div className="container-fluid">
        <TopMenuBar searchQuery={searchQuery} hideSearchBar={true} setSearchQuery={setSearchQuery} />

        <div className="p-3">
          {/* <h1 className="font-16 fw-semibold pb-3">{title}</h1> */}
          <div className="row gy-3">
            <div className="col-12 text-end">
              <div className="d-md-flex align-items-center justify-content-end">
                <div className="grid justify-center align-center"
                  style={{ position: "relative" }}>
                  <button className="download-report-btn mb-1  " onClick={() => setToggleDownloadDropDown(!toggleDownloadDropDown)}>
                    <img src={DownloadIcon} alt="Download" className="download-icon me-1" loading="lazy" />
                    Download Report
                  </button>
                  {
                    toggleDownloadDropDown ? (
                      <div
                        ref={downloadPopUpRef} className="bg-white border border-gray-500 rounded-lg flex justify-center align-center"
                        style={{ position: "absolute", zIndex: 9999, width: "220px", right: "0" }}>
                        <div className="border border-solid border-gray-400 text-start px-2" onClick={DownloadReportAsCSV}> Download Card Data ( csv.)</div>
                        <div className="border border-solid border-gray-400 text-start px-2" onClick={saveAsPNG}> Download as Image ( png.)</div>
                      </div>
                    ) : null
                  }
                </div>
                <div className="ps-md-4 mb-1">
                  <Calendar
                    className="calender"
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gy-3" id="element-to-capture">
                {userType === EUserType.FRANCHISE_ADMIN && (
                  <div className="col-12 col-md-6">
                    <div className="card p-3 position-relative" id="total-sale-people-graph">
                      <TotalSalePeopleGraph globalDateRange={dateRange} />
                      <div className="row">
                        <div className="col-6">
                          <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                            This counts will be based on onboarding companies
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 col-md-6">
                <div className="card p-3" id="onboarded-company-graph">
                <OnboardedCompanyGraph globalDateRange={dateRange} />
                    <div className="row">
                      <div className="col-6">
                        <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                        This counts will be based on onboarding companies
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card p-3" id="total-interest-graph">
                    <TotalInterestGraph globalDateRange={dateRange} />
                    <div className="row">
                      <div className="col-6">
                        <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                          This counts will be based on companies Interests
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card p-3" id="total-meeting-graph">
                    <TotalMeetingGraph globalDateRange={dateRange} />
                    <div className="row">
                      <div className="col-6">
                        <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                          This counts will be based on companies Meetings
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card p-3" id="total-revenue-graph">
                    <TotalRevenueGraph globalDateRange={dateRange} />
                    <div className="row">
                      <div className="col-6">
                        <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                          This counts will be based on Sales Persons Earned Revenue (Indian Rupee)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card p-3" id="total-profit-graph">
                    <TotalProfitGraph globalDateRange={dateRange} />
                    <div className="row">
                      <div className="col-6">
                        <p style={{ fontSize: '11px', fontWeight: 'lighter' }}>
                          This counts will be based on Total Profit of Sales Persons (Indian Rupee)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
const getDefaultStartDate = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  return `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
};