import React, { FC, useEffect, useState } from "react";
import ShowPassword from "../assets/images/icons/eye-show.png";
import HidePassword from "../assets/images/icons/eye-hide.png";
import { changePasswordHandler } from "../services/AuthApiService";
import { getAccessToken, clearLocalStorage } from "../core/services/StorageService";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";
import { doLogout } from "../services/AuthApiService";
import PromiloLogo from "../assets/images/promilo-logo.png";

interface TitleProps {
  title: string;
}

const ResetPassword: FC<TitleProps> = ({ title }) => {
  const resetToken =
    new URLSearchParams(window.location.search).get("token") ?? "";

  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSaveClick = async () => {
    // Reset error messages

    const newPassErrMsg = !newPassword.trim().length
      ? "New Password is required"
      : newPassword.length < 6
        ? "New Password should be at least 6 characters long"
        : newPassword.includes(" ")
          ? "New Password should not contain spaces"
          : ""

    setNewPasswordError(newPassErrMsg)

    const confirmPassErrMsg = !confirmPassword.trim().length
      ? "Confirm Password is required"
      : confirmPassword !== newPassword
        ? "Passwords do not match"
        : ""

    setConfirmPasswordError(confirmPassErrMsg)


    if (newPassErrMsg === "" && confirmPassErrMsg === "") {
      setNewPasswordError("");
      setConfirmPasswordError("")

      // Add your code to save the data here
      //   alert("Password change successful!");
      const payload: {
        token: string;
        password: string;
        confirm_password: string;
      } = {
        token: resetToken,
        password: sha256(newPassword),
        confirm_password: sha256(confirmPassword),
      }

      try {
        const response = await changePasswordHandler(payload);
        navigate("/");
      } catch { }
    }
  };
  const onLogoutClick = async () => {
    await doLogout();
    clearLocalStorage()
    window.location.reload()
  };
  const oldToken = getAccessToken();
  useEffect(() => {
    if (oldToken)
      onLogoutClick()
  }, [])
  return (
    <>
      <div className="container-fluid">
        <div className="p-3">
          <div className="d-flex justify-content-center my-5 py-5">
            <img src={PromiloLogo} alt="" />
          </div>

          <form
            action=""
            onSubmit={(e) => e.preventDefault()}
            className="change-password-form max-width-300"
          >
            <h1 className="font-16 fw-semibold pb-3">{title}</h1>
            <div className="pb-3">
              <label htmlFor="" className="label-text pb-1">
                Create new Password*
              </label>
              <div className="position-relative">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  className="password-input w-100 p-2"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img
                  src={newPasswordVisible ? ShowPassword : HidePassword}
                  alt={newPasswordVisible ? "Hide Password" : "Show Password"}
                  className="eye-icon position-absolute"
                  onClick={toggleNewPasswordVisibility}
                />
              </div>
              <div className="text-danger font-10 pt-1">{newPasswordError}</div>
            </div>

            <div className="pb-3">
              <label htmlFor="" className="label-text pb-1">
                Confirm Password*
              </label>
              <div className="position-relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="password-input w-100 p-2"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  src={confirmPasswordVisible ? ShowPassword : HidePassword}
                  alt={
                    confirmPasswordVisible ? "Hide Password" : "Show Password"
                  }
                  className="eye-icon position-absolute"
                  onClick={toggleConfirmPasswordVisibility}
                />
              </div>
              <div className="text-danger font-10 pt-1">
                {confirmPasswordError}
              </div>
            </div>

            <div className="pt-3">
              <button
                className="w-100 save-btn text-white border-0"
                onClick={handleSaveClick}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
