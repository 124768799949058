import { createContext } from 'react';

interface NotificationBadgeContextType {
    newEmail: number;
    setNewEmail: React.Dispatch<React.SetStateAction<number>>;
    newAnnouncement: number;
    setNewAnnouncement: React.Dispatch<React.SetStateAction<number>>;
    totalNotification: number;
    setTotalNotification: React.Dispatch<React.SetStateAction<number>>;
}

const NotificationBadgeContext = createContext<NotificationBadgeContextType>({} as NotificationBadgeContextType);

export default NotificationBadgeContext;