import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  EOrder,
  EOrderSequencing,
  ESearchMatch,
} from "../../core/enums/Search.enums";
import { ICommonSearch } from "../../core/interfaces/SearchRequest.interface";
import { convertToDDMMYYYYSeperatedByHypen } from "../../core/services/utilityService";
import { IDateRange } from "../../interface/html-elements.interface";
import {
  IReportGraphRes,
  IReportGraphResArray,
  IReportUsersList,
} from "../../interface/report/Report.interface";
import {
  getCategoryStatus,
  getCompanyCategories,
  getReportGraph,
  getReportUsersCompanies,
} from "../../services/reports/ReportApiService";
// import { Dropdown } from 'reactstrap';
import {formatString} from "../../core/services/utilityService"

interface TitleProps {
  reportUsersList: IReportUsersList[];
  dateRange: IDateRange;
  setSelectedUsersChartData: any;
  reportIndex: number;
  selectedUsersChartData: any;
  setSelectedAllUsersData: any;
  selectedAllUsersData: any;
}
interface NestedDropdownProps {
  title: string;
  children?: React.ReactNode;
  onClick?: any;
  show?: boolean;
}

const NestedDropdown: React.FC<NestedDropdownProps> = ({
  show,
  title,
  children,
  onClick,
}) => {
  return (
    <Dropdown show={show} onClick={onClick}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};


const convertAndAddRatio = (data: IReportGraphRes): IReportGraphRes => {
  const validResponse = data.response.filter(
    (item: IReportGraphResArray) => !isNaN(item.count)
  );

  const maxCount = Math.max(
    ...validResponse.map((item: IReportGraphResArray) => item.count)
  );

  const mappedArray = validResponse.map((item: IReportGraphResArray) => ({
    ...item,
    ratio:!isNaN(item.count) ? item.count  : 0,
  }));

  return {
    response: mappedArray,
    total: data.total,
  };
};

const Reports: React.FC<TitleProps> = ({
  reportIndex,
  selectedUsersChartData,
  reportUsersList,
  dateRange,
  setSelectedUsersChartData,
  selectedAllUsersData,
  setSelectedAllUsersData,
}) => {
  const [activeUser, setActiveUser] = useState<any>({});
  const [companyList, setCompanyList] = useState<any>([]);

  const [activeCompany, setActiveCompany] = useState<any>({});
  const [categoryList, setCategoryList] = useState<any>([]);

  const [activeCategory, setActiveCategory] = useState("");
  const [statusList, setStatusList] = useState<any>([]);

  const [selectedData, setSelectedData] = useState({
    selectedCategory: {},
    selectedUser: { user_name: "", user_referral_code: "", referral_code: "" },
    selectedCompany: { company_name: "" },
    selectedStatus: { category_sub_name: "" },
  });

  const initializeSearch = ({
    refCode,
    userRefCode,
  }: {
    refCode: string;
    userRefCode: string;
  }) => {
    let search: any = {
      page: {
        no: 1,
        size: 10,
      },
      query: {
        filter: [
          {
            fields: ["franchise_ref_code"],
            search: refCode,
            search_match: ESearchMatch.EXACT,
          },
          {
            fields: ["franchise_seller_ref_code"],
            search: userRefCode,
            search_match: ESearchMatch.EXACT,
          },
        ],
        search: {} as ICommonSearch,
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };
    return search;
  };

  const fetchCompany = useCallback(
    async (users: any, e?: MouseEvent) => {
      e?.stopPropagation();
      if (activeUser?.user_id === users?.user_id) {
        setActiveUser("");
        setActiveCompany("");
        setActiveCategory("");
        return;
      } else {
        setActiveUser(users);
      }
      try {
        setCompanyList([]);
        const searchRequest = initializeSearch({
          refCode: users.referral_code,
          userRefCode: users.user_referral_code,
        });
        const res = await getReportUsersCompanies(searchRequest);
        if (res.response.content.length) {
          setCompanyList(res.response.content);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [activeUser]
  );

  const fetchCategories = useCallback(
    async (company: any, e?: MouseEvent) => {
      e?.stopPropagation();
      if (activeCompany?.company_name === company.company_name) {
        setActiveCompany("");
        setActiveCategory("");
        return;
      } else {
        setActiveCompany(company);
      }
      try {
        setCategoryList([]);
        const res = await getCompanyCategories();
        if (res.response.length) {
          setCategoryList(res.response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [activeCompany]
  );

  const fetchStatus = useCallback(
    async (category: any, e?: MouseEvent) => {
      e?.stopPropagation();
      if (activeCategory === category) {
        setActiveCategory("");
        return;
      } else {
        setActiveCategory(category);
      }
      try {
        setStatusList([]);
        const res = await getCategoryStatus(category);
        if (res.response.length) {
          setStatusList(res.response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [activeCategory]
  );

  const fetchGraph = async () => {
    try {
      const paramsData = {
        category: selectedData.selectedCategory,
        user_ref_code: selectedData.selectedUser.user_referral_code,
        franchise_ref_code: selectedData.selectedUser.referral_code,
        sub_category: selectedData.selectedStatus.category_sub_name,
        company_name: selectedData.selectedCompany.company_name,
        from_date: convertToDDMMYYYYSeperatedByHypen(dateRange.startDate),
        to_date: convertToDDMMYYYYSeperatedByHypen(dateRange.endDate),
      };
      const res = await getReportGraph(paramsData);
      if (res.response) {
        setSelectedUsersChartData((prevData: any) => {
          const updateData = [...prevData];
          const mappedArrayRatioBased = convertAndAddRatio(res.response);
          updateData[reportIndex] = {
            ...mappedArrayRatioBased,
            selectedStatus: selectedData.selectedStatus,
          };
          return [...updateData];
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const { selectedCategory, selectedStatus, selectedUser, selectedCompany } =
      selectedData;
    if (
      selectedCategory &&
      selectedUser.user_referral_code &&
      selectedCompany.company_name &&
      selectedStatus
    ) {
      fetchGraph();
    }
  }, [selectedData, dateRange]);

  const onStatusClick = (status: any, e?: any) => {
    setActiveUser({});
    setActiveCompany({});
    setActiveCategory("");
    setSelectedData({
      selectedCategory: activeCategory,
      selectedUser: activeUser,
      selectedCompany: activeCompany,
      selectedStatus: status,
    });
    setSelectedAllUsersData((prevData: any) => {
      const updateData = [...prevData];
      updateData[reportIndex] = {
        selectedCategory: activeCategory,
        selectedUser: activeUser,
        selectedCompany: activeCompany,
        selectedStatus: status,
      };
      return [...updateData];
    });
  };

  return (
    <>
      <div className="user-report-options d-flex justify-content-between flex-column">
        <div className="font-10 report-breadcrumb">
          {selectedData &&
          selectedData.selectedUser &&
          selectedData.selectedUser.user_name
            ? selectedData.selectedUser.user_name + " > "
            : ""}
          {selectedData &&
          selectedData.selectedCompany &&
          selectedData.selectedCompany.company_name
            ? selectedData.selectedCompany.company_name + " > "
            : ""}
          {selectedData &&
          selectedData.selectedCategory &&
          typeof selectedData.selectedCategory === "string"
            ? selectedData.selectedCategory + " > "
            : ""}
          {selectedData &&
          selectedData.selectedStatus &&
          selectedData.selectedStatus.category_sub_name
            ? selectedData.selectedStatus.category_sub_name
            : ""}
        </div>

        <NestedDropdown
          title={
            selectedData?.selectedUser?.user_name
              ? selectedData?.selectedUser?.user_name
              : "Select user"
          }
        >
          <>
            {reportUsersList?.map((users: any) => {
              return (
                <NestedDropdown
                  show={activeUser.user_id === users.user_id}
                  title={users?.user_name}
                  onClick={(e: MouseEvent) => {
                    fetchCompany(users, e);
                  }}
                >
                  <>
                    {companyList?.map((company: any) => (
                      <NestedDropdown
                        show={
                          activeCompany.company_name === company.company_name
                        }
                        title={company?.company_name}
                        onClick={(e: MouseEvent) => {
                          fetchCategories(company, e);
                        }}
                      >
                        <>
                          {categoryList?.map((category: any) => (
                            <NestedDropdown
                              show={activeCategory === category}
                              title={category}
                              onClick={(e: MouseEvent) => {
                                fetchStatus(category, e);
                              }}
                            >
                              <>
                                {statusList?.map((status: any) => {
                                  const optionDisabled = () => {
                                    return !!selectedAllUsersData?.filter(
                                      (x: any) => {
                                        return (
                                          x?.selectedUser?.user_id ===
                                            users?.user_id &&
                                          x?.selectedCompany.company_name ===
                                            company?.company_name &&
                                          x?.selectedCategory === category &&
                                          x?.selectedStatus?.category_sub_id ===
                                            status?.category_sub_id
                                        );
                                      }
                                    )?.length;
                                  };
                                  return (
                                    <Dropdown.Item
                                      disabled={optionDisabled()}
                                      onClick={(e) => {
                                        onStatusClick(status, e);
                                      }}
                                    >
                                      {formatString(status?.category_sub_name)}
                                    </Dropdown.Item>
                                  );
                                })}
                              </>
                            </NestedDropdown>
                          ))}
                        </>
                      </NestedDropdown>
                    ))}
                  </>
                </NestedDropdown>
              );
            })}
          </>
        </NestedDropdown>
        <div className="report-index-value text-end">
          {selectedUsersChartData?.[reportIndex]?.total}
        </div>
      </div>
    </>
  );
};

export default Reports;
