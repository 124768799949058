import React, { useState, useEffect } from "react";
import { Table, Input, Button } from "reactstrap";
import moment from "moment";
import { useNavigate } from "react-router";
import PaginationIcon from "../assets/images/icons/pagination-left.png";
import Pagination from "react-js-pagination";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { EFeedStatus, EFeedUsers } from "../enums/feedStatus.enums";
import { EOrder, EOrderSequencing, ESearchMatch } from "../core/enums/Search.enums";
import { IFeedDetails, IFeedDetailsProps } from "../interface/feed.interface";
import { setLocalStorageItem } from "../core/services/StorageService";
import Calendar from "../common-components/Calendar";
import TopMenuBar from "../common-components/TopMenuBar";
import { getCampaign } from "../services/FranchiseApiService";
import { ICommonSearch } from "../core/interfaces/SearchRequest.interface";

const FeedsTable: React.FC<IFeedDetailsProps> = ({ userType, companyId }) => {
  const navigate = useNavigate();
  const [selectedCampaign, setSelectedCampaign] = useState<string>("");
  const [selectAllList, setSelectAllList] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | string[]>("");
  const [searchMatch, setSearchMatch] = useState<ESearchMatch>(
    ESearchMatch.EXACT
  );
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const [showApprovedFeedModal, setShowApprovedFeedModal] =
    useState<boolean>(false);
  const [campaignStatus, setCampaignStatus] = useState<string>("");
  const [showRemarkModal, setShowRemarkModal] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<IFeedDetails[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  
  const initializeSearch = () => {
    let search: any = {
      page: {
        no: currentPage,
        size: pageSize,
      },
      query: {
        filter: [],
        search: {} as ICommonSearch
      },
      sort: [
        {
          field: "last_updated_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };
    if(userType){
      search.query.filter.push({
        fields: ["user_type"],
        search:
          userType === EFeedUsers.ADVERTIZER
            ? EFeedUsers.ADVERTIZER
            : EFeedUsers.USER,
        search_match: ESearchMatch.EXACT,
      })
    }
    if(companyId){
      search.query.filter.push({
        fields: ["user_id"],
        search: companyId,
        search_match: ESearchMatch.EXACT,
      })
    }
    if (activeButton) {
      search.query.filter.push({
        fields: ["status"],
        search: activeButton,
        search_match: searchMatch,
      });
    }
    if (dateRange.startDate) {
      search.query.filter.push({
        fields: ["created_on"],
        between: [dateRange.startDate, dateRange.endDate],
        search_match: ESearchMatch.EXACT,
      });
    }
    if (searchQuery) {
      search.query.search = {
        fields: ["campaign_name", "user_name", "company_name", "title"],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    return search;
  };

  const getCompanyList = async () => {
    try {
      const searchRequest = initializeSearch();
      const res = await getCampaign(searchRequest);
      setTotalPagesCount(res.response.total_elements);
      setCompanyList(res?.response.content);
    } catch (error) {}
  };

  const handleButtonClick = (buttonText: string) => {
    if (buttonText === EFeedStatus.ACCEPTED) {
      setActiveButton(
        EFeedStatus.ACTIVE +
          "," +
          EFeedStatus.STOPPED +
          "," +
          EFeedStatus.EXPIRED
      );
      setSearchMatch(ESearchMatch.IN);
    } else {
      setActiveButton(buttonText);
      setSearchMatch(ESearchMatch.EXACT);
    }
  };
  const onNavigate = (campaign_id?: string): void => {
    setLocalStorageItem("userType", userType);
    if (EFeedUsers.USER === userType) {
      navigate(
        `/edit-user-feed?campaignId=${
          campaign_id ? campaign_id : selectedCampaign
        }`
      );
    } else {
      navigate(
        `/edit-company-feed?campaignId=${
          campaign_id ? campaign_id : selectedCampaign
        }`
      );
    }
    window.scrollTo(0, 0);
  };



  function convertText(inputText: string) {
    const words = inputText.split("_");
    const convertedText = words.map((word) => {
      return word.charAt(0).toUpperCase() + word?.slice(1)?.toLowerCase();
    });
    return convertedText.join(" ");
  }

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageCountChange = (eventKey: any) => {
    setPageSize(eventKey);
    setCurrentPage(1);
  };



  useEffect(() => {
    getCompanyList();
  }, [activeButton, dateRange, searchQuery, currentPage, pageSize]);
  
  return (
    <div className="">
      <TopMenuBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} showBackButton={true}/>
      {
        !companyId &&
      <div className="edit-options d-flex align-items-end justify-content-between py-2">
        <div className="filter-table-buttons d-flex gap-2">
          <Button
            type="button"
            className={activeButton === EFeedStatus.IN_REVIEW ? "active" : ""}
            onClick={() => {
              handleButtonClick(EFeedStatus.IN_REVIEW);
              setCurrentPage(1);
            }}>
            Waiting for Approval
          </Button>
          <Button
            type="button"
            className={activeButton.includes("ACTIVE") ? "active" : ""}
            onClick={() => {
              handleButtonClick(EFeedStatus.ACCEPTED);
              setCurrentPage(1);
            }}>
            Approved
          </Button>
          <Button
            type="button"
            className={activeButton === EFeedStatus.REJECTED ? "active" : ""}
            onClick={() => {
              handleButtonClick(EFeedStatus.REJECTED);
              setCurrentPage(1);
            }}>
            Rejected
          </Button>
          <Button
            type="button"
            className={activeButton === EFeedStatus.DELETED ? "active" : ""}
            onClick={() => {
              handleButtonClick(EFeedStatus.DELETED);
              setCurrentPage(1);
            }}>
            Deleted
          </Button>
          <Button
            type="button"
            className={activeButton === EFeedStatus.DRAFT ? "active" : ""}
            onClick={() => {
              handleButtonClick(EFeedStatus.DRAFT);
              setCurrentPage(1);
            }}>
            In Draft
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-inline">
            <Calendar
              className="calender"
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
        </div>
      </div>
      }
      <Table responsive className="listing-table  font-12 ">
        <thead>
          <tr>
            <th scope="col-2" style={{display:"flex",justifyContent:"center"}}>
              <Input
                type="checkbox"
                name="selectAll"
                onChange={() => {
                  setSelectAllList(!selectAllList);
                }}
                
              />
            </th>
            {/* <th>
              <div className="text-nowrap text-center">
                {userType === EFeedUsers.USER
                  ? "Channel Name"
                  : "Company Name"}
              </div>
            </th> */}
            <th>
              <div className="text-nowrap text-center">Campaign Name</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Campaign Type</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Delivery Status</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Start Date</div>
            </th>
          
                <th>
                  <div className="text-nowrap text-center">End Date</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Cost Per Unit</div>
                </th>
              
           

            {/* <th>
              <div className="text-nowrap text-center"></div>
            </th> */}
            {/* <th>
              <div className="text-nowrap text-center">Cost per Unit</div>
            </th>

            <th>
              <div className="text-nowrap text-center">Deliverable Objects</div>
            </th>

            {activeButton.includes("ACTIVE") && (
              <>
                <th>
                  <div className="text-nowrap text-center">Views/Open</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Clicks</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Interest Shown</div>
                </th>

                <th>
                  <div className="text-nowrap text-center">
                    Meeting Completed
                  </div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Acceptance</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Ticket</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Total Spend</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Approved on</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Approved By</div>
                </th>
              </>
            )}
            {(activeButton === EFeedStatus.REJECTED ||
              activeButton === EFeedStatus.DELETED) && (
              <>
                <th>
                  <div className="text-nowrap text-center">Rejected on</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Rejected By</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Remark</div>
                </th>
              </>
            )} */}
            {/* {activeButton !== EFeedStatus.IN_REVIEW && (
              <>
                <th>
                  <div className="text-nowrap text-center">Restart</div>
                </th>
              </>
            )} */}
          </tr>
        </thead>
        <tbody className="feeds-first-row">
          {companyList?.map((data) => {
            return (
              <tr
                className={
                  data.campaign_id === selectedCampaign ? "selected" : ""
                }>
                <td>
                  <div>
                    <Input
                      name=""
                      type="checkbox"
                      checked={
                        selectAllList
                          ? true
                          : data.campaign_id === selectedCampaign
                      }
                      onChange={() => {
                        setRemark("");
                        setLocalStorageItem("userId", data.user_id);
                        setLocalStorageItem("profileId", data.profile_id);
                        setLocalStorageItem("campaignType", data.campaign_type);
                        if (selectAllList) {
                          setSelectedCampaign("");
                        } else {
                          setSelectedCampaign((prevSelectedCampaign) =>
                            prevSelectedCampaign === data.campaign_id
                              ? ""
                              : data.campaign_id
                          );
                        }
                        setCampaignStatus(data.status);
                      }}
                    />
                  </div>
                </td>
                {/* <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {userType === EFeedUsers.USER
                      ? data.user_name + " " + data.last_name
                      : data.company_name}
                  </div>
                </td> */}
                <td
                  className="text-nowrap text-start">
                  <span className="text-primary-blue">{data.campaign_name}</span>
                </td>

                <td className="text-nowrap text-start">{data.campaign_type}</td>
                <td className="text-nowrap">
                  {convertText(data.status)}
                </td>
                <td className="text-nowrap">
                 {moment(data.start_date).format("DD - MM - YYYY")}
                </td>
                {/* {activeButton.includes("ACTIVE") && (
                  <>
                    <td className="text-nowrap">
                      {moment(data.start_date).format("DD - MM - YYYY")}
                    </td>
                    <td className="text-nowrap">
                      {moment(data.end_date).format("DD - MM - YYYY")}
                    </td>
                  </>
                )} */}
                <td className="text-nowrap capitalize text-start">
                {moment(data.end_date).format("DD - MM - YYYY")}
                </td>
                <td className="text-nowrap text-end">
                  {" "}
                  &#8377; <span className="ps-1">{data.meeting_cost?data.meeting_cost:0} </span>
                </td>
                {/* <td className="text-nowrap text-start">{data.delivery_mode}</td>
                {activeButton.includes("ACTIVE") && (
                  <>
                    <td className="text-nowrap text-start">{data.views} </td>
                    <td className="text-nowrap text-start">{data.clicks} </td>
                    <td className="text-nowrap text-start">
                      {data.interest_shown}
                    </td>
                    <td className="text-nowrap text-start">
                      {data.meeting_completed}
                    </td>
                    <td className="text-nowrap text-start">
                      {data.acceptence}
                    </td>
                    <td className="text-nowrap ">{data.ticket}</td>
                    <td className="text-nowrap text-start">
                      ₹ <span>{data.total_spend}</span>
                    </td>
                    <td className="text-nowrap">
                      {moment(data.approved_on).format("DD-MM-YYYY")}
                    </td>
                    <td className="text-nowrap">{data.approved_by} </td>
                  </>
                )}
                {(activeButton === EFeedStatus.REJECTED ||
                  activeButton === EFeedStatus.DELETED) && (
                  <>
                    <td className="text-nowrap">
                      {data.approved_on
                        ? moment(data.approved_on).format("DD-MM-YYYY")
                        : ""}
                    </td>
                    <td className="text-nowrap">{data.approved_by}</td>
                    <td className="text-nowrap text-center  ">
                    </td>
                  </>
                )} */}
                {/* {activeButton !== EFeedStatus.IN_REVIEW && (
                  <>
                    <td className="text-nowrap">Restart </td>
                  </>
                )} */}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="row align-items-center pt-25 pb-50 pagination-section py-3">
        <div className="col-10 text-end mt-1">
          <form className="d-flex justify-content-end align-item">
            <span className="text-primary-blue pe-1 font-12 d-flex align-items-center">
              Show
            </span>
            <div className="total-pages me-2 position-relative">
              <span className="d-flex align-items-center">{pageSize}</span>
              <DropdownButton onSelect={onPageCountChange} title={""}>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="25">25</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
                <Dropdown.Item eventKey="100">100</Dropdown.Item>
              </DropdownButton>
            </div>
          </form>
        </div>
        <div className="col-2">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={pageSize}
            totalItemsCount={totalPagesCount}
            pageRangeDisplayed={5}
            hideFirstLastPages={true}
            prevPageText={<img src={PaginationIcon} alt="Previous" />}
            nextPageText={<img src={PaginationIcon} alt="Next" />}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedsTable;
