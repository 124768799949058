import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PromiloLogo from "../assets/images/promilo-logo.png";
import CompanyIcon from "../assets/images/menu/company.png";
import OverviewIcon from "../assets/images/menu/overview.png";
import SalesIcon from "../assets/images/menu/sales.png";
import BillingIcon from "../assets/images/menu/billing.png";
import SettingsIcon from "../assets/images/menu/settings.png";
import SupportIcon from "../assets/images/menu/support.png";
import ReportIcon from "../assets/images/menu/reports.png";

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../core/services/StorageService";
import { Collapse } from "reactstrap";
// @ts-ignore: Unresolved module
import * as CryptoJS from "crypto-js";
import { activateSalesPerson } from "../services/SettingsApiService";
import { IAvailableServices } from "../interface/settings.interface";
import Announcements from "./Announcements";
import { getRequest } from "../core/services/HttpClientWrapper";
import { useContext } from "react";
import NotificationBadgeContext from "../contexts/NotificationBadgeContext";

type AccordionSection = "feeds" | "browser" | "rewards";

const Menu = () => {
  const {newEmail,setNewEmail,newAnnouncement,setNewAnnouncement,totalNotification,setTotalNotification} = useContext(NotificationBadgeContext)
  const navigate = useNavigate();
  const location = useLocation();
  const token = getLocalStorageItem("access_token");
  const userType = getLocalStorageItem("userData")?.userType || "";
  const franchiseRefCode = getLocalStorageItem("userProfile")?.franchise_ref_code || ""
  const activeItemRef = useRef<HTMLLIElement | null>(null);
  const [activeMenuItems, setActiveMenuItem] = useState<IAvailableServices[]>(
    []
  );
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);

  const [accordionState, setAccordionState] = useState<
    Record<AccordionSection, boolean>
  >({
    feeds: false,
    browser: false,
    rewards: false,
  });
  
  const getUnreadAnnoucementAndEmail = async ()=>{
    try {
      const res = await getRequest("/v1/inboxMessage/counts?fr_code=" + franchiseRefCode, 'ADMIN');
      setNewAnnouncement(res.data.response.new_announcements)
      setNewEmail(res.data.response.new_email)
      setTotalNotification(res.data.response.new_email+res.data.response.new_announcements)
    } catch (error) {
      throw error;
    }
  }
  useEffect(()=>{
    if(franchiseRefCode){
      getUnreadAnnoucementAndEmail()
    }
  },[])
  useEffect(() => {
    if (activeMenuItems.length === 0) {
      const services = getLocalStorageItem("availed_services") || "";
      if (services) {
        const bytes = CryptoJS.AES.decrypt(
          services,
          process.env.REACT_APP_CYPER
        );
        const data = bytes.toString(CryptoJS.enc.Utf8)
          ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
          : [];
        
        setActiveMenuItem(data ? data : []);
      }
    }
  }, [location.pathname]);

  // Function to determine if a menu item is active
  const isMenuItemActive = (path: string[]) => {
    return path.includes(location.pathname) ? "active" : "";
  };

  const handleItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    menuItem: string = ""
  ) => {
    setLocalStorageItem("current_menu_item", menuItem);
    const target = event.currentTarget;
    activeItemRef.current = target;
    event.stopPropagation();
    const path = target.getAttribute("data-path");
    if (path === "/announcement") {
      setShowAnnouncement(!showAnnouncement);
    } else if (path) {
      navigate(path);
    }
  };

  const toggleAccordion = (section: AccordionSection) => {
    setAccordionState({
      ...accordionState,
      [section]: !accordionState[section],
    });
  };

  return (
    <div>
      <div className={`sidenav-menu ${token ? "d-block" : "d-none"}`}>
        <div className="home px-4">
          <img
            src={PromiloLogo}
            loading="lazy"
            alt="Promilo Logo"
            className="logo"
          />
        </div>
        <nav>
          <ul>
            {((activeMenuItems &&
              activeMenuItems.find(
                (ele) =>
                  ele.service?.toString() === "1" && ele?.status === "ENABLED"
              )) ||
              userType === "FRANCHISE_ADMIN") && (
              <li
                className={`pointer ${isMenuItemActive(["/overview"])}`}
                data-path="/overview"
                onClick={handleItemClick}
              >
                <img src={OverviewIcon} loading="lazy" alt="overview" />
                Overview
              </li>
            )}
            {((activeMenuItems &&
              activeMenuItems.find(
                (ele) =>
                  ele.service?.toString() === "2" && ele?.status === "ENABLED"
              )) ||
              userType === "FRANCHISE_ADMIN") && (
              <li
                className={`pointer ${isMenuItemActive(["/onboarded-company"])}`}
                data-path="/onboarded-company"
                onClick={handleItemClick}
              >
                <img src={CompanyIcon} loading="lazy" alt="Onboarded Company" />
                Onboarded Company
              </li>
            )}
            {((activeMenuItems &&
              activeMenuItems.find(
                (ele) =>
                  ele.service?.toString() === "3" && ele?.status === "ENABLED"
              )) ||
              userType === "FRANCHISE_ADMIN") && (
              <li
                className={`pointer ${isMenuItemActive(
                  ["/sales-service-performance"]
                )}`}
                data-path="/sales-service-performance"
                onClick={handleItemClick}
              >
                <img src={SalesIcon} loading="lazy" alt="Sales Service" />
                Sales Service Performance
              </li>
            )}
            {((activeMenuItems &&
              activeMenuItems.find(
                (ele) =>
                  ele.service?.toString() === "4" && ele?.status === "ENABLED"
              )) ||
              userType === "FRANCHISE_ADMIN") && (
              <li
                className={`pointer ${isMenuItemActive(["/reports"])}`}
                data-path="/reports"
                onClick={handleItemClick}
              >
                <img src={ReportIcon} loading="lazy" alt="Reports" />
                Reports
              </li>
            )}
            {((activeMenuItems &&
              activeMenuItems.find(
                (ele) =>
                  ele.service?.toString() === "5" && ele?.status === "ENABLED"
              )) ||
              userType === "FRANCHISE_ADMIN") && (
              <li
                className={`pointer ${isMenuItemActive(["/billing"])}`}
                data-path="/billing"
                onClick={handleItemClick}
              >
                <img src={BillingIcon} loading="lazy" alt="Billing" />
                Billing & Payments
              </li>
            )}
            {userType === "FRANCHISE_ADMIN" && (
              <li
                className={`pointer ${isMenuItemActive(["/settings"])}`}
                data-path="/settings"
                onClick={handleItemClick}
              >
                <img src={SettingsIcon} loading="lazy" alt="Settings" />
                Settings
              </li>
            )}

            <li
              className={`position-relative pointer ${accordionState.feeds ? 'feeds-dropdown-active' : 'feeds-dropdown'}`}
              onClick={() => toggleAccordion("feeds")}
            >
              <div className={`pointer ${isMenuItemActive([
                "/create-new-ticket",
                "/my-tickets",
                "/inbox",
                "/announcement"
              ]) || showAnnouncement
              ? "active"
              : ""} accordion-title d-flex`}>
                <img src={SupportIcon} loading="lazy" alt="Support" />
                Support
                {totalNotification>0 && !accordionState.feeds && (
                  <span style={{marginLeft:"30px"}} >
                    <div style={{backgroundColor:"rgba(209, 26, 42, 1)",borderRadius:"50%",width:"17px",height:"17px",textAlign:"center",color:"white"}}>{totalNotification}</div>
                  </span>
                )}
              </div>
              
              <Collapse isOpen={accordionState.feeds}>
                <ul className="">
                  <li
                    className={`pointer ${isMenuItemActive(
                      ["/create-new-ticket"]
                    )}`}
                    data-path="/create-new-ticket"
                    onClick={handleItemClick}
                  >
                    Create New Ticket
                  </li>
                  <li
                    className={`pointer ${isMenuItemActive(["/my-tickets"])}`}
                    data-path="/my-tickets"
                    onClick={handleItemClick}
                  >
                    My Tickets
                  </li>
                  <li
                    className={`pointer ${isMenuItemActive(["/inbox"])} d-flex`}
                    data-path="/inbox"
                    onClick={handleItemClick}

                  >
                    Inbox
                    {newEmail>0 && (
                  <span style={{marginLeft:"142.5px"}} >
                    <div style={{backgroundColor:"rgba(209, 26, 42, 1)",borderRadius:"50%",width:"17px",height:"17px",textAlign:"center",color:"white"}}>{newEmail}</div>
                  </span>
                )}
                  </li>
                  <li
                    className={`pointer ${
                      isMenuItemActive(["/announcement"]) || showAnnouncement
                        ? "active"
                        : ""
                    } d-flex`}
                    data-path="/announcement"
                    onClick={handleItemClick}
                  >
                    Announcement
                    {newAnnouncement>0 && (
                  <span style={{marginLeft:"90px"}} >
                    <div style={{backgroundColor:"rgba(209, 26, 42, 1)",borderRadius:"50%",width:"17px",height:"17px",textAlign:"center",color:"white"}}>{newAnnouncement}</div>
                  </span>)}
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        </nav>
      </div>
      {
        showAnnouncement &&
          <Announcements
            showAnnouncement={showAnnouncement}
            setShowAnnouncement={setShowAnnouncement}
          />
      }
    </div>
  );
};

export default Menu;
