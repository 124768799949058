import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import DocumentIcon from "../assets/images/Icon-document-icon.png"
import { ellipseAppender, formatBytes } from "../core/services/utilityService"
import { getDocumentUrl } from "../services/FranchiseApiService";
interface IOpenDocumentModal {
  showDocumentModal: boolean;
  setShowDocumentModal: any;
  documents:any[];
}


const OpenDocumentModal: React.FC<IOpenDocumentModal> = ({
    showDocumentModal,
    setShowDocumentModal,
    documents,
}) => {
  const handleDownload = async (documentId:string) => {
    try {
      let data = await getDocumentUrl(documentId);
      let url = data.response;
      window.open(url, "_blank");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={showDocumentModal}
        className="open-document-modal"
        style={{ maxWidth: "950px" }}>
        <ModalHeader toggle={() => setShowDocumentModal(!showDocumentModal)}>
          <div>
            <span className="font-16 ms-2 fw-500">
              Documents
            </span>
          </div>
        </ModalHeader>
        <ModalBody style={{height:"500px"}}>

          <div
            className="choose-remark-statement px-3 py-2 mb-3">
            <div className="d-flex justify-content-start flex-wrap">
            {
                documents.map((document,index)=>(
                    <div>
                        <div className="document-card my-2 mx-2 pointer" onClick={() =>handleDownload(document.document_id)} >
                            <div className="document-imagebox">
                              <img src={DocumentIcon}></img>
                            </div>
                            <div className="document-name ">
                                <h6 className="mx-3">{ellipseAppender(document.document_name,20)}</h6>
                                <span className="mx-3">{formatBytes(document.document_size)}</span>
                              </div>  
                        </div>
                    </div>
                ))
            }
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end border-0">

        </ModalFooter>
      </Modal>

    </div>
  );
};

export default OpenDocumentModal;
