import { click } from "@testing-library/user-event/dist/click";
import Select from 'react-select';
import FilterIcon from "../assets/images/icons/filter.png";
import AsyncSelect from 'react-select/async'
import { getFranchiseCompanyList, getFranchiseDetails, getLocationList } from "../services/FranchiseApiService";
import { getLocalStorageItem, getUserData } from "../core/services/StorageService";
import { FC, useEffect, useRef, useState } from "react";
import { ICommonSearch, ISearch } from "../core/interfaces/SearchRequest.interface";
import { EOrder, EOrderSequencing, ESearchMatch } from "../core/enums/Search.enums";
import { IFilterValues, IFranchiseCompanyList, IFranchiseSalesPerformance } from "../interface/franchise.interface";
import { IDropdown } from "../interface/html-elements.interface";
import { constructSelectOptions } from "../core/services/utilityService";
import CloseMarkIcon from "../assets/images/circle-xmark.png";
interface Props {
    isBillingPage: boolean;
    setFilterValues: any
}
export const OnboardedCompanyFilter: FC<Props> = ({ isBillingPage = false, setFilterValues }) => {

    const userId = useRef<string>('')
    const userRefCode = useRef<string>('')
    const [salesPersons, setSalesPersons] = useState<any[]>([])
    const [locationList, setLocationList] = useState<any[]>([])
    const [companyList, setCompanyList] = useState<any[]>([])
    const [selectedSalesPersons, setSelectedSalesPersons] = useState<IDropdown[]>([])
    const [selectedLocation, setSelectedLocation] = useState<IDropdown[]>([])
    const [selectedCompany, setSelectedCompany] = useState<IDropdown[]>([])
    const [sales, setSales] = useState('')
    const [company, setCompany] = useState('')
    const [city, setCity] = useState("")
    useEffect(() => {
        onTriggerFilterChange()
    }, [selectedSalesPersons, selectedLocation, selectedCompany])

    useEffect(() => {
        getAllLocation()
        getAllCompanies()
        getAllSalesPerson()
    }, [])
    const getAllCompanies = async () => {
        const allCompanies = await getCompanyList("")
        setCompanyList(allCompanies)
    }
    const getAllSalesPerson = async () => {
        const allSales = await getSalesPersonList("")
        setSalesPersons(allSales)
    }
    const loadOptionsForSalesPerson = async (inputValue: string) => {
        let requestResults: IDropdown[] = []
        if (inputValue.length >= 0) {
            requestResults = await getSalesPersonList(inputValue)
        } else {
            requestResults = []
        }
        setSalesPersons(requestResults)
    }
    const loadOptionsForCompany = async (inputValue: string) => {
        let requestResults: IDropdown[] = []
        if (inputValue.length >= 0) {
            requestResults = await getCompanyList(inputValue)
        } else {
            requestResults = []
        }
        setCompanyList(requestResults)
    }

    const getCompanyList = async (inputValue: string): Promise<IDropdown[]> => {
        const userProfileData = getLocalStorageItem('userProfile')
        if (userProfileData.user_type === "FRANCHISE_ADMIN") {
            userId.current = userProfileData?.franchise_id
            userRefCode.current = ""
        } else {
            userRefCode.current = userProfileData?.referral_code
            userId.current = ""
        }
        let search: ISearch = {
            page: {
                no: 1,
                size: 20,
            },
            query: {
                filter: [
                ],
                search: {
                    fields: ['company_name'],
                    search_match: ESearchMatch.CONTAINS,
                    search: inputValue
                } as ICommonSearch
            }
        };

        if (userId.current) {
            search.query && search.query.filter && search.query.filter.push(
                {
                    fields: ["franchise_id"],
                    search: userId.current ? userId.current : '',
                    search_match: ESearchMatch.EXACT,

                });
        }
        let res = await getFranchiseCompanyList(search)
        if (res.response && res.response.content) {
            const dropdown = res.response.content.map((ele: IFranchiseCompanyList) => ({
                'value': ele.company_name,
                'label': ele.company_name
            }));
            return dropdown
        }

        return []

    }
    const getSalesPersonList = async (inputValue: string): Promise<IDropdown[]> => {
        
        const userProfileData = getLocalStorageItem('userProfile')
        if (userProfileData.user_type === "FRANCHISE_ADMIN") {
            userId.current = userProfileData?.franchise_id
            userRefCode.current = ""
        } else {
            userRefCode.current = userProfileData?.referral_code
            userId.current = ""
        }
        let search: ISearch = {
            page: {
                no: 1,
                size: 20,
            },
            query: {
                filter: [],
            }
        };

        if (inputValue && search.query) {
            search.query.search = {
                    fields: ['user_name'],
                    search_match: ESearchMatch.CONTAINS,
                    search: inputValue
                };
        }
        if (userId.current) {
            search.query && search.query.filter && search.query.filter.push(
                {
                    fields: ["franchise_id"],
                    search: userId.current ? userId.current : '',
                    search_match: ESearchMatch.EXACT,

                });
        }
        if (userProfileData.user_type === 'FRANCHISE_SALES') {
            search.query && search.query.filter && search.query.filter.push(
                {
                    fields: ["user_id"],
                    search: userProfileData.user_profile_id,
                    search_match: ESearchMatch.EXACT,

                });
        }
        let res = await getFranchiseDetails(search)
        if (res.response && res.response.content) {
            const dropdown = res.response.content.map((ele: IFranchiseSalesPerformance) => ({
                'value': ele.user_referral_code,
                'label': ele.user_name || ""
            }));
            return dropdown
        }
        return []

    }

    const onSelectSalesPerson = (e: any) => {
        if (!selectedSalesPersons.some(person => person.value === e.value)) {            
            setSelectedSalesPersons(prevSelected => [...prevSelected, e]);
            setSales('')
        }
    };

    const onUnSelectSalesPerson = (e: IDropdown) => {
        const updatedSelected = selectedSalesPersons.filter(person => person.value !== e.value);
        setSelectedSalesPersons(updatedSelected);
    };
    const onSelectCompany = (e: any) => {
        if (!selectedCompany.some(person => person.value === e.value)) {
            setSelectedCompany(prevSelected => [...prevSelected, e]);
        };
    }
    const onUnSelectCompany = (e: IDropdown) => {
        const updatedSelected = selectedCompany.filter(person => person.value !== e.value);
        setSelectedCompany(updatedSelected);
    };

    const onSelectLocation = (e: any) => {
        if (!selectedLocation.some(person => person.value === e.value)) {
            setSelectedLocation(prevSelected => [...prevSelected, e]);
        }
    }
    const onUnSelectLocation = (e: IDropdown) => {
        const updatedSelected = selectedLocation.filter(person => person.value !== e.value);
        setSelectedLocation(updatedSelected);
    };

    const getAllLocation = async () => {
        try {
            let data = await getLocationList();
            let locationList = data.response.content;
            let locationTypeOptions = constructSelectOptions(
                locationList,
                "location_name",
                "location_name"
            );
            CityOptionsdata(locationTypeOptions);
        } catch (error) { }
    };
    const CityOptionsdata = (data: any[]) => {
        let groupedOptions: any[] = [];
        data.forEach((datas) => {
            if (datas.label.includes("----")) {
                groupedOptions.push({
                    label: datas.label.replace(/-{4}/g, "").trim().toLocaleUpperCase(),
                    options: [],
                });
            } else {
                groupedOptions[groupedOptions.length - 1].options.push({
                    label: datas.label,
                    value: datas.value,
                });
            }
        });
        groupedOptions.forEach((category) => {
            category.options.sort((a: any, b: any) => a.label.localeCompare(b.label));
        });
        setLocationList(groupedOptions);
    };


    const onTriggerFilterChange = () => {
        let locations: ICommonSearch = {} as ICommonSearch;
        let salesPerson: ICommonSearch = {} as ICommonSearch;
        let companies: ICommonSearch = {} as ICommonSearch;

        if (selectedLocation && selectedLocation.length > 0) {
            locations = {
                fields: ['location'],
                search: selectedLocation.map(ele => ele.value).join(','),
                search_match: ESearchMatch.IN
            }
        }
        if (selectedSalesPersons && selectedSalesPersons.length > 0) {
            salesPerson = {
                fields: ['franchise_seller_ref_code'],
                search: selectedSalesPersons.map(ele => ele.value).join(','),
                search_match: ESearchMatch.IN
            }
        }
        if (selectedCompany && selectedCompany.length > 0) {
            salesPerson = {
                fields: ['company_name'],
                search: selectedCompany.map(ele => ele.value).join(','),
                search_match: ESearchMatch.IN
            }
        }
        const filterValues: IFilterValues = { salesPerson: salesPerson, locations: locations, companies: companies }
        setFilterValues(filterValues)
    }

    return (
        <div className="px-4 py-3 filter-options mb-3">
            <div className="border-bottom-grey fw-500 pb-2 mb-2">
                <img src={FilterIcon} alt="Filter" className="" loading="lazy" />
                Filter
            </div>
            <div className="border-bottom-grey mb-2 pb-2">
                <div className="row ">
                    <div className="col-12 col-sm-6" style={{marginRight:"-17px"}}>
                        <Select
                            onChange={onSelectSalesPerson}
                            onInputChange={(e) => {

                                loadOptionsForSalesPerson(e)

                            }}
                            closeMenuOnSelect={true}
                            options={salesPersons}
                            placeholder="Sales Person"
                            value={sales}
                        />

                    </div>
                    {
                        isBillingPage &&
                        <div className="col-12 col-sm-6" style={{marginRight:"-17px"}}>
                            <Select
                                onChange={onSelectCompany}
                                onInputChange={(e) => {
                                    loadOptionsForCompany(e)
                                }}
                                closeMenuOnSelect={true}
                                options={companyList}
                                placeholder="Companies"
                                value={company}
                            />

                        </div>
                    }
                    <div className="col-12 col-sm-6" style={{marginRight:"-17px"}}>
                        <Select
                            onChange={onSelectLocation}
                            closeMenuOnSelect={true}
                            options={locationList}
                            placeholder="Location"
                            value={city}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap">
                {
                    selectedSalesPersons.map(ele => (
                        <li key={ele.value} style={{ width: "max-content", height: "38px", padding: "10px", borderRadius: "4px", border: "1px", backgroundColor: "rgba(0, 168, 255, 0.15)", listStyle: "none", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "6.2px", marginBottom: "10px" }}>
                            <div className="" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                {ele.label}

                            </div>
                            <img src={CloseMarkIcon} onClick={() => onUnSelectSalesPerson(ele)} />
                        </li>
                    ))
                }
                {
                    selectedCompany.map(ele => (
                        <li key={ele.value} style={{ width: "243px", height: "38px", padding: "10px", borderRadius: "4px", border: "1px", backgroundColor: "rgba(0, 168, 255, 0.15)", listStyle: "none", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "6.2px", marginBottom: "10px" }}>
                            <div className="" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                {ele.label}

                            </div>
                            <img src={CloseMarkIcon} onClick={() => onUnSelectCompany(ele)} />
                        </li>
                    ))
                }
                {
                    selectedLocation.map(ele => (
                        <li key={ele.value} style={{ width: "243px", height: "38px", padding: "10px", borderRadius: "4px", border: "1px", backgroundColor: "rgba(0, 168, 255, 0.15)", listStyle: "none", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "6.2px", marginBottom: "10px" }}>
                            <div className="" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                {ele.label}


                            </div>
                            <img src={CloseMarkIcon} onClick={() => onUnSelectLocation(ele)} />
                        </li>
                    ))
                }
            </div>


        </div>
    )
}