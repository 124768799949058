import React, { useEffect, useState, useRef, LegacyRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { doLogout } from "../services/AuthApiService";
import { clearLocalStorage, getAccessToken, getUserData } from "../core/services/StorageService";
import SearchIcon from "../assets/images/icons/search-icon.png";
import BackIcon from "../assets/images/Vector.png"
import LogoutIcon from "../assets/images/door-open-icon-logout-door.png"
import CLoseIcone from "../assets/images/circle-xmark.png";
import DefaultUserImg from "../assets/images/default-user.png";
import NotificationIcon from "../assets/images/icons/notificationIcon.svg"
import NotificationModal from "./NotificationModal";
import { ICommonSearch } from "../core/interfaces/SearchRequest.interface";
import { getNotification , NotificationCount } from "../services/FranchiseApiService"
interface ITopMenuBarProps {
  setSearchQuery?: React.Dispatch<string>;
  hideSearchBar?: boolean
  showBackButton?: boolean
  onSearchIconClick?: () => void;
  searchQuery?: any;
  pageTitle?: string
  clearField?: () => void;
}

interface Notification {
  notificationId: string;
  title: string;
  message: string;
  notificationType: string;
  messageType: string;
  status: string;
  receiver: string;
  gateway: string;
  additionalDetails: {
    ACTION_TYPE: string;
  };
  creationDate: string;
}

interface GroupedNotifications {
  today: Notification[];
  tomorrow: Notification[];
  others: {
    [date: string]: Notification[];
  };
}

const TopMenuBar: React.FC<ITopMenuBarProps> = ({
  setSearchQuery,
  hideSearchBar = false,
  showBackButton = false,
  onSearchIconClick,
  searchQuery,
  pageTitle = "",
  clearField,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const token = getAccessToken();
  const inputRef: LegacyRef<HTMLInputElement> | undefined = useRef<HTMLInputElement>(null);
  const userData: any = getUserData();
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [searchQueryPast, setSearchQueryPast] = useState<string>("");
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [groupedNotifications, setGroupedNotifications] = useState<GroupedNotifications>({ today: [], tomorrow: [], others: {} });


  const onNavigate = (path: string) => {
    navigate(path, { replace: true });
    window.scrollTo(0, 0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [activeMenuItem, setActiveMenuItem] = useState<string>("");

  const onLogoutClick = async () => {
    await doLogout();
    clearLocalStorage();
    onNavigate("/");
    window.location.reload();
    setActiveMenuItem("/");
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter" && onSearchIconClick && searchQuery?.length > 3) {
      onSearchIconClick();
      setShowClearButton(true);
    }
  };

  const clearSearchField = async () => {
    if (clearField) {
      clearField();
    }
    inputRef?.current?.focus();
  };

  const OnClickSearchIcone = () => {
    if (onSearchIconClick && searchQuery?.length > 3) {
      onSearchIconClick();
      setShowClearButton(true);
    }
  };

  const groupNotificationsByDate = (notifications: Notification[]): GroupedNotifications => {
    const grouped: GroupedNotifications = {
      today: [],
      tomorrow: [],
      others: {},
    };
  
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
  
    notifications.forEach((notification) => {
      const creationDate = new Date(notification.creationDate);
      const creationDay = creationDate.toDateString();
  
      if (creationDate.toDateString() === today.toDateString()) {
        grouped.today.push(notification);
      } else if (creationDate.toDateString() === tomorrow.toDateString()) {
        grouped.tomorrow.push(notification);
      } else {
        if (!grouped.others[creationDay]) {
          grouped.others[creationDay] = [];
        }
        grouped.others[creationDay].push(notification);
      }
    });
  
    grouped.today.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    grouped.tomorrow.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    Object.keys(grouped.others).forEach((date) => {
      grouped.others[date].sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    });
  
    const sortedOthers: { [date: string]: Notification[] } = {};
    Object.keys(grouped.others)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .forEach((key) => {
        sortedOthers[key] = grouped.others[key];
      });
  
    return {
      ...grouped,
      others: sortedOthers,
    };
  };



  let  page= {
    no: 1,
    size: 20
  }
  
  const fetchNotificationData = async (page:{no:number , size:number}) => {
    let res = await getNotification(page, "INAPP");
    console.log(res)
    const grouped = groupNotificationsByDate(res.response.content);
    getNotificationCount()
    setGroupedNotifications(grouped);
    return res.response.content
  }
  const getNotificationCount = async () =>{
   let payload={NotificationStatus:"NEW", MessageType:"INAPP"}
    let res = await NotificationCount(payload)
    setNotificationCount(res.response.count)
  return res.response.count
  }



  useEffect(() => {
   getNotificationCount()
    fetchNotificationData(page);
  }, [])



  useEffect(() => {
    if (searchQueryPast !== searchQuery) {
      setShowClearButton(false);
    }
    if ((searchQueryPast?.length > 0) && ((searchQuery ?? '').length === 0)) {
      if (onSearchIconClick) {
        onSearchIconClick();
        setShowClearButton(false);
      }
    }


    setSearchQueryPast(searchQuery);
  }, [searchQuery, searchQueryPast, onSearchIconClick]);

  if (!token) {
    return null;
  }


  return (
    <div className="py-3 top-nav-bar">
      <div className="align-items-center d-flex justify-center align-center">
        {showBackButton ? (
          <img src={BackIcon}
            style={{
              height: "18px",
              width: "8px",
              marginTop: "10px",
              marginLeft: "17px",
              cursor: "pointer",
            }}
            alt="backIcon"
            onClick={() => navigate(-1)}
          />
        ) : ''}
        <div className="w-100 me-4 d-flex justify-center align-center">

          {hideSearchBar ?
            (
              <span style={{ textAlign: "center", fontWeight: 500 }} className="px-4 fw-500 pt-2">
                {pageTitle}
              </span>) : (
              <div className="position-relative w-100">
                <div className="d-flex">
                  <input
                    type="text"
                    placeholder="Search by keywords"
                    className="form-control search-field"
                    onChange={(e) => setSearchQuery?.(e.target.value)}
                    style={showBackButton ? { marginLeft: "20px" } : {}}
                    onKeyDown={(e) => handleKeyPress(e)}
                    value={searchQuery}
                    ref={inputRef}
                  />
                </div>

                {showClearButton ? (
                  <div>
                    <img
                      src={CLoseIcone}
                      alt="clearField"
                      loading="lazy"
                      className={searchQuery?.length > 0 ? "search-icon-active" : "search-icon-inactive"}
                      onClick={clearSearchField}
                    />
                  </div>
                ) : (
                  <img
                    src={SearchIcon}
                    alt="Search"
                    loading="lazy"
                    className={searchQuery?.length > 0 ? "search-icon-active" : "search-icon-inactive"}
                    height={searchQuery?.length > 0 ? "24px" : "16px"}
                    onClick={OnClickSearchIcone}
                  />
                )}

              </div>
            )}
        </div>
        <div className=" flex justify-center align-center flex-nowrap"
          onClick={() => setShowNotification(!showNotification)}>
          <div className="relative pb-2 px-4">
            <div className="pt-2 pb-1 flex justify-start align-start">
              <div className="notification-count ">{notificationCount}</div>
            </div>
            <img
              src={NotificationIcon}
              alt="NotificationIcon"
              width={"24px"}
              height={"24px"}
              className="icon-hover"
            />
          </div>
        </div>
        <div className="text-end">
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle caret color="" className="w-100">
              <img src={userData?.profilePic ? userData.profilePic : DefaultUserImg} alt="User" className="user-img me-2" />{" "}
              {userData?.firstName} {userData?.lastName}
            </DropdownToggle>
            <DropdownMenu>
              <div className="pointer" onClick={() => onNavigate("/myprofile")}>
                My Profile
              </div>
              <div
                className="pointer"
                onClick={() => onNavigate("/change-password")}
              >
                Change Password
              </div>
              <hr style={{ margin: "4px" }} />
              <div className="pointer d-flex " onClick={onLogoutClick}>
                <img style={{ margin: "0 8px 0 12px" }} src={LogoutIcon}></img>Log Out
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <NotificationModal
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        groupedNotifications={groupedNotifications}
        fetchNotificationData={fetchNotificationData}
      />
    </div>

  );
};

export default TopMenuBar;
