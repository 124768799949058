import React, { FC, useEffect, useState } from "react";
import DonutChart from "../DonutChart";
import Calendar from "../../common-components/Calendar";
import { IDateRange } from "../../interface/html-elements.interface";
import { getTotalProfit, getTotalRevenue } from "../../services/OverviewApiService";
import { convertToDDMMYYYYSeperatedByHypen } from "../../core/services/utilityService";
import { IGraphData } from "../../interface/overview.interface";

interface TitleProps {
  globalDateRange: IDateRange
}

const TotalProfitGraph: FC<TitleProps> = ({globalDateRange}) => {
  const [graphData, setGraphData] = useState<IGraphData>({} as IGraphData);
  const [noDataPresent, setNoDataPresent] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: "",
    endDate: "",
  });


  useEffect(() => {
    if(dateRange && dateRange.startDate && dateRange.endDate){
      getAllTotalProfit()
    }
  }, [dateRange]);

  useEffect(() => {
    if(globalDateRange && globalDateRange.startDate && globalDateRange.endDate){
      setDateRange(globalDateRange)
    }
  }, [globalDateRange]);

  const getAllTotalProfit = async () :Promise<void>=>{
    const param = {
      fromDate: convertToDDMMYYYYSeperatedByHypen(dateRange.startDate),
      toDate: convertToDDMMYYYYSeperatedByHypen(dateRange.endDate)
    }
    const res = await getTotalProfit(param);
    if(res && res.response && res.response.graph_response_object){;
      const dataSet = res.response.graph_response_object.map(ele => ele.param_count);
      const legendSet = res.response.graph_response_object.map(ele => ele.param_name);
      setNoDataPresent(dataSet.length === 0 ? true:false)
      setGraphData({
        totalCount: res.response.total_count,
        data: dataSet,
        legends: legendSet
      })
    }
  }
  return (
    <>
      <div className="row" style={{height: '26rem'}}>
        <div className="col-12">
          <div className="row">
          <h1 className="col-12 col-md-12 col-lg-4 col-xl-6 m-auto text-start">Total Profit</h1>
          <div className="col-12 col-md-12 col-lg-8 col-xl-6 ps-lg-4">
              <Calendar
                className="calender"
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          {noDataPresent ? <>
          <span className=" font-16">No data for selected date range</span>
          </> : 
          <DonutChart data={graphData} label={'Profit'}/>
          }
        </div>
      </div>
    </>
  );
};

export default TotalProfitGraph;
