import { FC, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Input } from "reactstrap";
import Switch from "../common-components/Switch";
import TopMenuBar from "../common-components/TopMenuBar";
import { IServiceList, IUserServiceList } from "../interface/settings.interface";
import { activateSalesPerson, getAllUserWithService, getServiceList, updatePermission } from "../services/SettingsApiService";
import { EUserStatus } from "../enums/user.enum";

interface TitleProps {
  title: string;
}

const Settings: FC<TitleProps> = ({ title }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [users, setUsers] = useState<IUserServiceList[]>([])
  const [filteredUsers, setFilteredUsers] = useState<IUserServiceList[]>([])
  const [services, setServices] = useState<IServiceList[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  useEffect(() => {
    getAllServices()
    getAllUsersFranchiser()
  }, [])

  useEffect(() => {
    filterUserByKeyword()
  }, [searchQuery, users])

  useEffect(() => {
    const allRowsSelected = users.length > 0 && selectedRows.size === users.length;
    setSelectAll(allRowsSelected);
  }, [selectedRows, users]);

  const filterUserByKeyword = (): void => {
    const filteredService: IUserServiceList[] = users.filter(ele => ele.user_name.toLocaleLowerCase().startsWith(searchQuery.toLocaleLowerCase()) ||
      ele.referral_code.toLocaleLowerCase().startsWith(searchQuery.toLocaleLowerCase()));
    setFilteredUsers(filteredService)
  }

  const getUserServiceEnabled = (serviceId: string, userServices: IServiceList[]) => {
    const myService = userServices.find(ele => ele.franchise_service_id === serviceId)
    return myService?.status === 'ENABLED' ? true : false
  }


  const getAllUsersFranchiser = async (): Promise<void> => {
    try {
      const res = await getAllUserWithService()
      if (res.response) {
        setUsers(res.response)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAllServices = async (): Promise<void> => {
    try {
      const res = await getServiceList()
      if (res.response) {
        setServices(res.response)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const setSwitchValueChange = async (e: boolean, userId: string, serviceId: string): Promise<void> => {
    const payload = {
      user_id: userId,
      service_id: serviceId,
      status: e ? 'ENABLED' : 'DISABLED'
    }
    const res = await updatePermission(payload);
    getAllUsersFranchiser()
  }

  const onChangeUserStatus = async (e: boolean, userId: string): Promise<void> => {
    const res = await activateSalesPerson(userId, e ? 'unblock' : 'block');
    getAllUsersFranchiser()
  }
  const toggleRowSelection = (index: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(index)) {
      updatedSelectedRows.delete(index);
    } else {
      updatedSelectedRows.add(index);
    }
    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows(new Set());
    } else {
      const allIndexes = Array.from({ length: filteredUsers.length }, (_, i) => i);
      setSelectedRows(new Set(allIndexes));
    }
    setSelectAll(!selectAll);
  };
  return (
    <>
      <div className="container-fluid settings-page">
        <TopMenuBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="mb-3 pt-2">
          <Table responsive className="listing-table font-12">
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    name="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>
                  <div className="text-nowrap text-center">
                    Sales Person Name
                  </div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Referral Code</div>
                </th>
                {
                  services.map((service, i) => {
                    return (
                      <th key={'service_' + i}>
                        <div className="text-nowrap text-center">{service.service_name}</div>
                      </th>
                    )
                  })
                }
                <th>
                  <div className="text-nowrap text-center">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody className="first-row">
              {
                filteredUsers && filteredUsers.map((user, i) => {
                  return (
                    <tr key={'user_' + i}>
                      <td>
                        <div className="text-start">
                          <Input
                            name={`selectRow-${i}`}
                            type="checkbox"
                            checked={selectedRows.has(i)}
                            onChange={() => toggleRowSelection(i)}
                          />
                        </div>
                      </td>
                      <td>{user.user_name}</td>
                      <td>{user.referral_code}</td>
                      {
                        services.map((service, index) => {
                          return (
                            <td key={'user_' + i + 'service_' + index}>
                              <Switch
                                id={'switch_user_' + i + 'service_' + index}
                                name="settings"
                                className="switch-blue"
                                checked={getUserServiceEnabled(service.franchise_service_id, user.service_list || [])}
                                setSwitchValueChange={(e: any) => setSwitchValueChange(e, user.user_id, service.franchise_service_id)}
                              />
                            </td>
                          )
                        })
                      }
                      <td className={(user.status === EUserStatus.ACTIVE || user.status === EUserStatus.INACTIVE || user.status === EUserStatus.BLOCKED) ? 'pointer' : 'disabled'}>
                        <Switch
                          id={'status' + i}
                          name="user"
                          className={user.status === EUserStatus.ACTIVE ? 'switch-green' : 'switch-red'}
                          checked={user.status === EUserStatus.ACTIVE}
                          setSwitchValueChange={(e: any) => onChangeUserStatus(e, user.user_id)}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Settings;
