import React, { FC, useEffect, useState } from "react";
import { IDateRange } from "../interface/html-elements.interface";
import Calendar from "../common-components/Calendar";
import TopMenuBar from "../common-components/TopMenuBar";
import InboxSideMenu from "./InboxSideMenu";
import InboxContent from "./InboxContent";
import {
  EOrder,
  EOrderSequencing,
  ESearchMatch,
} from "../core/enums/Search.enums";
import {
  ICommonSearch,
  ISearch,
} from "../core/interfaces/SearchRequest.interface";
import { getAllInboxes } from "../services/SupportApiService";
import { IInbox } from "../interface/inbox.interface";
import { getAndStoreFranchiseDeatils } from "../services/AuthApiService";
import { getLocalStorageItem } from "../core/services/StorageService";
import { useContext } from "react";
import NotificationBadgeContext from "../contexts/NotificationBadgeContext";
import { getRequest } from "../core/services/HttpClientWrapper";
interface TitleProps {
  title: string;
}

const Inbox: FC<TitleProps> = ({ title }) => {
  const {setNewEmail,setNewAnnouncement,setTotalNotification} = useContext(NotificationBadgeContext)
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [inboxList, setInboxList] = useState<IInbox[]>([]);
  const [selectedInbox, setSelectedInbox] = useState<IInbox>({} as IInbox);
  const franchiseRefCode = getLocalStorageItem("userProfile")?.franchise_ref_code || ""
  const [firstLoad,setFirstLoad] = useState(true)

  const getUnreadAnnoucementAndEmail = async ()=>{
    try {
      const res = await getRequest("/v1/inboxMessage/counts?fr_code=" + franchiseRefCode, 'ADMIN');
      setNewAnnouncement(res.data.response.new_announcements)
      setNewEmail(res.data.response.new_email)
      setTotalNotification(res.data.response.new_email+res.data.response.new_announcements)
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    getInboxList();
  }, [dateRange, currentPage, pageSize]);
  useEffect(()=>{
    if(inboxList.length === 0){
    setSelectedInbox({} as IInbox);
  }},[inboxList])
  const initializeSearch = (franchiseRefCode: string) => {
    let search: ISearch = {
      page: {
        no: 1,
        size: 20*currentPage,
      },
      query: {
        filter: [
          {
            fields: ["franchise_referral_code"],
            search: franchiseRefCode ? franchiseRefCode : "",
            search_match: ESearchMatch.EXACT,
          },
        ],
        search: {} as ICommonSearch,
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };
    if (dateRange.startDate && search && search.query && search.query.filter) {
      search.query.filter.push({
        fields: ["created_on"],
        between: [dateRange.startDate, dateRange.endDate],
        search_match: ESearchMatch.EXACT,
      });
    }
    if (searchQuery && search && search.query && search.query.search) {
      search.query.search = {
        fields: ["description", "subject", "email_id"],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    return search;
  };

  const getInboxList = async () => {
    try {
      await getAndStoreFranchiseDeatils();
      const franchiseRefCode =
        getLocalStorageItem("userProfile")?.franchise_ref_code;
      const searchRequest = initializeSearch(franchiseRefCode);
      const res = await getAllInboxes(searchRequest);
      if (res.response) {
        setTotalPagesCount(res.response.total_elements);
        setInboxList(res.response.content);
        if(firstLoad===true){
        setSelectedInbox(res.response.content[0]);
        setFirstLoad(false)
      }
        
      }
    } catch (error) {}
  };
  const markAsRead = (id: any) => {
    setInboxList((prevState) => {
      return prevState.map((obj) => {
        if (obj.id === id) {
          return { ...obj, read: true };
        }
        return obj;
      });
    });
  };
  const handleSearchIconClick = async () => {
    let searchRequest = initializeSearch(franchiseRefCode);

    if (searchQuery && searchRequest && searchRequest.query && searchRequest.query.search) {
      searchRequest.query.search = {
        fields:
            ["description", "subject", "email_id"],
        search: searchQuery,
      };
      // setCurrentPage(1);
    }
    try {
      let data: any;
      data = await getAllInboxes(searchRequest);
      setInboxList(data.response.content);
    } catch (error) {
      console.error("Error fetching inbox list:", error);
    } finally {
      setSearchQuery("");
    }
  };
  return (
    <>
      <div className="container-fluid service-request-page">
      <TopMenuBar setSearchQuery={setSearchQuery}  onSearchIconClick={handleSearchIconClick} searchQuery={searchQuery} hideSearchBar={true}/>
        <div className="row align-items-center">
          <div className="col-5">
            <h1 className="font-20 fw-500 py-1 m-0">{title}</h1>
          </div>
          <div className="col-7 text-end">
            <div className="d-flex align-items-center justify-content-end">
              {/* <DownloadButton
                buttonText={buttonText}
                buttonIcon={buttonIcon}
                className="download-popup"
              /> */}
              <div className="ps-4">
                <Calendar
                  className="calender"
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="w-100 d-flex">
          <InboxSideMenu
            inboxList={inboxList}
            markAsRead={(e) => {
              markAsRead(e);
              getUnreadAnnoucementAndEmail();
         
            }}
            setSelectedInbox={(e) => {
              setSelectedInbox(e);
            }}
            setPage={(e:any)=>setCurrentPage(e)}
          />
          <InboxContent selectedInbox={selectedInbox} />
        </div>
      </div>
    </>
  );
};

export default Inbox;
