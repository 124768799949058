import React, { FC, useEffect, useState } from "react";
import TopMenuBar from "../common-components/TopMenuBar";
import Calendar from "../common-components/Calendar";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ITicketForm } from "../interface/ticket.interface";
import { addNewTickets } from "../services/SupportApiService";
import { useLocation } from "react-router-dom";

interface TitleProps {
  title: string;
}

const CreateNewTicket: FC<TitleProps> = ({ title }) => {
  const {
    reset,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ITicketForm>();
  const documents = watch("documents", []);
  const routeState = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [description, setDescription] = useState("")
  const onSubmit: SubmitHandler<ITicketForm> = async (data): Promise<void> => {
    const formData = new FormData();
    const request = data;


    Array.from(data.documents).forEach((file) => {
      formData.append("attchements", file);
    });

    const requestFile = new File([JSON.stringify(request)], "", {
      type: "application/json",
    });
    formData.append("request", requestFile);
    const res = await addNewTickets(formData);
    if (res.response) {
      resetForm();
    }
  };

  const resetForm = () => {
    reset({
      business_mail: "",
      description: "",
      documents: [],
    });
  };

  const handleRemoveFile = (index: number) => {
    const currentFiles = watch("documents");
    currentFiles.splice(index, 1);
    reset({
      ...watch(),
      documents: currentFiles,
    });
  };

  useEffect(() => { console.log("documents :", documents) }, [documents])
  return (
    <>
      <form className="container-fluid service-request-page">
        <TopMenuBar
          hideSearchBar={true}
          showBackButton={routeState.state && routeState.state.goBack ?true:false}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery} />
        <h1 className="font-20 fw-semibold pt-3">{title}</h1>
        <hr />
        <p className="font-14 fw-medium pb-1 m-0">
          Please explain the request in as much details as possible<span style={{ color: "red" }}>*</span>
        </p>
        <p className="font-12 text-grey">
          Tell us more about what happened, including the steps we can take to
          reproduce the problem.
        </p>
        <div className="pb-3">
          <textarea
            id="request"
            placeholder="Type here ..."
            className="service-request-text-area w-100 p-3"
            {...register("description", {
              required: true,
              maxLength: 2500,
              minLength: 3
            })}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          ></textarea>
          {description.length < 3 && description.length > 0 ?
            <span style={{ color: "red", fontSize: "small" }}>Minimum 3 characters required</span> :
            ""
          }
          {errors.description?.type === "required" && description.length === 0 ? (
            <p role="alert" className="error-massage">
              Description is required.
            </p>
          ) : (
            errors.description?.type === "maxLength" && (
              <p role="alert" className="error-massage">
                Description Should not be greater than 2500 character
              </p>
            )
          )}
        </div>
        <div className="py-3">
          <h2 className="font-14 mb-0 pb-1">
            Business Email <span className="text-grey">(optional)</span>
          </h2>
          <p className="font-12 text-grey">
            Please provide us your business emails.
          </p>
          <input
            type="text"
            placeholder="Type here ..."
            id="business-email"
            className="business-email-input w-100"
            {...register("business_mail", {
              required: false,
              maxLength: 150,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.business_mail?.type === "required" ? (
            <p role="alert" className="error-massage">
              Email Id is required
            </p>
          ) : errors.business_mail?.type === "maxLength" ? (
            <p role="alert" className="error-massage">
              Email Id Should not be greater than 150 character
            </p>
          ) : (
            errors.business_mail?.type === "pattern" && (
              <p role="alert" className="error-massage">
                Email Id is Invalid
              </p>
            )
          )}
        </div>

        <div className="newTicket-upload d-sm-flex">
          <label
              htmlFor="upload"
              className="upload-label w-100 text-white font-14 d-flex align-items-center justify-content-center pointer"
            >
              Upload
            </label>
            <input
              type="file"
              id="upload"
              className="d-none"
              multiple
              {...register("documents", {
                required: false,
              })}
              onChange={(e) => {
                if (e.target.files) {
                  const files = Array.from(e.target.files);
                  const existingFiles = watch("documents");
                  reset({
                    ...watch(),
                    documents: [...existingFiles, ...files],
                  });
                }
              }}
            />


                      <div className="w-100 newTicket-upload-text">
              <span className="font-14  mb-0 pb-1">
                Please add a Screenshot, Video or File{" "}
                <span className="text-grey">(optional)</span>
              </span>
              <span className="font-12 text-grey">
                Attach a screenshot, video or file to help us understand the
                request.
              </span>
            </div>

        </div>
        <div className="">
          {watch("documents") && watch("documents").length > 0 && (
            <div>
              <div className="uploaded-files-container pt-2">
                {watch("documents").map((file: File, index: number) => (
                  <div key={index}
                    className="uploaded-file" >
                    {file.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                      className="delete-button">&times;</button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="row pb-5">
          <div className="col text-end">
            <button
              type="button"
              className="default-button cancel-btn w-100  mb-1 mb-sm-0 me-sm-3"
              onClick={() => resetForm()}
            >
              Cancel
            </button>
            <button
              className="default-button submit-btn w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Send Request
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateNewTicket;
