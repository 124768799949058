import { FC, useEffect, useRef, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-js-pagination";
import { Button, Input, Table } from "reactstrap";
import DownloadIcon from "../../assets/images/icons/download.png";
import PaginationIcon from "../../assets/images/icons/pagination-left.png";
import { IDateRange } from "../../interface/html-elements.interface";
import {
  IFilterValues,
  IFranchisePaymentDetails,
  IFranchiseRevenueList,
  ISelectedFranchise,
} from "../../interface/franchise.interface";
import {
  EOrder,
  EOrderSequencing,
  ESearchMatch,
} from "../../core/enums/Search.enums";
import { getAndStoreFranchiseDeatils } from "../../services/AuthApiService";
import { getLocalStorageItem } from "../../core/services/StorageService";
import {
  getFranchisePaymentList,
  getFranchisePaymentListExcel,
  getFranchiseRevenueList,
  getFranchiseRevenueListExcel,
} from "../../services/FranchiseApiService";
import TopMenuBar from "../../common-components/TopMenuBar";
import Calendar from "../../common-components/Calendar";
import { convertToMonthAndYear } from "../../core/services/utilityService";
import BillingTable from "./BillingTable";
import PaymentTable from "./PaymentTable";
import { OnboardedCompanyFilter } from "../OnboardedCompanyFilter";
import fmt from "indian-number-format"
import { getRequest } from "../../core/services/HttpClientWrapper";
interface TitleProps {
  title: string;
}
function convertText(inputText: string) {
  const words = inputText.split("_");
  const convertedText = words.map((word) => {
    return word.charAt(0).toUpperCase() + word?.slice(1)?.toLowerCase();
  });
  return convertedText.join(" ");
}

const EAllStatus = {
  BILLING: "BILLING",
  PAYMENTS: "PAYMENTS",
};

const BillingAndPayment: FC<TitleProps> = ({ title }) => {
  const buttonText = "Download Report";
  const buttonIcon = DownloadIcon;

  const userProfile = getLocalStorageItem("userProfile");
  const franchiseId = userProfile?.franchise_id || ""
  const userType = userProfile?.user_type || ""
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: "",
    endDate: "",
  });
  const [selectedFranchise, setSelectedFranchise] = useState<
    ISelectedFranchise[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [billingList, setBillingList] = useState<IFranchiseRevenueList[]>([]);
  const [paymentList, setPaymentList] = useState<IFranchisePaymentDetails[]>(
    []
  );
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    {} as IFilterValues
  );
  const userId = useRef<string>("");
  const userRefCode = useRef<string>("");

  const [activeStatus, setActiveStatus] = useState<string>(EAllStatus.BILLING);
  const [insightRevenue, setInsightRevenue] = useState("0");
  const [insightProfit, setInsightProfit] = useState("0");
  const [insightPending, setInsightPending] = useState("0");
  const [insightPaid, setInsightPaid] = useState("0");
  const referralCode = userProfile?.referral_code || ""
  const insightDataAdmin = async () => {
    try {
      const res = await getRequest("/v1/franchise/payment/summary?fr_id=" + franchiseId, 'ADMIN');
      setInsightRevenue(fmt.format(res.data.response.revenew))
      setInsightProfit(fmt.format(res.data.response.profit))
      setInsightPending(fmt.format(res.data.response.pending))
      setInsightPaid(fmt.format(res.data.response.transferred))
    } catch (error) {
      throw error;
    }
  }
  const insightDataUser = async () => {
    try {
      const res = await getRequest("/v1/franchise/payment/summary/" + referralCode, 'ADMIN');
      setInsightRevenue(fmt.format(res.data.response.revenew))
      setInsightProfit(fmt.format(res.data.response.profit))
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (userType === "FRANCHISE_ADMIN") {
      insightDataAdmin()
    }
    else {
      insightDataUser()
    }

  }, [])

  useEffect(() => {
    getBillingAndPaymentList();
  }, [
    activeStatus,
    dateRange,
    searchQuery,
    currentPage,
    pageSize,
    filterValues,
  ]);

  const initializeSearch = () => {
    let search: any = {
      page: {
        no: currentPage,
        size: pageSize,
      },
      query: {
        filter: [

        ],
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };
    if (userId.current) {
      search.query.filter.push(
        {
          fields: ["franchise_id"],
          search: userId.current ? userId.current : '',
          search_match: ESearchMatch.EXACT,

        });
    }
    if (filterValues && filterValues.locations && filterValues.locations.fields) {
      search.query.filter.push(filterValues.locations);
    }
    if (filterValues && filterValues.salesPerson && filterValues.salesPerson.fields) {
      search.query.filter.push(filterValues.salesPerson);
    }
    if (filterValues && filterValues.companies && filterValues.companies.fields) {
      search.query.filter.push(filterValues.companies);
    }
    if (userRefCode.current) {
      search.query.filter.push(
        {
          fields: ["franchise_seller_ref_code"],
          search: userRefCode.current ? userRefCode.current : '',
          search_match: ESearchMatch.EXACT,

        });
    }
    if (dateRange.startDate) {
      search.query.filter.push({
        fields: activeStatus === EAllStatus.BILLING ? ["created_on"] : ["payment_date"],
        between: [dateRange.startDate, dateRange.endDate],
        search_match: ESearchMatch.EXACT,
      });
    }
    if (searchQuery) {
      search.query.search = {
        fields: activeStatus === EAllStatus.BILLING ?
          ["company_name", "sales_person", "franchise_seller_ref_code", "location"] :
          ["invoice_number", "franchise_name", "mode_of_payment", "remarks"],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    if (selectedFranchise && selectedFranchise.length > 0) {
      search.query.filter.push({
        fields: activeStatus === EAllStatus.BILLING ? ["franchise_revenue_id"] : ["payment_id"],
        search: selectedFranchise.map((res) => res.id).toString(),
        search_match: ESearchMatch.IN,
      });
    }
    return search;
  };
  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageCountChange = (eventKey: any) => {
    setPageSize(eventKey);
    setCurrentPage(1);
  };

  const getBillingAndPaymentList = async () => {
    try {
      await getAndStoreFranchiseDeatils();
      const userProfileData = getLocalStorageItem("userProfile");
      if (userProfileData.user_type === "FRANCHISE_ADMIN") {
        userId.current = userProfileData?.franchise_id;
        userRefCode.current = "";
      } else {
        userRefCode.current = userProfileData?.referral_code;
        userId.current = "";
      }
      const searchRequest = initializeSearch();

      let res: any = {};

      if (activeStatus === EAllStatus.BILLING) {
        res = await getFranchiseRevenueList(searchRequest);
        setBillingList(res?.response.content);
      }
      if (activeStatus === EAllStatus.PAYMENTS) {
        res = await getFranchisePaymentList(searchRequest);
        setPaymentList(res?.response.content);
      }

      setTotalPagesCount(res.response.total_elements);
    } catch (error) { }
  };

  const onSelectAll = (e: any): void => {
    if (e.target && e.target.checked) {
      let franchiseList: any = [];
      if (activeStatus === EAllStatus.BILLING) {
        franchiseList = billingList;
      }
      if (activeStatus === EAllStatus.PAYMENTS) {
        franchiseList = paymentList;
      }
      const franchises =
        franchiseList?.map((ele: any) => ({
          id:activeStatus === EAllStatus.BILLING ?  ele.franchise_revenue_id : ele.payment_id ,
          name: ele.franchise_name,
        })) || [];
      setSelectedFranchise(franchises);
    } else {
      setSelectedFranchise([]);
    }
  };

  const handleButtonClick = (buttonText: string) => {
    setCurrentPage(1);
    setSelectedFranchise([]);
    setBillingList([]);
    setPaymentList([]);
    setActiveStatus(buttonText);
  };

  const onDownloadExcel = async (): Promise<void> => {
    const searchRequest = initializeSearch();
    let res;
    if (activeStatus === EAllStatus.BILLING) {
      res = await getFranchiseRevenueListExcel(searchRequest);
    }
    if (activeStatus === EAllStatus.PAYMENTS) {
      res = await getFranchisePaymentListExcel(searchRequest);
    }
    if (res) {
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${convertText(activeStatus)}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  return (
    <>
      <div className="container-fluid">
        <TopMenuBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <OnboardedCompanyFilter
          isBillingPage={true}

          setFilterValues={setFilterValues}
        />

        <div className="row pb-3 align-items-end">
          <div className="col-7 d-flex justify-content-between">
            {userProfile.user_type === "FRANCHISE_ADMIN" && (
              <div className="edit-options d-flex align-items-center justify-content-between">
                <div className="filter-table-buttons d-flex gap-2">
                  <Button
                    type="button"
                    className={
                      activeStatus === EAllStatus.BILLING ? "active" : ""
                    }
                    onClick={() => {
                      handleButtonClick(EAllStatus.BILLING);
                    }}
                  >
                    {convertText(EAllStatus.BILLING)}
                  </Button>
                  <Button
                    type="button"
                    className={
                      activeStatus === EAllStatus.PAYMENTS ? "active" : ""
                    }
                    onClick={() => {
                      handleButtonClick(EAllStatus.PAYMENTS);
                    }}
                  >
                    {convertText(EAllStatus.PAYMENTS)}
                  </Button>
                </div>
              </div>
            )}

          </div>

        </div>
        <div className="service-performance-total-records d-md-flex justify-content-between align-items-center pb-2">
          <span>Total Records: {activeStatus === EAllStatus.BILLING ? billingList.length : paymentList.length}</span>
          
          <div className="insights d-sm-flex align-items-center mx-md-4">
              <div className="d-flex mx-2" style={{ fontSize: "14px" }}>
                <a style={{ color: "orange" }}>Revenue:</a>
                <a style={{ fontWeight: "500", marginLeft: "3px" }}> ₹{insightRevenue}</a>
              </div>
              <div className="d-flex mx-2" style={{ fontSize: "14px" }}>
                <a style={{ color: "green" }}>Profit:</a>
                <a style={{ fontWeight: "500", marginLeft: "3px" }}> ₹{insightProfit}</a>
              </div>
              {userType === "FRANCHISE_ADMIN" && (
                <>
                  <div className="d-flex mx-2" style={{ fontSize: "14px" }}>
                    <a style={{ color: "red" }}>Pending:</a>
                    <a style={{ fontWeight: "500", marginLeft: "3px" }}> ₹{insightPending}</a>
                  </div>
                  <div className="d-flex mx-2" style={{ fontSize: "14px" }}>
                    <a style={{ color: "blue" }}>Paid:</a>
                    <a style={{ fontWeight: "500", marginLeft: "3px" }}> ₹{insightPaid}</a>
                  </div>
                </>
              )}
            </div>
          
          <div className="d-sm-flex align-items-center justify-content-end">
            
            <button
              className="download-report-btn"
              onClick={() => onDownloadExcel()}
            >
              <img
                src={DownloadIcon}
                alt="Download"
                className="download-icon me-1"
                loading="lazy"
              />
              Download Report
            </button>
            <div className="ps-sm-2">
              <Calendar
                className="calendar"
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </div>
          </div>
        </div>

        {activeStatus === EAllStatus.BILLING && (
          <BillingTable
            key={activeStatus}
            franchiseList={billingList}
            onSelectAll={onSelectAll}
            selectedFranchise={selectedFranchise}
            setSelectedFranchise={setSelectedFranchise}
          />
        )}

        {activeStatus === EAllStatus.PAYMENTS && (
          <PaymentTable
            key={activeStatus}
            franchiseList={paymentList}
            onSelectAll={onSelectAll}
            selectedFranchise={selectedFranchise}
            setSelectedFranchise={setSelectedFranchise}
          />
        )}

        <div className="row align-items-center pt-25 pb-50 pagination-section py-3">
          <div className="col-2 text-start service-performance-selected-records">
            <span className="">{selectedFranchise ? selectedFranchise.length : 0} Items Selected</span>
          </div>
          <div className="col-8 text-end mt-1">
            <form className="d-flex justify-content-end align-item">
              <span className="text-primary-blue pe-1 font-12 d-flex align-items-center">
                Show
              </span>
              <div className="total-pages me-2 position-relative">
                <span className="d-flex align-items-center">{pageSize}</span>
                <DropdownButton onSelect={onPageCountChange} title={""}>
                  <Dropdown.Item eventKey="10">10</Dropdown.Item>
                  <Dropdown.Item eventKey="25">25</Dropdown.Item>
                  <Dropdown.Item eventKey="50">50</Dropdown.Item>
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>
              </div>
            </form>
          </div>
          <div className="col-2">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalPagesCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              prevPageText={<img src={PaginationIcon} alt="Previous" />}
              nextPageText={<img src={PaginationIcon} alt="Next" />}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAndPayment;
