import React, { FC, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

interface TitleProps {
  chartData: any;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function convertText(inputText: string) {
  const words = inputText?.split("_");
  const convertedText = words?.map((word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  });
  return convertedText?.join(" ");
}

const getLineColor = (index: number) => {
  if (index === 0) return "rgb(242, 208, 205)";
  if (index === 1) return "rgb(251, 233, 191)";
  if (index === 2) return "rgba(30, 142, 62, 0.25)";
  if (index === 3) return "rgb(196, 219, 249)";
  return `rgb(0,0,0)`;
};

const LineChart: FC<TitleProps> = ({ chartData }) => {
  const removedEmpty = [...chartData]?.filter(
    (x: any) => !!Object.keys(x).length
  );
  const labels = removedEmpty.length > 0 ? 
  removedEmpty[0]?.response?.map((y: any) => {
    const date = moment(y.date);
    return date.format("DD MMM YYYY");
  }) : [];
  
  
  const allRatios = chartData.flatMap((chartDetails: any) => 
    chartDetails.response?.map((obj: any) => obj.ratio) || []
  );
  const minValue = Math.min(...allRatios);
  const maxValue = Math.max(...allRatios);

  const startDate = labels.length > 0 ? labels[0] : "";
  const endDate = labels.length > 0 ? labels[labels.length - 1] : "";


  const options: ChartOptions<'line'> = {
    interaction: {
      intersect: false,
      mode: 'index',
    },

    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          minRotation: 0,
          maxRotation: 0,
          callback: function (value:any, index:any, values:any) {
            const label = labels[index];
            if (label === startDate || label === endDate) {
              return label
            }
            return '';
          },
        },
      },
      y: {
        ticks: {
          display: true,
           callback: function(value : any, index : any, values:any) {
            if (value === minValue || value === maxValue) {
              return value;
            }
            return '';
          },
        },
        min: minValue,
        max: maxValue,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
     
      tooltip: {
        padding: {
          left: 20,
          right: 20,
          bottom: 20,
          top: 20,
      },
        callbacks: {
          label: function (context: any, ...a: any) {

            let label = "";
            const dataIndex = context?.dataIndex;
            const activeData =
              context?.dataset?.chartDetails?.response &&
              context.dataset.chartDetails.response[dataIndex];

            if (context.parsed.x !== null) {
              const date =
                activeData && activeData.date
                  ? moment(activeData.date).format("DD-MM-YYYY")
                  : "";

              const recordsWithSameDate = context.chart.data.datasets.reduce(
                (accumulator: any, dataset: any, i: number) => {
                  const datasetRecords = dataset.chartDetails?.response?.filter(
                    (item: any) =>
                      moment(item?.date)?.format("DD-MM-YYYY") === date
                  );

                  if (datasetRecords?.length > 0) {
                    label += `${context.chart.data.datasets[i].label}: ${
                      datasetRecords[0]?.count || 0
                    }\n \n`;
                  }

                  return accumulator.concat(datasetRecords);
                },
                []
              );
            }
          },
        },
      },
    },
  };

  const datasets = chartData?.map((chartDetails: any, index: number) => {
    const response = chartDetails.response?.map((obj: any) => obj.ratio);
    const categoryName =
      convertText(chartDetails?.selectedStatus?.category_name) ?? "Category";
    const subCategoryName =
      convertText(chartDetails?.selectedStatus?.category_sub_name) ??
      "Subcategory";

    return {
      label: `${categoryName} - ${subCategoryName}`,
      data: response,
      borderColor: getLineColor(index),
      backgroundColor: getLineColor(index),
      chartDetails: chartDetails,
      pointStyle: 'none',
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
