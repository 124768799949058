import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import SearchIcon from "../assets/images/icons/search.svg";
import { EOrder, EOrderSequencing, ESearchMatch } from "../core/enums/Search.enums";
import {
  ICommonSearch,
  ISearch,
} from "../core/interfaces/SearchRequest.interface";
import {
  convertToMonthAndYear,
  convertToMonthDateAndYear,
  convertToYYYYMMDDFromDBDate,
} from "../core/services/utilityService";
import { IAnnouncement } from "../interface/announcement.interface";
import {
  getAllAnnouncement,
  readAnnouncement,
} from "../services/SupportApiService";
import AnnouncementPreview from "./AnnouncementPreview";
import { getAccessToken, getLocalStorageItem } from "../core/services/StorageService";
import CloseIcon from "../assets/images/closeIconX.png"


interface IAnnouncementsModal {
  showAnnouncement: boolean;
  setShowAnnouncement: any;
}

const Announcements: React.FC<IAnnouncementsModal> = ({
  showAnnouncement,
  setShowAnnouncement,
}) => {
  const [announcementPreview, setShowAnnouncementPreview] =
    useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [announcementList, setAnnouncementList] = useState<IAnnouncement[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [announcementActiveIn, setAnnouncementActiveIn] = useState<number>(0);
  const userProfile = getLocalStorageItem("userProfile")
  const franchiseType = userProfile?.franchise_type
  const assignedLocation = userProfile?.assigned_location
  const category = userProfile?.category_id

  const initializeSearch = () => {
    let search: ISearch = {
      page: {
        no: currentPage,
        size: pageSize,
      },
      query: {
        filter:  [
          {
              "fields": [
                  "franchise_type"
              ],
              "search": `${franchiseType},BOTH`,
              "search_match": ESearchMatch.IN
          },
          {
              "fields": [
                  "category"
              ],
              "search": category,
              "search_match": ESearchMatch.IN
          }
          ,
          {
              "fields": [
                  "assigned_locations"
              ],
              "search": assignedLocation,
              "search_match": ESearchMatch.IN
          }
      ],
        search: {} as ICommonSearch,
      },
      sort: [
        {
          field: "last_updated_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };

    if (searchQuery && search && search.query && search.query.search) {
      search.query.search = {
        fields: ["title", "description"],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    return search;
  };

  const getAnnouncementList = async () => {
    try {
      if (getAccessToken()) {
        const searchRequest = initializeSearch();
        const res = await getAllAnnouncement(searchRequest);
        if (res.response && res.response.content) {
          setTotalPagesCount(res.response.total_elements);
          setAnnouncementList(res.response.content);
        }
      }
    } catch (error) {}
  };

  const handleReadAnnouncement = async (announcementId: string) => {
    if (announcementId) {
      let res = await readAnnouncement(announcementId);
      getAnnouncementList();
    }
  };
  useEffect(() => {
    getAnnouncementList();
  }, [searchQuery, currentPage, pageSize]);
  return (
    <div>
      <Modal
        isOpen={showAnnouncement}
        toggle={() => setShowAnnouncement(!showAnnouncement)}
        centered
        className="announcement-popup position-relative me-4 mt-0"
        style={{ top: "3%" }}
      >
        <div className="position-relative search-input m-3">
            <input
              type="search"
              className="form-control w-100 ps-5"
              placeholder="What's new on Promilo?"
              onChange={(e) => setSearchQuery?.(e.target.value)}
              
            />
            <img className="close-button" src={CloseIcon} onClick={() => setShowAnnouncement(false)}></img>
            <img
              src={SearchIcon}
              loading="lazy"
              alt="Search"
              className="search-icon"
            />
            
          </div>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          

          <>
            {announcementList?.map((announcement, index) => (
              <div
                className={`card mb-3 p-3 ${
                  announcement?.read ? "" : "unread"
                }`}
                key={announcement.announcement_id}
              >
                <div className="d-flex justify-content-between align-items-center pb-1">
                  <h2 className="mb-0 font-16 fw-500">{announcement?.title}</h2>
                  <p className="mb-0 font-14 text-grey">
                    {convertToMonthDateAndYear(announcement.published_on)}
                  </p>
                </div>
                <div className="font-16 fw-500 text-grey">
                  <span
                    className="text-decoration-underline ps-1 fw-500 pointer"
                    onClick={() => {
                      setAnnouncementActiveIn(index);
                      setShowAnnouncementPreview(!announcementPreview);
                    }}
                  >
                    click here
                  </span>
                </div>
                {announcement.image_url && (
                  <img
                    src={announcement.image_url}
                    loading="lazy"
                    alt="Sample Image"
                    className="my-1"
                    style={{height:"220px",borderRadius:"8px"}}
                  />
                )}
              </div>
            ))}
          </>
        </ModalBody>
      </Modal>
      <AnnouncementPreview
        announcementPreview={announcementPreview}
        setShowAnnouncementPreview={setShowAnnouncementPreview}
        announcementList={announcementList}
        activeIndex={announcementActiveIn}
        handleReadAnnouncement={handleReadAnnouncement}
      />
    </div>
  );
};

export default Announcements;
