import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button } from "reactstrap";
import CalendarIcon from "../assets/images/calendar-icon.png";
import { ICalendarProps } from "../interface/Calendar.interface";
import moment from "moment";

const Calendar: FC<ICalendarProps> = ({
  className,
  dateRange,
  setDateRange,
}) => {
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [isOpen, setIsOpen] = useState(false);
  const dateRangePickerRef = useRef<HTMLDivElement | null>(null);
  const [selectedRangeInState, setSelectedRangeInState] = useState<any>({
    startDate: "",
    endDate: ""
  });

  useEffect(() => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      setSelectedRange({
        startDate: convertToDate(dateRange.startDate),
        endDate: convertToDate(dateRange.endDate),
        key: "selection",
      });
    }
  }, [dateRange]);

  const handleSelect = (ranges: any) => {
  setSelectedRangeInState({
        ...dateRange,
        startDate: moment(ranges.selection.startDate).format("DD/MM/YYYY"),
        endDate: moment(ranges.selection.endDate).format("DD/MM/YYYY"),
      });
    setSelectedRange(ranges.selection);
  };

  const handleApplyDateFilter = () => {
    if ((selectedRangeInState.startDate && selectedRangeInState.endDate)) {
      setDateRange(selectedRangeInState);
    } else {
      setDateRange({
        startDate: moment(selectedRange.startDate).format("DD/MM/YYYY"),
        endDate: moment(selectedRange.endDate).format("DD/MM/YYYY"),
      });
    }
    setIsDateRangePickerOpen(false);
  }

  const toggleDateRangePicker = () => {
    setIsDateRangePickerOpen((prevState) => !prevState);
  };

  const convertToDate = (dateString: string) => {
    const [day, month, year] = dateString.split("/");
    // Months are 0-indexed in JavaScript Date, so subtract 1 from the month
    return new Date(`${year}-${month}-${day}`);
  };

  const formattedDateRange = `${selectedRange.startDate.toLocaleDateString()} - ${selectedRange.endDate.toLocaleDateString()}`;

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dateRangePickerRef.current &&
        !dateRangePickerRef.current.contains(event.target as Node)
      ) {
        setIsDateRangePickerOpen(false);
      }
    },
    [setIsDateRangePickerOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const resetDateRange = () => {
    setSelectedRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setDateRange({
      startDate: "", 
      endDate: "", 
    });
  };

  return (
    <div className="position-relative">
      <Button
        color="calendar"
        className="calendar-btn text-start position-relative"
        onClick={toggleDateRangePicker}
      >
        <span className="date-range" style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> {formattedDateRange}</span>
        <img alt="Calendar" src={CalendarIcon} className="position-absolute" />
      </Button>
      {isDateRangePickerOpen && (
        <div
          ref={(ref) => (dateRangePickerRef.current = ref)}
          className="date-range-picker-calendar-wrapper"
        >
          <DateRangePicker maxDate={new Date()} ranges={[selectedRange]} onChange={handleSelect} />
          <div className="text-end date-range-picker-submit bg-white d-flex justify-between align-center">
          <button
                className="outline-0 border-0 fw-500"
                onClick={() => handleApplyDateFilter()}
              >
                Apply
              </button>
              <div>
            <button className="outline-0 border-0" onClick={resetDateRange}>
              Reset
            </button>
            <button
              className="outline-0 border-0"
              onClick={() => setIsDateRangePickerOpen(false)}
            >
              Close
            </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
