import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import UploadIcon from "../assets/images/upload.png"
import LinkIcon from "../assets/images/link-alt.png"
import CameraIcon from "../assets/images/camera.png"
import AttachmentUrl from "./AttachmentUrl";
import WebCam from "./Webcam"
import ImageCropper from "./ImageCropper"

interface IMediaUploadModal {
    showPicUploadModal: boolean;
    setShowPicUploadModal: any;
    setNewCroppedImage:any
    title:string
    file?:any
}


const MediaUploadModal: React.FC<IMediaUploadModal> = ({
  showPicUploadModal,
  setShowPicUploadModal,
  setNewCroppedImage,
  title,
}
) => {

  const [showLinkModal,setShowLinkModal] = useState(false)
  const [showCameraModal,setShowCameraModal] = useState(false)
  const [image, setImage] = useState(null);
  const [ProfileWebCam, setProfileWebCam] = useState(true)
  const [selectedImage,setSelectedImage] = useState(null)
  const [showImageCrop,setShowImageCrop] = useState(false)
  const setImageNew = async(image:any) => {
    const formData = new FormData();
    formData.append("imageUrl", image);
    // let data =await putProfileImage(formData);  ----Api to upload picture
    // await getUserData();  ----Api to refetch data
  };
  const handleImageUpload = async (event:any) => {
   if(event.target.files && event.target.files.length>0){
    const reader:any = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.addEventListener("load",()=>{
      setSelectedImage(reader.result);
    })
    setShowImageCrop(true)

   }
    
  };
  return (
    <div>
      <Modal
        isOpen={showPicUploadModal}
        className="open-document-modal"
        style={{ maxWidth: "670px",top:"70px" }}>
        <ModalHeader toggle={() => setShowPicUploadModal(!showPicUploadModal)}>
          <div>
            <span className="font-16 ms-2 fw-500">
              {title}
            </span>
          </div>
        </ModalHeader>
        <ModalBody style={{height:"350px"}}>
        <div
            className="choose-remark-statement px-3 py-2 mb-3">
            <div className="d-flex justify-content-start flex-wrap">
                    <div>
                    <label htmlFor="fileInput">
                        <div className="picture-upload-card my-2 mx-2 pointer">
                            <div className="document-imagebox">
                            <input
            type="file"
            multiple
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="fileInput"
          />
          
            <img className="px-4" src={UploadIcon} alt="Attachment" />
          
                            </div>
                            <div className="document-name ">
                                <h6 className="mx-3">Upload</h6>
                              </div>  
                        </div>
                        </label>
                    </div>
                    <div>
                        <div className="picture-upload-card my-2 mx-2 pointer" onClick={()=>setShowLinkModal(!showLinkModal)}>
                            <div className="document-imagebox">
                              <img src={LinkIcon}></img>
                            </div>
                            <div className="document-name ">
                                <h6 className="mx-3">Link</h6>
                              </div>  
                        </div>
                    </div>
                    <div>
                        <div className="picture-upload-card my-2 mx-2 pointer" onClick={()=>setShowCameraModal(!showCameraModal)}>
                            <div className="document-imagebox">
                              <img src={CameraIcon}></img>
                            </div>
                            <div className="document-name ">
                                <h6 className="mx-3">Camera</h6>
                              </div>  
                        </div>
                    </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end border-0">

        </ModalFooter>
      </Modal>
        {
            showLinkModal &&
            <AttachmentUrl
        showAttachmentModel={showLinkModal}
        setAttachmentModal={setShowLinkModal}
        setImage={setImageNew}
        className="bookmark-modal"
        setNewCroppedImage={setNewCroppedImage}
        setSelectedImage={setSelectedImage}
        setShowImageCrop={setShowImageCrop}
      ></AttachmentUrl>
        }
        {
            showCameraModal &&
            <WebCam
            showWebCamModel={showCameraModal}
            setWebCamModal={setShowCameraModal}
            setImage={setImage}
            ProfileWebCam={ProfileWebCam}
            className="bookmark-modal search-modal"
            setNewCroppedImage={setNewCroppedImage}
            setSelectedImage={setSelectedImage}
            setShowImageCrop={setShowImageCrop}
          ></WebCam>
        }
        {
                showImageCrop &&
                <ImageCropper showImageCrop={showImageCrop} setShowImageCrop={setShowImageCrop} image={selectedImage} setNewCroppedImage={setNewCroppedImage} setShowLinkModal={setShowLinkModal} setShowCameraModal={setShowCameraModal} setShowPicUploadModal={setShowPicUploadModal} cropBoxHeight={120} cropBoxWidth={120} />
        }
    </div>
  );
};

export default MediaUploadModal;
