import React, { FC } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AttachmentIcon from "../assets/images/icons/attachment-icon.png";
import { ITicket } from "../interface/ticket.interface";
import { convertToTitleCase, convertToYYYYMMDDFromDBDate } from "../core/services/utilityService";
import { IDocument } from "../core/interfaces/Document.interface";

interface PendingTicketsModalProps {
  modalAction: string;
  selectedTicket: ITicket,
  setModal: React.Dispatch<React.SetStateAction<string>>;
}
const downloadDocment = (document: IDocument) => {
  window.open(document.location, '_blank')
}
const PendingTicketsModal: FC<PendingTicketsModalProps> = ({ modalAction, setModal, selectedTicket }) => {
  return (
    <>
      <Modal
        isOpen={modalAction ? true : false}
        toggle={() => setModal("")}
        centered
        size="lg"
        modalClassName="pending-ticket-modal"
      >
        <ModalHeader toggle={() => setModal("")}>
          {modalAction === "ATTACHMENTS" ? "Attachments" : "Tickets"}
        </ModalHeader>
        <ModalBody>
          <div className="row g-3">
            <div className="col-12 col-md-4">
              <label className="font-12 label-text d-block pb-1">
                Created Date
              </label>
              <input
                type="text"
                value={convertToYYYYMMDDFromDBDate(selectedTicket.published_on)}
                name="createdDate"
                id="created-date"
                className="input-text p-2 font-14 w-100"
                disabled
              />
            </div>
            <div className="col-12 col-md-4">
              <label className="font-12 label-text d-block pb-1">
                Business Email*
              </label>
              <input
                type="text"
                value={selectedTicket.business_mail}
                name="businessEmail"
                id="business-email"
                className="input-text p-2 font-14 w-100"
                disabled
              />
            </div>
            <div className="col-12 col-md-4">
              <label className="font-12 label-text d-block pb-1">Status</label>
              <input
                type="text"
                value={convertToTitleCase(selectedTicket.ticket_status)}
                name="status"
                id="status"
                className="input-text p-2 font-14 w-100"
                disabled
              />
            </div>
            {
              modalAction === 'ATTACHMENTS' && selectedTicket.documents && selectedTicket.documents.map((doc, i) => {
                return (
                  <div className="col-12 col-md-4">
                    <label className="font-12 label-text d-block pb-1">
                      Attachment - {i + 1}
                    </label>
                    <div className="input-text font-14 w-100 d-flex align-items-center p-2 justify-content-between">
                      <div className="text-truncate">{doc.file_name}</div>
                      <img
                        className="pointer"
                        onClick={() => downloadDocment(doc)}
                        src={AttachmentIcon}
                        alt="Attachment"
                        style={{ width: "16px" }}
                      />
                    </div>
                  </div>
                )
              })
            }
            {
              modalAction === 'DETAILS' &&
              <div className="col-12 col-12">
                <label className="font-12 label-text d-block pb-1">
                  Detailed Content
                </label>
                <textarea
                  name="detailedContent"
                  id="detailed-content"
                  className="w-100 p-2"
                  disabled
                >{selectedTicket.description}</textarea>
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PendingTicketsModal;
