import React, { FC, useEffect, useState } from "react";
import TopMenuBar from "../common-components/TopMenuBar";
import DefaultProfilePic from "../assets/images/user-alt-1-profilepic.png";
import { Button, Input } from "reactstrap";
import OpenDocumentModal from "./OpenDocumentModal"
import { getLocalStorageItem,setLocalStorageItem, setUserData } from "../core/services/StorageService";
import { getFranchiseById, getAllUsersFranchiser } from "../services/FranchiseApiService"
import MediaUploadModal from "./MediaUploadModal";
import EditIcon from "../assets/images/Icon-edit.png"
import { putProfileImage } from "../services/FranchiseApiService"
import { getAndStoreFranchiseDeatils } from "../services/AuthApiService"
import { EUserType } from "../enums/user.enum";

interface ITitleProps {
    title: string;
}

const MyProfile: FC<ITitleProps> = ({ title }) => {
    const [userDetails, setUserDetails] = useState<any>()
    const [usersList, setUsersList] = useState<any[]>([])
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const userProfile = getLocalStorageItem("userProfile")
    const [primaryUserName,setPrimaryUserName] = useState("")
    const [salesPersonList,setSalesPersonList] = useState<any[]>([])
    const [documents,setDocuments] = useState([])
    const [showPicUploadModal,setShowPicUploadModal] =  useState(false)
    const [newCroppedImage,setNewCroppedImage] = useState<any>(null)
    const franchiserId = userProfile?.franchise_id;
    const userData = getLocalStorageItem("userData")
    const [profileImage,setProfileImage] = useState<string>(userData.profilePic) 
    useEffect(() => {
        getFranchiserDetails()
        getUsersList()
    }, [])
    useEffect(()=>{
        getPrimaryUserNameFromList(usersList)
        setSalesPersons(usersList)
    },[usersList])
    useEffect(()=>{
        if(newCroppedImage){
        updateProfilePic()
        }
    },[newCroppedImage])
    const setSalesPersons = (AllUserList:any[])=>{
        const salespersons = AllUserList.filter((user)=>user.user_type !== "FRANCHISE_ADMIN")
        setSalesPersonList(salespersons)
    }
    const getPrimaryUserNameFromList = (usersList:any[])=>{
        const primaryUserName =  usersList.find(user => user.user_type === "FRANCHISE_ADMIN")?.first_name;
        setPrimaryUserName(primaryUserName)
    }
    const getUsersList = async()=>{
        try {
            const res = await getAllUsersFranchiser(franchiserId)
            setUsersList(res.response)
        } catch (err) {
            console.log(err)
        }
    }
    const getFranchiserDetails = async () => {
        try {
            const res = await getFranchiseById(franchiserId)
            setUserDetails(res.response)
        } catch (err) {
            console.log(err)
        }
    }
    const updateProfilePic = async()=>{
        try {
            const formData = new FormData();
    
            let file;
            if (typeof newCroppedImage === 'string' && newCroppedImage.startsWith('data:image')) {
                const base64Response = await fetch(newCroppedImage);
                const blob = await base64Response.blob();
                file = new File([blob], 'profileImage.jpeg', { type: blob.type });
            } else if (newCroppedImage instanceof Blob) {
                file = new File([newCroppedImage], 'profileImage.jpeg', { type: newCroppedImage.type });
            } else {
                throw new Error("Invalid image format. The image must be a base64 string or a Blob.");
            }
            formData.append("profileImage", file);
            let response = await putProfileImage(formData);
            if (response.response === null) {
                console.log("Profile picture updated successfully");
                const profilePic = (userData.profilePic as string).split('?').length > 0 ?
                 (userData.profilePic as string).split('?')[0]: userData.profilePic;
                 const profilePicWithTime = profilePic + '?time='+new Date().getTime()
                setUserData({...userData, 'profilePic': profilePicWithTime});
                setProfileImage(profilePicWithTime)
            } else {
                console.log("Error updating profile picture");
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="container-fluid">
                <TopMenuBar
                    hideSearchBar={true}
                    showBackButton={true}
                    pageTitle={title}
                />
                <div className="p-3">
                    <div>
                        <div className="myprofileheading">
                            <div className="myprofilepic" onClick={()=>setShowPicUploadModal(!showPicUploadModal)}> 
                            {localStorage.getItem('userData')? 
                            <img 
                            src={profileImage ? 
                                profileImage : DefaultProfilePic} 
                            alt="Profile" 
                            style={{height:"100%",width:"100%"}} />
                            : ''}
                                <div className="edit-icon-container">
        <img src={EditIcon} alt="Edit" className="edit-icon" />
      </div>
                            </div>
                            <div className="m-3">
                                <h4 style={{ display: "inline", color: "#006599" }}>
                                    {userDetails?.franchise_name}
                                </h4>
                                <br />
                                <h5 style={{ color: "grey" }}>
                                    {`${userDetails?.address?.city}, ${userDetails?.address?.state}, ${userDetails?.address?.country}`}
                                </h5>
                            </div>
                        </div>
                        {getLocalStorageItem('userProfile').user_type === EUserType.FRANCHISE_ADMIN ?
                        <>                        <div className="profile-body-container my-5">
                        <div className="franchise-details">
                            <div className="my-2">
                                <h5 style={{ color: "#006599" }}> Franchise Details</h5>
                            </div>
                            <div className="my-2" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className="d-flex align-items-center my-2">
                                    <label htmlFor="franchiseName" style={{ width: "170px" }}>
                                        Franchise Name
                                    </label>
                                    <Input
                                        disabled
                                        id="franchiseName"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.franchise_name || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="typesOfFranchise" style={{ width: "170px" }}>
                                        Types of Franchise
                                    </label>
                                    <Input
                                        disabled
                                        id="typesOfFranchise"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.franchise_type || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="emailId" style={{ width: "170px" }}>
                                        Email ID
                                    </label>
                                    <Input
                                        disabled
                                        id="emailId"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.email_id || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="contactNo" style={{ width: "170px" }}>
                                        Contact No.
                                    </label>
                                    <Input
                                        disabled
                                        id="contactNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.phone_number || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="aadhaarNo" style={{ width: "170px" }}>
                                        Aadhaar No.
                                    </label>
                                    <Input
                                        disabled
                                        id="aadhaarNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.aadhar_number || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="panNo" style={{ width: "170px" }}>
                                        PAN No.
                                    </label>
                                    <Input
                                        disabled
                                        id="panNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.pan_number || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="assignedIndustry" style={{ width: "170px" }}>
                                        Assigned Industry
                                    </label>
                                    <Input
                                        disabled
                                        id="assignedIndustry"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.industry?.industry_name || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="tanNo" style={{ width: "170px" }}>
                                        TAN No.
                                    </label>
                                    <Input
                                        disabled
                                        id="tanNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.tan_number || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="assignedCategory" style={{ width: "170px" }}>
                                        Assigned Category
                                    </label>
                                    <Input
                                        disabled
                                        id="assignedCategory"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.category_name || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="assignedLocation" style={{ width: "170px" }}>
                                        Assigned Location
                                    </label>
                                    <Input
                                        disabled
                                        id="assignedLocation"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.assigned_location || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="adminName" style={{ width: "170px" }}>
                                        Admin Name
                                    </label>
                                    <Input
                                        disabled
                                        id="adminName"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={primaryUserName}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="referralCode" style={{ width: "170px" }}>
                                        Referral Code
                                    </label>
                                    <Input
                                        disabled
                                        id="referralCode"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.referral_code || ""}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="profitMarginB2B" style={{ width: "170px", alignSelf: "start" }}>
                                        Profit Margin
                                    </label>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                       {
                                        userDetails?.profit_margin?.map((margin:any,index:number)=>(
                                            <div style={{ display: "flex", width: "300px", marginLeft: "10px", marginBottom: "10px" }}>
                                            <Input
                                                disabled
                                                id="profitMarginType"
                                                style={{ background: "white", width: "80%", height: "43px", color: "grey", borderRadius: "5px 0px 0px 5px" }}
                                                value={margin?.service_name || ""}
                                            />
                                            <Input
                                                disabled
                                                id="profitMarginValue"
                                                style={{ background: "white", width: "20%", height: "43px", color: "grey", borderRadius: "0px 5px 5px 0px" }}
                                                value={margin?.profit_margin+"%" || "%"}
                                            />
                                        </div>
                                        ))
                                       }
                                    </div>
                                </div>
                                <div className="d-flex align-items-start my-2">
                                    <label htmlFor="adminName" style={{ width: "170px" }}>
                                        Documents
                                    </label>
                                    <div
                                        style={{ background: "white", width: "300px", height: "47px", marginLeft: "10px", color: "grey" }}
                                    >
                                        <Button style={{ height: "100%", width: "50%", backgroundColor: "#006599" }} onClick={() => {
                                            setShowDocumentModal(!showDocumentModal)
                                            setDocuments(userDetails?.documents)
                                            }}>Open Document</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="profile-body-container my-5">
                        <div className="franchise-details">
                            <div className="my-2">
                                <h5 style={{ color: "#006599" }}> Address Details</h5>
                            </div>
                            <div className="my-2" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="franchiseName" style={{ width: "170px" }}>
                                        Address - 1
                                    </label>
                                    <Input
                                        disabled
                                        id="franchiseName"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.address?.address_line1}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="typesOfFranchise" style={{ width: "170px" }}>
                                        Address - 2
                                    </label>
                                    <Input
                                        disabled
                                        id="typesOfFranchise"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.address?.address_line2}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="emailId" style={{ width: "170px" }}>
                                        City
                                    </label>
                                    <Input
                                        disabled
                                        id="emailId"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.address?.city}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="contactNo" style={{ width: "170px" }}>
                                        State
                                    </label>
                                    <Input
                                        disabled
                                        id="contactNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.address?.state}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="aadhaarNo" style={{ width: "170px" }}>
                                        Country
                                    </label>
                                    <Input
                                        disabled
                                        id="aadhaarNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={ "India"}
                                    />
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <label htmlFor="panNo" style={{ width: "170px" }}>
                                        Pincode
                                    </label>
                                    <Input
                                        disabled
                                        id="panNo"
                                        style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                        value={userDetails?.address?.pin_code}
                                    />
                                </div>

                            </div>
                        </div>

                    </div></>
                        : ''}

                        {
                            salesPersonList.map((salesperson, index) => (
                                <div className="profile-body-container my-5">
                                    <div className="franchise-details">
                                        <div className="my-2">
                                            <h5 style={{ color: "#006599" }}> Sales Person Details {salesPersonList.length !== 1 ? ' - ' + (index + 1): ''}  </h5>
                                        </div>
                                        <div className="my-2" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="franchiseName" style={{ width: "170px" }}>
                                                    Name
                                                </label>
                                                <Input
                                                    disabled
                                                    id="franchiseName"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.first_name}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="typesOfFranchise" style={{ width: "170px" }}>
                                                    Email ID
                                                </label>
                                                <Input
                                                    disabled
                                                    id="typesOfFranchise"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.email_id}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="emailId" style={{ width: "170px" }}>
                                                    Phone Number
                                                </label>
                                                <Input
                                                    disabled
                                                    id="emailId"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.phone_number}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="contactNo" style={{ width: "170px" }}>
                                                    Date of Birth
                                                </label>
                                                <Input
                                                    disabled
                                                    id="contactNo"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.date_of_birth}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="aadhaarNo" style={{ width: "170px" }}>
                                                    Gender
                                                </label>
                                                <Input
                                                    disabled
                                                    id="aadhaarNo"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.gender}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="panNo" style={{ width: "170px" }}>
                                                    Assigned Industry
                                                </label>
                                                <Input
                                                    disabled
                                                    id="panNo"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.industry?.industry_name}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="assignedIndustry" style={{ width: "170px" }}>
                                                    Assigned Category
                                                </label>
                                                <Input
                                                    disabled
                                                    id="assignedIndustry"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.category_name}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="tanNo" style={{ width: "170px" }}>
                                                    Assigned Location
                                                </label>
                                                <Input
                                                    disabled
                                                    id="tanNo"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.assigned_location}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="assignedCategory" style={{ width: "170px" }}>
                                                    Aadhaar No.
                                                </label>
                                                <Input
                                                    disabled
                                                    id="assignedCategory"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details.aadharNumber}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="assignedLocation" style={{ width: "170px" }}>
                                                    PAN Number
                                                </label>
                                                <Input
                                                    disabled
                                                    id="assignedLocation"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details.panNumber}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="adminName" style={{ width: "170px" }}>
                                                    Promilo Refferal Code
                                                </label>
                                                <Input
                                                    disabled
                                                    id="adminName"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.referred_by}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    Referral Code
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.referral_code}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    Address - 1
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details?.address?.addressLine1}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    Address - 2
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details?.address?.addressLine2}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    City
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details?.address?.city}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    State
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details?.address?.state}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    Country
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={"India"}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="referralCode" style={{ width: "170px" }}>
                                                    Pincode
                                                </label>
                                                <Input
                                                    disabled
                                                    id="referralCode"
                                                    style={{ background: "white", width: "300px", height: "43px", marginLeft: "10px", color: "grey" }}
                                                    value={salesperson?.additional_details?.address?.pinCode}
                                                />
                                            </div>

                                            <div className="d-flex align-items-start my-2">
                                                <label htmlFor="adminName" style={{ width: "170px" }}>
                                                    Documents
                                                </label>
                                                <div
                                                    style={{ background: "white", width: "350px", height: "47px", marginLeft: "10px", color: "grey" }}
                                                >
                                                    <Button style={{ height: "100%", width: "50%", backgroundColor: "#006599" }} onClick={() => {
                                                        setShowDocumentModal(!showDocumentModal)
                                                        setDocuments(salesperson?.documents)
                                                        }}>Open Document</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {
                showDocumentModal &&
                <OpenDocumentModal showDocumentModal={showDocumentModal} setShowDocumentModal={setShowDocumentModal} documents={documents} />
            }
            {
                showPicUploadModal &&
                <MediaUploadModal showPicUploadModal={showPicUploadModal} setShowPicUploadModal={setShowPicUploadModal} setNewCroppedImage={setNewCroppedImage} title={"Profile Picture"} />
             
            }
            
        </>
    );
}

export default MyProfile;
