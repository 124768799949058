import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody } from "reactstrap";
import { ModalHeader } from 'react-bootstrap';
import moment from 'moment';
import DefaultUserIMG from "../assets/images/default-user.png";
import { readUnreadNotification } from "../services/FranchiseApiService";

interface Notification {
  notificationId: string;
  title: string;
  message: string;
  notificationType: string;
  messageType: string;
  status: string;
  receiver: string;
  gateway: string;
  additionalDetails: {
    ACTION_TYPE: string;
  };
  creationDate: string;
  image?: string;
}

interface GroupedNotifications {
  today: Notification[];
  tomorrow: Notification[];
  others: {
    [date: string]: Notification[];
  };
}

interface INotificationModal {
  showNotification: boolean;
  setShowNotification: (show: boolean) => void;
  groupedNotifications: GroupedNotifications;
  fetchNotificationData: (page: { no: number, size: number }) => void;
}

const NotificationModal: React.FC<INotificationModal> = ({
  showNotification,
  setShowNotification,
  groupedNotifications,
  fetchNotificationData,
}) => {
  const [page, setpage] = useState({ no: 1, size: 20 })
  const pageRef = useRef<number>(1);
  const [isLoading, setIsLoading] = useState(false);


  const timeAgo = (date: string) => {
    const now = new Date();
    const notificationDate = new Date(date);
    const diffInSeconds = Math.floor((now.getTime() - notificationDate.getTime()) / 1000);

    const units: { [key: string]: number } = {
      year: 31536000,
      month: 2592000,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    for (const unit in units) {
      const interval = Math.floor(diffInSeconds / units[unit]);
      if (interval >= 1) {
        return `${interval} ${unit}${interval !== 1 ? 's' : ''} ago`;
      }
    }
    return "just now";
  };

  const handleReadNotification = async (ID: string) => {
    await readUnreadNotification([ID]);
    fetchNotificationData(page);
  };

  const getNotificationIds = (groupedNotifications: GroupedNotifications): string[] => {
    const ids: string[] = [];

    groupedNotifications.today.forEach(notification => ids.push(notification.notificationId));
    groupedNotifications.tomorrow.forEach(notification => ids.push(notification.notificationId));
    Object.keys(groupedNotifications.others).forEach(date => {
      groupedNotifications.others[date].forEach(notification => ids.push(notification.notificationId));
    });

    return ids;
  };

  const handleReadAllNotification = async () => {
    const ids = getNotificationIds(groupedNotifications);
    await readUnreadNotification(ids);
    fetchNotificationData(page);
  };

  const renderNotifications = (notifications: Notification[]) => {
    return notifications.map((notification) => (
      <div
        key={notification.notificationId}
        className={`notification-item ${notification.status === "NEW" ? "notification-item-unread" : "notification-item-read"}`}
        onClick={() => handleReadNotification(notification.notificationId)}
      >
        <img src={notification.image ? notification.image : DefaultUserIMG} alt={notification.title} width={60} height={60} />
        <div>
          <h6>{notification.title}</h6>
          <p>{notification.message}</p>
          <p>{timeAgo(notification.creationDate)}</p>
        </div>
        {notification.status === "NEW" && <div className='unreadDot' />}
      </div>
    ));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target.scrollTop + target.clientHeight + 1 >= target.scrollHeight) {
      fetchMoreNotifications();
    }
  };

  const fetchMoreNotifications = async () => {
    if (!isLoading) {
      setIsLoading(true);
      pageRef.current = pageRef.current + 1
      const nextPage = {
        no: 1,
        size: page.size * pageRef.current,
      };
      try {
        const newNotifications = fetchNotificationData(nextPage);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={showNotification}
        toggle={() => setShowNotification(!showNotification)}
        centered
        className="announcement-popup position-relative me-4 mt-0"
        style={{ top: "3%" }}


      >
        <ModalHeader className='shadow-sm'>
          <div className='notification-header-container'>
            <h5>Notifications</h5>
            <div onClick={handleReadAllNotification} style={{cursor:"pointer"}}>Mark all as read</div>
          </div>
        </ModalHeader>
        <ModalBody
          onScroll={handleScroll}
          style={{ maxHeight: '500px', overflowY: 'auto', margin: 0, padding: 0 }}
          className='notification-content-container'>

          {groupedNotifications.today.length > 0 && (
            <>
              <p className='notification-date-text'>Today</p>
              {renderNotifications(groupedNotifications.today)}
            </>
          )}
          {groupedNotifications.tomorrow.length > 0 && (
            <>
              <h3 className='notification-date-text'>Tomorrow</h3>
              {renderNotifications(groupedNotifications.tomorrow)}
            </>
          )}
          {Object.keys(groupedNotifications.others).map((date) => (
            <div key={date}>
              <div className='notification-date-text'>{moment(date).format("DD/MM/YYYY")}</div>
              <div>{renderNotifications(groupedNotifications.others[date])}</div>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NotificationModal;
