//Template config options
const appConfig = {
  paths: {
    homePage: "/create-campaign",
    loginPage: "/login",
    signupPage: "/new-register",
    createVideoPage: "/create-video-campaign",
  },
  variable: {
    source: "advertiser",
  },
  storage: {
    userData: "userData",
    accessToken: "access_token",
    MeetupData: "MeetupData",
    videoPost: "videoPost",
    jobPost:"jobPost",
    jobEditPage:"jobEditPage",
    jobAction:"jobAction",
    jobQuestion:"jobQuestion",
    meetupPage:"meetupPage",
    yourAudience:"yourAudience",
    questions:"questions",
    timeSlot:"timeSlot",
    meetupEditPage:"meetupEditPage",
    meetupUploadPage:"meetupUploadPage",
    meetupTimeSlot:"meetupTimeSlot",
    screeningQuestion:"screeningQuestion"

  },
  emailCampaignData: {
    userData: "userData",
    accessToken: "access_token",

  },
 
};

export default appConfig;
