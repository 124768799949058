import React, { FC, useState } from "react";
import ShowPassword from "../assets/images/icons/eye-show.png";
import HidePassword from "../assets/images/icons/eye-hide.png";
import TopMenuBar from "../common-components/TopMenuBar";
import { useNavigate } from "react-router-dom";
import { doLogout } from "../services/AuthApiService";
import { clearLocalStorage } from "../core/services/StorageService";
import toast from "react-hot-toast";
import { updatePassword } from "../services/AuthApiService"
import { sha256 } from "js-sha256";
import {
  getLocalStorageItem
} from "../core/services/StorageService";
import { SubmitHandler, useForm } from "react-hook-form";
interface TitleProps {
  title: string;
}
interface IUpdatePasswordForm{
  currentPassword: string,
  newPassword: string,
  confirmPassword:string,
}
const ChangePassword: FC<TitleProps> = ({ title }) => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<IUpdatePasswordForm>();
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const userProfile = getLocalStorageItem("userProfile")
  const userID = userProfile?.user_profile_id
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const onSubmit: SubmitHandler<IUpdatePasswordForm> = async (data): Promise<void> => {
    let formData = data;

    try{
      if (formData.currentPassword !== formData.newPassword) {
        let payload = {
          oldPassword: sha256(formData.currentPassword),
          newPassword: sha256(formData.newPassword),
        };
        await updatePassword(userID, payload).then((res) => {
          if (res.response) {
            toast.success("Password Updated Successfully");
            onPasswordChange();
          }
        });
      } else {
        toast.error("Current Password and New Password are same");
      }  
    // }
  }catch(err){
    console.log(err)
  }
  };
  const onPasswordChange = async () => {
    await doLogout();
    clearLocalStorage()
    navigate("/");
  };
  return (
    <>
      <div className="container-fluid">
        <TopMenuBar 
        hideSearchBar={true}
        showBackButton={true}
        pageTitle={title}
        />
        <div className="p-3">
          <form className="change-password-form max-width-300">
            <div className="pb-3">
              <label htmlFor="" className="label-text pb-1">
                Current Password*
              </label>
              <div className="position-relative">
                <input
                  type={currentPasswordVisible ? "text" : "password"}
                  className="password-input w-100 p-2"
                  {...register("currentPassword", {
                    required: true,
                  })}
                />
                <img
                  src={currentPasswordVisible ? ShowPassword : HidePassword }
                  alt={
                    currentPasswordVisible ? "Hide Password" : "Show Password"
                  }
                  className="eye-icon position-absolute"
                  onClick={toggleCurrentPasswordVisibility}
                />
              </div>
              <div className="text-danger font-10 pt-1">
                {errors.currentPassword?.type ==="required" && 
                <p>Current Password is required</p>
                }
              </div>
            </div>

            <div className="pb-3">
              <label htmlFor="" className="label-text pb-1">
                Create new Password*
              </label>
              <div className="position-relative">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  className="password-input w-100 p-2"
                  {...register("newPassword", {
                    required: true,
                    minLength: 6,
                  })}
                />
                <img
                  src={newPasswordVisible ? ShowPassword: HidePassword }
                  alt={newPasswordVisible ? "Hide Password" : "Show Password"}
                  className="eye-icon position-absolute"
                  onClick={toggleNewPasswordVisibility}
                />
              </div>
              <div className="text-danger font-10 pt-1">
              { 
                errors.newPassword?.type === "required" ?
                (
                  <p>New Password is required</p>
                )
                :
                errors.newPassword?.type === "minLength" ?
                (
                  <p>Password should be atleast 6 characters</p>
                )
                :("")
              }
              </div>
              
            </div>

            <div className="pb-3">
              <label htmlFor="" className="label-text pb-1">
                Confirm Password*
              </label>
              <div className="position-relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="password-input w-100 p-2"
                  {...register("confirmPassword", {
                    required: "Confirm password required",
                    minLength: 6,
                    validate: value => value === getValues("newPassword") || "Passwords do not match"
                  })}
                />
                <img
                  src={confirmPasswordVisible ? ShowPassword : HidePassword }
                  alt={
                    confirmPasswordVisible ? "Hide Password" : "Show Password"
                  }
                  className="eye-icon position-absolute"
                  onClick={toggleConfirmPasswordVisibility}
                />
              </div>
              <div className="text-danger font-10 pt-1">
                { 
                    errors.confirmPassword && <p>{errors.confirmPassword.message}</p>
                }
              </div>
            </div>

            <div className="pt-3">
              <button
                className="w-100 save-btn text-white border-0"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
