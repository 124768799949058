import React, { useCallback, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Webcam from "react-webcam";
// import { addDocument } from "../../../../../network/services/document/documentApiService";
import Camera from "../assets/images/camera.png";
import CameraClick from "../assets/images/camera.png";
// import { putProfileImage } from "../../../../../network/services/user/UserApiService";
import { toast } from "react-hot-toast";

interface IWebcamProps {
    showWebCamModel: boolean
    setWebCamModal: any
    className: string;
    setImage: any
    ProfileWebCam: any
    setNewCroppedImage:any
    setSelectedImage:any
    setShowImageCrop:any
}

const WebCam: React.FC<IWebcamProps> = ({ showWebCamModel, setWebCamModal, className, setImage, ProfileWebCam,setNewCroppedImage,setSelectedImage,setShowImageCrop }) => {
    const webcamRef = useRef<any>(null);
    const handleClose = () => {
        setWebCamModal(false);
    };
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };
    const uploadImage = async (imageSrc: any) => {
        try {
            const formData = new FormData()
            const request = {
                // document_type: imageType,
                document_id: new Date().getTime()
            }
            const requestFile = new File([JSON.stringify(request)], '', {
                type: 'application/json',
            });
            var file = new File([imageSrc], "camera-roll.jpeg", { type: "image/jpeg" });
            formData.append('request', requestFile);
            formData.append('document', file)
            if(imageSrc){
                const reader:any = new FileReader();
    reader.readAsDataURL(imageSrc);
    reader.addEventListener("load",()=>{
      setSelectedImage(reader.result);
    })
    setShowImageCrop(true)
            }
            
            // await addDocument(formData).then((imageUrl) => {
            //     if (imageUrl.response) {
            //         setImage(imageUrl.response);
            //         setWebCamModal(false);
            //     } else {

            //     }
            // });
        } catch (error) {

        }
    }

    const basicInfoUploadImage = async (imageSrc: any) => {
        const formData = new FormData()
        const request = {
            // document_type: imageType,
            document_id: new Date().getTime()
        }
        const requestFile = new File([JSON.stringify(request)], '', {
            type: 'application/json',
        });
        formData.append('request', requestFile);
        formData.append('profileImage', imageSrc)
        if(imageSrc){
            const reader:any = new FileReader();
reader.readAsDataURL(imageSrc);
reader.addEventListener("load",()=>{
  setSelectedImage(reader.result);
})
setShowImageCrop(true)
setWebCamModal(false)

        }
        // await putProfileImage(formData).then((response) => {
        //     setWebCamModal(false);
        //     basicDetails && basicDetails();
        //     getUserData && getUserData();
        //     toast.success(response.status.message)
        // })

    }
    const dataURLtoFile = (dataurl: string, filename: string) => {
        var arr = dataurl.split(',') as string[]
        const arrMatch = arr[0].match(/:(.*?);/) as RegExpMatchArray
        if (arr && arr.length>0) {
           const mime = (arr.length>0 && arrMatch) ? (arrMatch[1]) : "";
           const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
    
            return new File([u8arr], filename, { type: mime });
        }

       
    }
    const capture = useCallback(
        () => {
            
            const imageSrc = webcamRef?.current?.getScreenshot();
            const file = dataURLtoFile(imageSrc, 'camera_roll.jpeg')
            { ProfileWebCam ? basicInfoUploadImage(file) : uploadImage(file) }
        },
        [webcamRef]
    );
    return (
        <>
            <Modal show={showWebCamModel} onHide={handleClose} size="lg" className={className} centered>
                <Modal.Header closeButton>
                    <h4 className="font-24 fw-bolder camera-header text-primary d-flex align-items-center pt-1"><img alt="camera" src={Camera} className="me-1" />Camera</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center pt-2 pb-1">
                        <h5 className="text-black font-22">Take photo and attach image </h5>
                        <p className="grey-text font-18 pt-50">Take photo and attach image to your profile</p>
                    </div>
                    <div className="position-relative mb-5">
                        <Webcam

                            audio={false}
                            height={420}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            className="w-100 webcam-frame"
                        />
                        <button onClick={capture} className="position-absolute camera-click">
                            <div className="outer-div"><img alt="Camera" src={CameraClick} /></div>
                            <p className="grey-text font-18 fw-medium pt-50">Take Photo</p>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default WebCam;
