/** @format */

import appConfig from "../configs/promiloConfig";
import { IPaginationResponse } from "../core/interfaces/Pagination.interface";
import { IRestResponse } from "../core/interfaces/RestResponse.interface";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../core/services/HttpClientWrapper";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setAccessToken,
  setLocalStorageItem,
  setUserData,
} from "../core/services/StorageService";
import { IFranchiseCompanyList } from "../interface/franchise.interface";

const doLogin = async (payload: any) => {
  try {
    let data = await postRequest("/oauth/token", payload, "LOGIN");

    if (data.data.response.access_token) {
      setAccessToken(data.data.response["access_token"]);
    }
    return data.data;
  } catch (error) {
    throw error;
  }
};

const doLogout = async () => {
  try {
    let data = await getRequest("/oauth/logout", "LOGOUT");
    return data.data;
  } catch (error) {
  } finally {
    removeLocalStorageItem(appConfig.storage.accessToken);
  }
};

const changePasswordHandler = async (payload: any) => {
  try {
    let data = await putRequest("/v1/franchise/setPassword", payload, "ADMIN");

    return data.data;
  } catch (error) { }
};
const updatePassword = async (userId:string,payload:any) => {
  try {
    let data = await putRequest(`/v1/authorization/${userId}/change-password`,payload,"USER");
    return data.data;
  } catch (error) {
    throw error;
  }
};
const getAndStoreUserProfile = async () => {
  try {
    let response = await getRequest("/v1/user/profile", "USER");
    setUserData(response.data.response);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getFranchiserUserDeatils = async (farnchiseId: string): Promise<IRestResponse<IFranchiseCompanyList>> => {
  try {
    const res = await getRequest("v1/franchise/user/" + farnchiseId, 'ADMIN');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAndStoreFranchiseDeatils = async () => {
  const userData = getLocalStorageItem('userData');
  if (userData) {
    const res = await getFranchiserUserDeatils(userData.userId);
    if (res.response) {
      setLocalStorageItem('userProfile', res.response)
    }
  }
}

export { doLogin, doLogout, getAndStoreUserProfile, changePasswordHandler, updatePassword };
