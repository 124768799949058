export enum EOrder {
    ASC = "ASC",
    DESC = "DESC"
}

export enum ESearchMatch {
    EXACT = "exact",
    STARTS_WITH = "startswith",
    ENDS_WITH = "endswith",
    CONTAINS = "contains",
    IN = "in",
    NOT_IN = "notin"
}

export enum EOrderSequencing {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum EFieldMatch {
    OR = "OR",
    AND = "AND"
}