import Home from "../components/Home";
import OnboardedCompany from "../components/OnboardedCompany";
import SalesServicePerformance from "../components/SalesServicePerformance";
import Login from "../components/Login";
import ChangePassword from "../components/ChangePassword";
import ResetPassword from "../components/ResetPassword";
import FranchiseCompany from "../components/FranchiseCompany";
import Overview from "../components/overview/Overview";
import Settings from "../components/Settings";
import CreateNewTicket from "../components/CreateNewTicket";
import PendingTickets from "../components/PendingTickets";
import Inbox from "../components/Inbox";
import Reports from "../components/reports/Reports";
import BillingAndPayment from "../components/billing/BillingAndPayment";
import MyProfile from "../components/MyProfile"

export const routes = [
  {
    id: 'home',
    path: "/",
    element: <Login title={"Login"} />,
  },
  {
    id: 2,
    path: "/home",
    element: <Home title={"title"} />,
  },
  {
    id: 3,
    path: "/overview",
    element: <Overview title={"Overview Company"} />,
    activeMenuItem: "overview",
    private: true,
  },
  {
    id: 4,
    path: "/onboarded-company",
    element: <OnboardedCompany title={"Onboarded Company"} />,
    activeMenuItem: "onboard-company",
    private: true,
  },
  {
    path: "/franchise-company",
    element: <FranchiseCompany />,
    private: true,
    activeMenuItem: 'menu-franchise'
  },
  {
    path: "/sales-service-performance",
    element: <SalesServicePerformance title={"Sales Service Performance"} />,
    activeMenuItem: "sales-service-perform",
    private: true,
  },
  {
    path: "/billing",
    element: <BillingAndPayment title={"billing"} />,
    activeMenuItem: "billing",
    private: true,
  },
  {
    path: "/change-password",
    element: <ChangePassword title={"Change Password"} />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword title={"Reset Password"} />,
  },
  {
    path: "/settings",
    element: <Settings title={"Settings"} />,
    activeMenuItem: "settings",
    private: true,
  },
  {
    path: "/create-new-ticket",
    element: <CreateNewTicket title={"Create New Ticket"} />,
    activeMenuItem: "support",
    private: true,
  },
  {
    path: "/my-tickets",
    element: <PendingTickets title={"My Tickets"} />,
    activeMenuItem: "support",
    private: true,
  },
  {
    path: "/inbox",
    element: <Inbox title={"Inbox"} />,
    activeMenuItem: "support",
    private: true,
  },
  {
    path: "/reports",
    element: <Reports title={"Reports"} />,
    activeMenuItem: "reports",
    private: true,
  },
  {
    activeMenuItem: "my-profile",
    path: "/myprofile",
    element: <MyProfile title={"My Profile"}/>,
    private:true,
  },
];
