import React from "react";
interface SwitchProps {
  id: string;
  name: string;
  className: string;
  checked: boolean
  setSwitchValueChange: React.Dispatch<React.SetStateAction<boolean>>;
}
const Switch: React.FC<SwitchProps> = ({ id, name, className, checked, setSwitchValueChange }) => {
  return (
    <div
      className={`switch-input ${className} d-flex align-items-center justify-content-center `}
    >
      <input type="checkbox" checked={checked} role="switch" id={id} name={name} onChange={(e)=>setSwitchValueChange(e.target.checked)}/>
      <label htmlFor={id} className="position-relative pointer"></label>
    </div>
  );
};

export default Switch;
