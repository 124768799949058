import React, { FC, useEffect, useRef, useState } from "react";
import TopMenuBar from "../common-components/TopMenuBar";
import Calendar from "../common-components/Calendar";
import { IDateRange } from "../interface/html-elements.interface";
import Pagination from "react-js-pagination";
import { DropdownButton } from "react-bootstrap";
import { Table, Input } from "reactstrap";
import PaginationIcon from "../assets/images/icons/pagination-left.png";
import FileIcon from "../assets/images/icons/link.png";
import EyeIcon from "../assets/images/icons/eye.png";
import Dropdown from "react-bootstrap/Dropdown";
import {
  EOrder,
  EOrderSequencing,
  ESearchMatch,
} from "../core/enums/Search.enums";
import { getLocalStorageItem } from "../core/services/StorageService";
import { getAllTickets } from "../services/SupportApiService";
import {
  ICommonSearch,
  ISearch,
} from "../core/interfaces/SearchRequest.interface";
import { ITicket } from "../interface/ticket.interface";
import {
  convertToDDMMYYYY,
  convertToDDMMYYYYFromDBDate,
  convertToTitleCase,
} from "../core/services/utilityService";
import { ETicket } from "../enums/tickets.enum";
import PendingTicketsModal from "./PendingTicketsModal";
import { EUserType } from "../enums/user.enum";
import { useNavigate } from "react-router-dom";

interface TitleProps {
  title: string;
}

const PendingTickets: FC<TitleProps> = ({ title }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const franchiseRefCode = useRef<string>("");
  const [ticketList, setTicketList] = useState<ITicket[]>([]);
  const [openTicketDeatilModal, setOpenTicketDetailModals] =
    useState<string>("");
  const selectedTicket = useRef<ITicket>({} as ITicket);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  useEffect(() => {
    getTicketList();
  }, [dateRange, searchQuery, currentPage, pageSize]);
  const toggleRowSelection = (index: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(index)) {
      updatedSelectedRows.delete(index);
    } else {
      updatedSelectedRows.add(index);
    }
    setSelectedRows(updatedSelectedRows);
  };
  const handleSelectAll = () => {
    if (selectedRows.size === ticketList.length) {
      setSelectedRows(new Set());
    } else {
      const allIndexes = Array.from({ length: ticketList.length }, (_, i) => i);
      setSelectedRows(new Set(allIndexes));
    }
  };
  const openTicketDetailsModal = (modalHead: string, ticket: ITicket) => {
    setOpenTicketDetailModals(modalHead);
    selectedTicket.current = ticket;
  };
  const initializeSearch = () => {
    let search: ISearch = {
      page: {
        no: currentPage,
        size: pageSize,
      },
      query: {
        filter: [
          {
            fields: ["franchise_referral_code"],
            search: franchiseRefCode.current ? franchiseRefCode.current : "",
            search_match: ESearchMatch.EXACT,
          },
        ],
        search: {} as ICommonSearch,
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };

    if (dateRange.startDate && search && search.query && search.query.filter) {
      search?.query?.filter.push({
        fields: ["created_on"],
        between: [dateRange.startDate, dateRange.endDate],
        search_match: ESearchMatch.EXACT,
      });
    }
    if (searchQuery && search && search.query && search.query.search) {
      search.query.search = {
        fields: ["ticket_no", "description", "business_mail"],
        search: searchQuery,
      };
      setCurrentPage(1);
    }
    const userType = getLocalStorageItem('userProfile')?.user_type
    if (userType === EUserType.FRANCHISE_SALES) {
      const referralCode = getLocalStorageItem('userProfile')?.referral_code;
      search && search.query && search.query.filter ? search.query?.filter.push({
        fields: ["referral_code"],
        search: referralCode,
        search_match: ESearchMatch.EXACT,
      }) : void 0;
    }
    return search;
  };

  const getTicketList = async () => {
    try {
      franchiseRefCode.current = getLocalStorageItem("userProfile")?.franchise_ref_code;
      if (franchiseRefCode.current) {
        const searchRequest = initializeSearch();
        const res = await getAllTickets(searchRequest);
        if (res.response && res.response.content) {
          setTotalPagesCount(res.response.total_elements);
          setTicketList(res?.response.content);
        }
      }
    } catch (error) { }
  };

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageCountChange = (eventKey: any) => {
    setPageSize(eventKey);
    setCurrentPage(1);
  };

  const getTextColor = (status: string) => {
    if (status === ETicket.COMPLETED) {
      return "text-success";
    } else if (status === ETicket.IN_PROGRESS) {
      return "text-primary";
    } else if (status === ETicket.PENDING) {
      return "text-warning";
    } else if (status === ETicket.REJECTED) {
      return "text-danger";
    }
  };

  return (
    <>
      <div className="container-fluid service-request-page">
        <TopMenuBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="row">
        <h1 className="font-20 fw-500 m-0 py-2">{title}</h1>
        </div>
        <div className="row ">
          <div className="col-12 col-md-5 pt-2">
          <div className="service-performance-total-records">
          <span className="">Total Records: { ticketList.length}</span>
        </div>
          </div>
          <div className="col-12 col-md-7 text-end">
            <div className="d-flex align-items-center justify-content-end">
            <button className="download-report-btn" onClick={()=> {navigate("/create-new-ticket",{state: {goBack:true}})}}>
                
               Create New Ticket
              </button>
              <div className="ps-sm-4">
                <Calendar
                  className="calender"
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="mb-3 pt-2">
          <Table responsive className="listing-table font-12">
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    name="selectAll"
                    checked={ticketList.length > 0 && selectedRows.size === ticketList.length}
                    onChange={handleSelectAll}
                    disabled={ticketList.length === 0}
                  />
                </th>
                <th>
                  <div className="text-nowrap text-center">Ticket No.</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Content</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Created Date</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Business Email</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Ticket Raised By</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Uploaded Files</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">Status</div>
                </th>
                <th>
                  <div className="text-nowrap text-center">View</div>
                </th>
              </tr>
            </thead>
            <tbody className="first-row">
              {ticketList.map((ticket: ITicket, i: number) => {
                const isSelected = selectedRows.has(i);
                return (
                  <tr key={"ticket_" + ticket.ticket_id}>
                    <td>
                      <div className="text-start">
                        <Input
                          name=""
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => toggleRowSelection(i)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end text-nowrap">
                        {ticket.ticket_no}
                      </div>
                    </td>
                    <td style={{ maxWidth: '200px' }}>
                      <div className=" text-truncate" >
                        {ticket.description}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center text-nowrap">
                        {ticket.published_on
                          ? convertToDDMMYYYYFromDBDate(ticket.published_on)
                          : "NA"}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center text-nowrap">
                        {ticket.business_mail || "NA"}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center text-nowrap">
                        {ticket.user_name || "NA"}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center text-nowrap">
                        <img
                          src={FileIcon}
                          alt="file"
                          style={{ width: "16px" }}
                          onClick={() =>
                            openTicketDetailsModal("ATTACHMENTS", ticket)
                          }
                          className={`file-attach-icon ${!ticket.documents || ticket.documents.length === 0
                            ? "disable"
                            : "enable"
                            }`}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={`d-flex align-items-center justify-content-center text-nowrap ${getTextColor(
                          ticket.ticket_status
                        )}`}
                      >
                        {convertToTitleCase(ticket.ticket_status)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center text-nowrap">
                        <img
                          src={EyeIcon}
                          alt="file"
                          style={{ width: "16px" }}
                          onClick={() =>
                            openTicketDetailsModal("DETAILS", ticket)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="row align-items-center pt-25 pb-50 pagination-section py-3">
          <div className="col-10 text-end mt-1">
            <form className="d-flex justify-content-end align-item">
              <span className="text-primary-blue pe-1 font-12 d-flex align-items-center">
                Show
              </span>
              <div className="total-pages me-2 position-relative">
                <span className="d-flex align-items-center">{pageSize}</span>
                <DropdownButton onSelect={onPageCountChange} title={""}>
                  <Dropdown.Item eventKey="10">10</Dropdown.Item>
                  <Dropdown.Item eventKey="25">25</Dropdown.Item>
                  <Dropdown.Item eventKey="50">50</Dropdown.Item>
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>
              </div>
            </form>
          </div>
          <div className="col-2">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalPagesCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              prevPageText={<img src={PaginationIcon} alt="Previous" />}
              nextPageText={<img src={PaginationIcon} alt="Next" />}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <PendingTicketsModal
        modalAction={openTicketDeatilModal}
        setModal={setOpenTicketDetailModals}
        selectedTicket={selectedTicket.current}
      />
    </>
  );
};

export default PendingTickets;
