import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";


const isValidUrl = (urlString:any) => {
    const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!-)[A-Z\d-]{1,63}(?<!-)\.?)+[A-Z]{2,6}(?::\d{1,5})?(?:[/?#]\S*)?$/i;
    return urlRegex.test(urlString);
}


const onInvalidLink = () => {
    toast.error("Please upload valid image URL");
}

function checkValidImgUrl(src:any, good:any, bad:any) {
    var img = new Image();
    img.src = src;
    img.onload = good;
    img.onerror = bad;
}

interface IAttachmentProps{
    showAttachmentModel:boolean;
    setAttachmentModal:any;
    setImage:any;
    className:string;
    setNewCroppedImage:any;
    setSelectedImage:any;
    setShowImageCrop:any;
}

const AttachmentUrl: React.FC<IAttachmentProps> = ({ showAttachmentModel, setAttachmentModal, setImage, className,setNewCroppedImage,setSelectedImage,setShowImageCrop }) => {
    const [myUrl, setMyUrl] = useState<any>(null);
    // const [isInvalidUrl, setIsInvalidUrl] = useState(false);
    const handleClose = () => {
        setAttachmentModal(false);
    };

    const handleSubmit = async (event:any) => {
        
        event.preventDefault();
        const onImgError = () => {
            onInvalidLink()
            return
        }
        if (!isValidUrl(myUrl)) {
            onImgError()
            return
        }
        const onImgSuccess = () => {
                setImage(myUrl);
                setSelectedImage(myUrl)
                setShowImageCrop(true)
                setAttachmentModal(false);

        }
       
        const isValidImgUrl = checkValidImgUrl(myUrl, onImgSuccess, onImgError)
        
    }
    const handleChange = (event:any) => {
        const inputUrl = event.target.value;
        setMyUrl(inputUrl);
        const regex = /\.(gif|jpe?g|png|webp|bmp)$/i
    };
    useEffect(() => {
        setMyUrl('');
    }, [showAttachmentModel])
    return (
        <>
            <Modal show={showAttachmentModel} onHide={handleClose} centered className={className}>
                <Modal.Header closeButton>
                    <h1 className="font-18 fw-bolder text-black pt-2 mb-0 pb-3">Enter URL</h1>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <label className="py-50 w-100 mb-0">
                            <input className="enter-url-input bg-transparent p-50"
                                placeholder="E.g. https://drive.google.com/mydocument"
                                type="text"
                                value={myUrl}
                                onChange={(e) => handleChange(e)}
                            />
                        </label>
                        <p className="text-black font-12 line-height-1-2 pt-1">{'We support only images URL'}</p>

                        <div className="url-btn text-end font-12 fw-bold pt-1">
                            <span className="me-2 pointer" onClick={() => {
                                handleClose()
                            }}>CANCEL</span>
                            <input type="submit" className="save-input border-0 bg-transparent fw-bold pointer" value="SAVE" />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AttachmentUrl;
