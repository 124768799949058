import { FC } from "react";
import { Input, Table } from "reactstrap";
import { convertToMonthAndYear } from "../../core/services/utilityService";
import {
  IFranchiseRevenueList,
  ISelectedFranchise,
  IStatsCount,
} from "../../interface/franchise.interface";
import { getLocalStorageItem } from "../../core/services/StorageService";
import { EUserType } from "../../enums/user.enum";
// import DownloadSuccess from '../../common-components/DownloadSuccess';

interface TitleProps {
  franchiseList: IFranchiseRevenueList[];
  onSelectAll: any;
  selectedFranchise: ISelectedFranchise[];
  setSelectedFranchise: any;
}

const BillingTable: FC<TitleProps> = ({
  franchiseList,
  onSelectAll,
  selectedFranchise,
  setSelectedFranchise,
}) => {
  const userType = getLocalStorageItem("userProfile")?.user_type;
  
  const getStatsCount = (statsArray: IStatsCount[], field: string): number => {
    const stats = statsArray.find(ele => ele.status === field);
    if (stats) {
      return stats.count
    }
    return 0
  }
  
  return (
    <div className="mb-3">
      <Table responsive className="listing-table font-12 ">
        <thead>
          <tr>
            <th>
              <Input
                type="checkbox"
                name="selectAll"
                onChange={(e) => {
                  onSelectAll(e);
                }}
              />
            </th>
            <th>
              <div className="text-nowrap text-center">Sales Person Name</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Company Name</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Company Location</div>
            </th>
            <th>
              <div className="text-nowrap text-center">Meeting Month</div>
            </th>
            <th>
              <div className="text-nowrap text-center">No. of Meetings </div>
            </th>

            <th>
              <div className="text-nowrap text-center">
                Total Revenue Generated
              </div>
            </th>
            {userType == EUserType.FRANCHISE_ADMIN && (
              <th>
                <div className="text-nowrap text-center">
                  Profit Revenue Margin
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="first-row">
          {franchiseList?.map((data: any) => {
            return (
              <tr key={data.franchise_revenue_id}>
                <td>
                  <div>
                    <Input
                      name=""
                      type="checkbox"
                      checked={selectedFranchise.some(
                        (franchise: any) =>
                          franchise.id === data.franchise_revenue_id
                      )}
                      onChange={() => {
                        if (
                          selectedFranchise.some(
                            (franchise: any) =>
                              franchise.id === data.franchise_revenue_id
                          )
                        ) {
                          setSelectedFranchise((prevSelectedData: any) =>
                            prevSelectedData.filter(
                              (ele: any) => ele.id !== data.franchise_revenue_id
                            )
                          );
                        } else {
                          setSelectedFranchise((prevSelecteData: any) => [
                            ...prevSelecteData,
                            {
                              id: data.franchise_revenue_id,
                              name: data.franchise_name,
                            },
                          ]);
                        }
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {data.sales_person} ({data.franchise_seller_ref_code})
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {data.company_name}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {data.location}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {convertToMonthAndYear(data.created_on)}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {getStatsCount(data.meeting_counts , "ALL")}
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    {data.meeting_amount}
                  </div>
                </td>
                {userType == EUserType.FRANCHISE_ADMIN && (
                  <td>
                    <div className="d-flex align-items-center text-nowrap">
                      {data.amount}
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default BillingTable;
