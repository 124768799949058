// InboxSideMenu Component
import React, { FC, useEffect, useState,useRef } from "react";
import { IGroupedInbox, IInbox } from "../interface/inbox.interface";
import { readInboxes } from "../services/SupportApiService";
import { format, isToday, isYesterday } from "date-fns";
import parse from "html-react-parser";
import moment from "moment";
import AttachIcon from "../assets/images/IconinboxAttach.png"
import PromiloLogoWithoutText from "../assets/images/icons/promilo-logo-without-text.png"
interface IInboxSideProps {
  inboxList: IInbox[];
  markAsRead: React.Dispatch<React.SetStateAction<string>>;
  setSelectedInbox: React.Dispatch<React.SetStateAction<IInbox>>;
  loading?:boolean
  setPage:any 
}

const InboxSideMenu: FC<IInboxSideProps> = ({
  inboxList,
  markAsRead,
  setSelectedInbox,
  loading,
   setPage,
}) => {
  const [groupedInboxesList, setGroupedInboxesList] = useState<IGroupedInbox[]>(
    []
  );
  const [activeInboxId, setActiveInboxId] = useState<string | null>(null);
  const [initialLoad,setInitialLoad] = useState(true)
  const listRef = useRef<any>();
  const updatingPage = ()=>{
    setPage((prev:any)=>prev+1)
  }
  useEffect(() => {
    const handleScroll = () => {
      const { current } = listRef;
      if (
        current &&
        current.scrollTop + current.clientHeight + 1 >= current.scrollHeight &&
        !loading
      ) {
        updatingPage()
      }
    };

    listRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [ updatingPage, loading]);


  useEffect(() => {
    if (inboxList) {
      const grouped = groupByDate(inboxList);
      setGroupedInboxesList(grouped);
    }
    if (inboxList && inboxList.length > 0 && initialLoad) {
      const id = inboxList[0].id;
      setActiveInboxId(id);
    }
  }, [inboxList]);
  useEffect(()=>{
    if(groupedInboxesList[0]?.inboxes[0] && initialLoad){
      readInbox(groupedInboxesList[0].inboxes[0])
    }
    
  },[groupedInboxesList])

  const groupByDate = (inboxItems: IInbox[]): IGroupedInbox[] => {
    const groupedInboxes: { [key: string]: IInbox[] } = {};
    inboxItems.forEach((item) => {
      const createdDate = item.created_on_date
        ? new Date(item.created_on_date)
        : new Date();
      let dateKey: string;
      if (isToday(createdDate)) {
        dateKey = "Today";
      } else if (isYesterday(createdDate)) {
        dateKey = "Yesterday";
      } else {
        dateKey = format(createdDate, "dd-MM-yyyy");
      }
      if (!groupedInboxes[dateKey]) {
        groupedInboxes[dateKey] = [];
      }

      groupedInboxes[dateKey].push(item);
    });

    // Convert the grouped object into an array
    const result: IGroupedInbox[] = Object.keys(groupedInboxes).map((date) => ({
      date,
      inboxes: groupedInboxes[date],
    }));

    return result;
  };

  const readInbox = async (inbox: IInbox): Promise<void> => {
    setSelectedInbox(inbox);
    if (!inbox.read) {
      let res = await readInboxes(inbox.id);
      markAsRead(inbox.id);
    }
    setActiveInboxId(inbox.id);
  };

  const formatDateTime = (dateTimeString: string): string => {
    const momentDate = dateTimeString ? moment(dateTimeString) : moment();
    const formattedDateTime = momentDate.format("ddd h:mm A");
    return formattedDateTime;
  };

  return (
    <>
      <div className="inbox-side-menu p-2 w-full" ref={listRef}>
        {groupedInboxesList && groupedInboxesList.length > 0 ? (
          groupedInboxesList.map((group, index) => (
            <div key={"date_sec_" + index}>
              <div className="show-date font-16 fw-500 py-2">{group.date}</div>
              {group.inboxes &&
                group.inboxes.map((inbox, i) => (
                  <div
                    className={`card mb-0 w-100 p-3 pointer ${
                      activeInboxId === inbox.id ? "active" : "inactive"
                    } ${inbox.read?"":"unread-inbox"}`}
                    key={"inbox_card_" + i}
                    onClick={() => {
                      readInbox(inbox);
                      if(initialLoad){
                        setInitialLoad(false)
                      }
                    }}
                  >
                    <div className="row g-2">
                      <div className="col-2">
                        <div className="show-user-name text-white font-16 text-bold d-flex align-items-center justify-content-center me-3">
                          <img src={ PromiloLogoWithoutText } style={{width: '32px',height: '32px'}}/>
                        </div>
                      </div>
                      <div className="col-10">
                        <div className="card-body p-0">
                          <h5 className="card-title font-14 fw-500 d-flex justify-content-between">
                            Promilo Support
                            {inbox.attachments.length>0 && <img src={AttachIcon} alt="attachIcon" style={{height:"15px",width:"15px"}}/>} 
                          </h5>
                          <div className="d-flex align-items-start justify-content-between">
                            <p className="card-text font-12 pb-1 mb-0">
                              {inbox.subject.length>20?inbox.subject.slice(0,18)+"...":inbox.subject}
                            </p>
                            <p className="card-text font-12 pb-1 mb-0 text-nowrap">
                              {formatDateTime(inbox.created_on_date)}
                            </p>
                          </div>
                          <p className="card-sub-text font-12 text-truncate pb-0 mb-0">
                            {inbox.short_description ? inbox.short_description : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))
        ) : (
          <div>There is no data available</div>
        )}
      </div>
    </>
  );
};

export default InboxSideMenu;
