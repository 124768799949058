import { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import Menu from "./common-components/Menu";
import {
  clearLocalStorage,
  getAccessToken,
  getLocalStorageItem,
  setLocalStorageItem,
} from "./core/services/StorageService";

import { Toaster } from "react-hot-toast";
import { EUserType } from "./enums/user.enum";
import { IAvailableServices } from "./interface/settings.interface";
import { routes } from "./routes/Routes";
// @ts-ignore: Unresolved module
import * as CryptoJS from 'crypto-js';
import NotificationBadgeContext from "./contexts/NotificationBadgeContext";
interface MainSectionProps {
  routeElement: React.ReactNode;
  menu?: string;
}

function App() {
  const [newEmail,setNewEmail] = useState(0)
  const [newAnnouncement,setNewAnnouncement] = useState(0)
  const [totalNotification,setTotalNotification] = useState(0)
  return (
    <div className="App">
      <header className="App-header">
        {/* <Sidebar/> */}
        <Toaster position="top-right" />
        <div className="d-flex">
          <BrowserRouter>
          <NotificationBadgeContext.Provider value={{newEmail,setNewEmail,newAnnouncement,setNewAnnouncement,totalNotification,setTotalNotification}}>
            <Menu />
            {/* <TopMenuBar /> */}
            <Routes>
              {routes.map((ele, i) => {
                return !ele.private ? (
                  <Route
                    path={ele.path}
                    element={<MainSection routeElement={ele.element} />}
                    key={"route_" + i}
                  />
                ) : (
                  <Route
                    path={ele.path}
                    element={
                      <AuthGuard
                        routeElement={ele.element}
                        menu={ele.activeMenuItem}
                      />
                    }
                    key={"route_" + i}
                  />
                );
              })}
            </Routes>
            </NotificationBadgeContext.Provider>
          </BrowserRouter>
        </div>
      </header>
    </div>
  );
}
function MainSection({ routeElement }: MainSectionProps) {
  const token = getAccessToken();

  return (
    <div className={`main-section ${token ? "" : "w-100"}`}>{routeElement}</div>
  );
}

const AuthGuard = ({ routeElement, menu = "" }: MainSectionProps) => {
  setLocalStorageItem("current_menu_item", menu);
  const token = getAccessToken();
  if (token && checkAccess(menu)) {
    return (
      <div className={`main-section ${token ? "" : "w-100"}`}>
        {routeElement}
      </div>
    );
  } else {
    clearLocalStorage()
    return <Navigate to="/" replace />;
  }
}

const checkAccess = (menu: string): boolean => {
  let activeMenuItems = []
  const userType = getLocalStorageItem('userData').userType;
  if (userType === EUserType.FRANCHISE_ADMIN) {
    return true
  }

  if (activeMenuItems.length === 0) {
    const services = getLocalStorageItem('availed_services') || "";
    if (menu === "settings") { // mention all the routes i.e. is not restricted by admin
      return true
    }
    if (services) {
      const bytes = CryptoJS.AES.decrypt(services, process.env.REACT_APP_CYPER);
      const data = bytes.toString(CryptoJS.enc.Utf8) ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : [];
      let serviceList = data ? data : [];
      activeMenuItems = serviceList?.filter((ele: IAvailableServices) => ele.status === "ENABLED")?.map((ele: IAvailableServices) => ele.service?.toString())
      if (menu === "onboard-company" || menu === "menu-franchise") {
        return activeMenuItems.includes("2")
      } else if (menu === "overview") {
        return activeMenuItems.includes("1")
      } else if (menu === "reports") {
        return activeMenuItems.includes("4")
      } else if (menu === "sales-service-perform") {
        return activeMenuItems.includes('3')
      } else if (menu === "billing") {
        return activeMenuItems.includes('5')
      } else if (menu === "my-profile" || menu === "reset-password" || menu === "support") {
        return true;
      }else {
        return false
      }
    } else {
      return true
    }
  }

  return false
}

export default App;
